import classnames from 'classnames';
import type { FC } from 'react';
import { useEffect } from 'react';

import { QueryParam } from '@/enums/route';
import { useQueryParam } from '@/hooks/use-query-param';

import { deepEqual } from '../../../../lib/equality-fns';
import { useMap } from '../../hooks/use-map';
import { WayFindingType } from '../../store/types';
import {
  selectWayFinding,
  useWayFindingStore,
} from '../../store/wayFindingStore';
import { WayFindingCard } from '../way-finding-card/way-finding-card';

import css from './way-finding.module.scss';

interface WayFindingProps {
  type: WayFindingType;
}

export const WayFinding: FC<WayFindingProps> = ({ type }) => {
  const wayFinding = useWayFindingStore(selectWayFinding, deepEqual);
  const { stopRoute, goToPoi } = useMap();
  const classNames = classnames({
    [css.gate]: type === WayFindingType.GATE,
    [css.poi]: type === WayFindingType.POI,
  });

  const poiId = useQueryParam(QueryParam.POI_ID);

  useEffect(() => {
    // stop route when component is destroyed
    const cleanup = () => {
      stopRoute();
    };

    // stop route when other poi is selected
    if (poiId) {
      stopRoute();
    }
    return cleanup;
  }, [poiId, stopRoute]);

  if (
    !wayFinding.to ||
    !wayFinding.isShowingRoute ||
    type !== wayFinding.type
  ) {
    return null;
  }

  const stopWayFinding = () => {
    void stopRoute();
    void goToPoi(wayFinding.to!.id);
  };

  return (
    <WayFindingCard
      className={classNames}
      wayFinding={wayFinding}
      onClose={stopWayFinding}
    />
  );
};
