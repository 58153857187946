import classnames from 'classnames';
import type { PropsWithChildren } from 'react';

import css from './sr-only.module.scss';

interface Props {
  as?: keyof HTMLElementTagNameMap;
  className?: string;
}

export const SrOnly = ({
  as = 'div',
  className,
  children,
}: PropsWithChildren<Props>) => {
  const Tag = as;
  const mergedClassNames = classnames(css.srOnly, className);
  return <Tag className={mergedClassNames}>{children}</Tag>;
};
