import type { Dispatch, SetStateAction } from 'react';
import { useSessionstorageState } from 'rooks';

const noop = () => {};

/*
 * This hook is a wrapper around the useSessionstorageState hook from rooks.
 * The original hook was causing the website to crash when access to session storage was blocked (e.g., when cookies are disabled).
 * This wrapper catches such errors and returns a default value to prevent the website from crashing.
 */
export const useCustomSessionStorageState = <T>(
  key: string,
  initialState: T,
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  try {
    return useSessionstorageState(key, initialState);
  } catch (error) {
    console.warn('Error using session storage state');
  }
  return [initialState, noop, noop];
};
