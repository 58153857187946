import type { DefaultMeta } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapStart: Mapper<DefaultMeta> = () => ({
  'gtm.start': new Date().getTime(),
  event: 'gtm.js',
});

export const mapSessionEnd: Mapper<DefaultMeta> = () => ({
  eventName: GTMEventName.SESSION_END,
});

export const mapError: Mapper<DefaultMeta> = ({ meta: { value } }) => ({
  eventName: GTMEventName.ERROR_MESSAGE,
  eventLabel: value,
});

export const mapPageView: Mapper<DefaultMeta> = () => ({
  event: 'virtual_pageview',
  eventName: GTMEventName.PAGE_VIEW,
});
