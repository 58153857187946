import { useSSU } from '@/context/ssu-provider';
import { Module } from '@/enums/module';
import { useModule } from '@/hooks/use-module';
import type { MapModule } from '@/types/content-schema';

export const useGetFloorIndication = (floorId = '') => {
  const mapModule = useModule<MapModule>(Module.MAP);
  const { config } = useSSU();
  const floors = mapModule?.properties.floors || [];
  const ssuLocationFloorId = config.orientation.floor;
  const floorIndex = floors.findIndex((floor) => floor.id === floorId);
  const floor = floors.find((floor) => floor.id === floorId);
  const floorNumber = floor?.number;
  const ssuLocationFloorIndex = floors.findIndex(
    (floor) => floor.id === ssuLocationFloorId,
  );

  const floorIndicationNumber = ssuLocationFloorIndex - floorIndex;

  return {
    currentFloor: floorNumber
      ? parseInt(floorNumber, 10) - floorIndicationNumber
      : null,
    floorIndicationNumber,
    floorNumber,
    isSameFloor: floorIndicationNumber === 0,
    isFloorUp: floorIndicationNumber > 0,
    isFloorDown: floorIndicationNumber < 0,
  };
};
