import { useTranslation } from 'next-i18next';
import { useTimeoutWhen } from 'rooks';
import type { VideoCallModule } from 'pxp-api/content-schema';

import { Module } from '@/enums/module';
import { useModule } from '@/hooks/use-module';
import { ModalTrigger } from '@/modals';
import {
  selectBalloonHasBeenClosed,
  selectBalloonIsOpen,
  useBalloonStore,
} from '@/store/balloonStore';

import { ButtonIcon } from '../../../../components/button-icon/button-icon';
import { getTestIdProp } from '../../../../lib/get-test-id-prop';
import { IconName } from '../../../../types/icon';

const BALLOON_ID = 'ask-agent';

export const AskAgentButton = () => {
  const { t } = useTranslation();
  const videoCallModule = useModule<VideoCallModule>(Module.VIDEO_CALL);
  const { setIsOpen } = useBalloonStore();
  const isOpen = useBalloonStore(selectBalloonIsOpen(BALLOON_ID));
  const hasBeenClosed = useBalloonStore(selectBalloonHasBeenClosed(BALLOON_ID));

  const title = t('general.button.askAgent', { defaultValue: 'Ask an agent' });

  useTimeoutWhen(
    () => setIsOpen(BALLOON_ID, true),
    (videoCallModule?.properties?.showBalloonDelay || 0) * 1000,
    videoCallModule?.enabled &&
      !!videoCallModule?.properties?.showBalloonDelay &&
      !hasBeenClosed,
  );

  useTimeoutWhen(
    () => setIsOpen(BALLOON_ID, false),
    (videoCallModule?.properties?.balloonTimeout || 0) * 1000,
    videoCallModule?.enabled &&
      !!videoCallModule?.properties?.balloonTimeout &&
      isOpen,
  );

  return (
    <ModalTrigger modalId="CONTACT_OPTIONS">
      <ButtonIcon
        hasPopOver
        popOverProps={{
          isOpen,
          onOpenChange: () => setIsOpen(BALLOON_ID, !isOpen),
          content: title,
        }}
        title={title}
        iconName={IconName.AGENT}
        {...getTestIdProp('contact-options')}
      />
    </ModalTrigger>
  );
};
