import classnames from 'classnames';
import type { FC } from 'react';

import { TheFooter } from '../components/the-footer/the-footer';
import { TheHeader } from '../components/the-header/the-header';

import css from './defaultLayout.module.scss';
import type { LayoutProps } from './get-layout';

export const DefaultLayout: FC<LayoutProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);
  return (
    <div className={classes}>
      <TheHeader />
      <main className={css.main}>{children}</main>
      <TheFooter />
    </div>
  );
};
