import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { getFlightStatus } from 'pxp-api/clients/flight-info-api/helpers/status';
import type { Flight } from 'pxp-flight-api-schema/types';

import { SrOnly } from '@/components/sr-only/sr-only';

import { GateLabel } from '../../../../components/gate-label/gate-label';

import css from './departure-gate.module.scss';

interface DepartureGateProps {
  flight: Flight;
  hideDescription?: boolean;
  className?: string;
}

export const DepartureGate = ({
  flight,
  hideDescription,
  className,
}: DepartureGateProps) => {
  const { t } = useTranslation();
  const { isGateChanged, isCancelled } = getFlightStatus(flight);
  const classNames = classnames(css.root, className);

  return (
    <span className={classNames}>
      {flight.departure.gateNumber && !isCancelled ? (
        <>
          {isGateChanged && (
            <SrOnly>{t('general.flightInfo.gateChanged')}</SrOnly>
          )}
          <GateLabel
            flightStatus={flight.status}
            gateNumber={flight.departure.gateNumber}
          />
        </>
      ) : (
        <span>-</span>
      )}
      {isGateChanged && !hideDescription && (
        <span className={css.changed} aria-hidden>
          {t('general.flightInfo.gateChanged')}
        </span>
      )}
    </span>
  );
};
