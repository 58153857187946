import { useEffect } from 'react';
import type { AdBanner as AdBannerModule } from 'pxp-api/clients/strapi/config-type';

import { Module } from '../../enums/module';
import { useModule } from '../../hooks/use-module';

import { Banner } from './banner/banner';

export const AdBanner = () => {
  const adBanner = useModule<AdBannerModule>(Module.AD_BANNER);
  const isEnabled = adBanner?.enabled;

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    document.body.classList.add('with-ad');

    return () => {
      document.body.classList.remove('with-ad');
    };
  }, [isEnabled]);

  if (!isEnabled) {
    return null;
  }

  return <Banner {...adBanner.properties} />;
};
