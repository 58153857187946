import type { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';
import css from './alert.module.scss';

export enum AlertType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const iconMap = {
  [AlertType.INFO]: IconName.FAQ,
  [AlertType.SUCCESS]: IconName.CHECKMARK,
  [AlertType.ERROR]: IconName.ERROR,
};

interface AlertProps {
  type: AlertType;
  text: string | ReactNode;
  className?: string;
}

export const Alert: FC<AlertProps> = ({ type, text, className }) => {
  const classNames = classnames(css.root, className);
  const iconName = iconMap[type];

  return (
    <div className={classNames}>
      <Icon name={iconName} className={css.icon} />
      <p className={css.text}>{text}</p>
    </div>
  );
};
