import { VideoCallStatus } from '@/types/component';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface VideoCallStore {
  status: VideoCallStatus;
  setVideoCallStatus: (status: VideoCallStatus) => void;
}

export const selectStatus =
  () =>
  ({ status }: VideoCallStore) =>
    status;

export const videoCallIsStarted = ({ status }: VideoCallStore) =>
  ['busy', 'CONNECTED', 'connecting', 'ON_HOLD', 'START'].includes(status);

export const useVideoCallStore = createWithEqualityFn<VideoCallStore>()(
  devtools(
    immer((set) => ({
      status: VideoCallStatus.UNINITIALIZED,
      setVideoCallStatus: (status: VideoCallStatus) => {
        set((state) => {
          state.status = status;
        });
      },
    })),
  ),
);
