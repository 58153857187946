import { FC } from 'react';
import classnames from 'classnames';
import { Loader } from '../../../../components/loader/loader';
import css from './map-spinner.module.scss';

export interface MapSearchResultProps {
  className?: string;
}

export const MapSpinner: FC<MapSearchResultProps> = ({ className }) => {
  const classNames = classnames(css.root, className);
  return (
    <div className={classNames}>
      <Loader />
    </div>
  );
};
