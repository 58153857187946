import type { TFunction } from 'i18next';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import type { Image as ImageType } from 'pxp-content-api-schema/types';

import { ScrollArea } from '@/components/scroll-area/scroll-area';

import css from './image-slider.module.scss';

export interface Props {
  images?: ImageType[];
}

const getImageAlt = (image: ImageType, t: TFunction): string =>
  t('general.map.poiImageAlt', {
    imageTitle: image?.title || 'poi',
    defaultValue: 'Image of poi',
  });

export const ImageSlider = ({ images }: Props) => {
  const { t } = useTranslation();
  return (
    <ScrollArea orientation="horizontal">
      <div className={css.slider}>
        {images?.map((image, index) =>
          image?.src ? (
            <div
              key={`${image?.identifier}_${index}`}
              className={css.sliderImage}
            >
              <Image
                src={image?.src}
                alt={getImageAlt(image, t)}
                layout="fill"
                objectFit="cover"
              />
            </div>
          ) : null,
        )}
      </div>
    </ScrollArea>
  );
};
