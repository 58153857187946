import type { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import css from './feedback-received-title.module.scss';

interface FeedbackReceivedTitleProps {
  className?: string;
}

export const FeedbackReceivedTitle: FC<FeedbackReceivedTitleProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const classNames = classnames(css.root, className);

  return (
    <h4 className={classNames}>
      {t('general.csat.feedback.feedbackReceived.title', {
        defaultValue: 'Thank you for your feedback.',
      })}
    </h4>
  );
};
