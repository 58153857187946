import classnames from 'classnames';
import debounce from 'lodash/debounce';
import type {
  ChangeEvent,
  HTMLAttributes,
  MouseEvent,
  MutableRefObject,
} from 'react';

import { SrOnly } from '@/components/sr-only/sr-only';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { IconName } from '@/types/icon';

import { Icon } from '../icon/icon';

import css from './input-search.module.scss';

const DEFAULT_DEBOUNCE_TIME = 1000;
const MAX_CHAR_LENGTH = 50;

interface Props
  extends Omit<Partial<HTMLAttributes<HTMLInputElement>>, 'onChange'> {
  className?: string;
  inputClassName?: string;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  onChange(value: string): void;
  placeholder: string;
  debounceTime?: number;
  defaultValue?: string;
  label: string;
}

const INPUT_ID = 'query';

export const InputSearch = (props: Props) => {
  const {
    className,
    inputClassName,
    inputRef,
    onChange,
    placeholder,
    debounceTime = DEFAULT_DEBOUNCE_TIME,
    id = INPUT_ID,
    label,
    ...restProps
  } = props;
  const classNames = classnames(css.root, className);
  const inputClassNames = classnames(css.input, inputClassName);
  const debouncedOnChange = debounce(onChange, debounceTime);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value.length > MAX_CHAR_LENGTH) {
      return;
    }

    debouncedOnChange.cancel();

    if (!value.length) {
      onChange('');
      return;
    }

    debouncedOnChange(value);
  };

  const onClick = (event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget?.dispatchEvent(new Event('focus')); // make sure that virtual keyboard is shown
  };

  return (
    <div className={classNames}>
      <SrOnly>
        <label htmlFor={id}>{label}</label>
      </SrOnly>
      <input
        ref={inputRef}
        name={id}
        id={id}
        {...getTestIdProp('search-input')}
        type="search"
        className={inputClassNames}
        onInput={onInputChange}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onClick={onClick}
        placeholder={placeholder}
        autoComplete="off"
        autoCapitalize="none"
        autoCorrect="off"
        spellCheck="false"
        maxLength={MAX_CHAR_LENGTH}
        {...restProps}
      />
      <Icon name={IconName.SEARCH} className={css.icon} />
    </div>
  );
};
