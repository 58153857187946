import classnames from 'classnames';
import { useRouter } from 'next/router';

import { Path, QueryParam } from '../../enums/route';
import { useQueryParam } from '../../hooks/use-query-param';
import { FlightInfoModuleProvider } from '../../modules/flight-info/context/flight-info-module-provider';
import { MapModule } from '../../modules/map';
import { CenterMapButton } from '../../modules/map/components/center-map-button/center-map-button';
import { ContentTop } from '../../modules/map/components/content-top/content-top';
import { FloorSwitcher } from '../../modules/map/components/floor-switcher/floor-switcher';
import { HideWhenRouting } from '../../modules/map/components/hide-when-routing/hide-when-routing';
import { MapFlightInfoDetail } from '../../modules/map/components/map-flight-info-detail/map-flight-info-detail';
import { MapPoiDetail } from '../../modules/map/components/map-poi-detail/map-poi-detail';
import { MapSearch } from '../../modules/map/components/map-search/map-search';
import { MouseEventHandler } from '../../modules/map/components/mouse-event-handler/mouse-event-handler';
import { WayFinding } from '../../modules/map/components/way-finding/way-finding';
import { WayFindingType } from '../../modules/map/store/types';
import { FeedbackOverlayButton } from '../feedback/feedback-overlay-button';

import css from './the-map.module.scss';

export const TheMap = () => {
  const flightId = useQueryParam(QueryParam.FLIGHT_ID);
  const poiId = useQueryParam(QueryParam.POI_ID);
  const { pathname } = useRouter();
  const isMapPage = pathname === Path.MAP.toString();
  const classNames = classnames(css.root, isMapPage && css.active);

  return (
    <div className={classNames}>
      <MapModule>
        {isMapPage && (
          <>
            <ContentTop>
              <HideWhenRouting>
                {flightId && (
                  <FlightInfoModuleProvider>
                    <MapFlightInfoDetail />
                  </FlightInfoModuleProvider>
                )}
              </HideWhenRouting>
              <WayFinding type={WayFindingType.GATE} />
              <FloorSwitcher />
              <CenterMapButton />
            </ContentTop>
            <HideWhenRouting>
              {!poiId && <MapSearch />}
              {poiId && <MapPoiDetail />}
            </HideWhenRouting>
            <WayFinding type={WayFindingType.POI} />
            <FeedbackOverlayButton context="map" />
            <MouseEventHandler />
          </>
        )}
      </MapModule>
    </div>
  );
};
