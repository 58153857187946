import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface BoardingPassInfo {
  name: string;
  flightNumber: string;
  destination: string;
  date: Date;
  hasNewGate: boolean;
  originalDepartureTime: Date | null;
  newDepartureTime: Date | null;
  gate: string | null;
  departures: string | null;
  checkIn: string | null;
}

interface BoardingPassInfoStore {
  boardingPass: BoardingPassInfo | null;
  setBoardingPassInfo: (boardingPassInfo: BoardingPassInfo) => void;
  clearBoardingPassInfo: () => void;
}

export const useBoardingPassInfoStore =
  createWithEqualityFn<BoardingPassInfoStore>()(
    devtools(
      immer((set) => ({
        boardingPass: null,
        setBoardingPassInfo: (boardingPassInfo) =>
          set((state) => {
            state.boardingPass = boardingPassInfo;
          }),
        clearBoardingPassInfo: () =>
          set((state) => {
            state.boardingPass = null;
          }),
      })),
    ),
  );
