"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightItineraryTimelineItemType = exports.FlightInfoItemType = void 0;
var FlightInfoItemType;
(function (FlightInfoItemType) {
    FlightInfoItemType["ARRIVAL_DATE"] = "ARRIVAL_DATE";
    FlightInfoItemType["ARRIVAL_HALL"] = "ARRIVAL_HALL";
    FlightInfoItemType["ARRIVAL_TIME"] = "ARRIVAL_TIME";
    FlightInfoItemType["BAGGAGE_BELT"] = "BAGGAGE_BELT";
    FlightInfoItemType["BOARDING"] = "BOARDING";
    FlightInfoItemType["CHECK_IN"] = "CHECK_IN";
    FlightInfoItemType["DEPARTURE_DATE"] = "DEPARTURE_DATE";
    FlightInfoItemType["DEPARTURE_HALL"] = "DEPARTURE_HALL";
    FlightInfoItemType["DEPARTURE_TIME"] = "DEPARTURE_TIME";
    FlightInfoItemType["GATE"] = "GATE";
    FlightInfoItemType["TRANSFER"] = "TRANSFER";
    FlightInfoItemType["ROUTE"] = "ROUTE";
})(FlightInfoItemType || (exports.FlightInfoItemType = FlightInfoItemType = {}));
var FlightItineraryTimelineItemType;
(function (FlightItineraryTimelineItemType) {
    FlightItineraryTimelineItemType["CURRENT_LOCATION"] = "CURRENT_LOCATION";
    FlightItineraryTimelineItemType["CHECK_IN"] = "CHECK_IN";
    FlightItineraryTimelineItemType["SECURITY"] = "SECURITY";
    FlightItineraryTimelineItemType["PASSPORT_CONTROL"] = "PASSPORT_CONTROL";
    FlightItineraryTimelineItemType["DISCOVER_POIS"] = "DISCOVER_POIS";
    FlightItineraryTimelineItemType["AIRLINE_LOUNGE"] = "AIRLINE_LOUNGE";
    FlightItineraryTimelineItemType["EXPECTED_BOARDING"] = "EXPECTED_BOARDING";
    FlightItineraryTimelineItemType["DEPARTURE"] = "DEPARTURE";
    FlightItineraryTimelineItemType["WALKING_TIME_SECURITY_TO_GATE"] = "WALKING_TIME_SECURITY_TO_GATE";
    FlightItineraryTimelineItemType["ARRIVAL_HALL"] = "ARRIVAL_HALL";
    FlightItineraryTimelineItemType["BAGGAGE_PICK_UP"] = "BAGGAGE_PICK_UP";
    FlightItineraryTimelineItemType["CUSTOMS"] = "CUSTOMS";
    FlightItineraryTimelineItemType["ONWARD_TRAVEL"] = "ONWARD_TRAVEL";
    FlightItineraryTimelineItemType["ODD_SIZED_BAGGAGE"] = "ODD_SIZED_BAGGAGE";
    FlightItineraryTimelineItemType["TRANSFER_SECURITY"] = "TRANSFER_SECURITY";
})(FlightItineraryTimelineItemType || (exports.FlightItineraryTimelineItemType = FlightItineraryTimelineItemType = {}));
