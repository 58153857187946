import type { Leg } from 'bcbp';
import { decode } from 'bcbp';
import { format } from 'date-fns';

export const ERROR_MESSAGE = {
  noLegsFound:
    'Barcode could not be recognized, because the decode was unsuccessful or there were no legs found',
  noDepartureAirports:
    'Barcode could not be recognized, because the flight has no departure airports',
  noFlightInformation:
    'Barcode could not be recognized; it did not contain a flightNumber, flightDate, and/or carrier',
  isOldFlight: 'This boarding pass belongs to an old flight',
  notArrivingOrDeparting:
    'This flight is neither departing from nor arriving to airport',
};

function decodeBcbpString(bcbpString: string, date: Date) {
  const currentYear = format(date, 'yyyy');
  return decode(bcbpString, Number(currentYear));
}

export const activeFlightFromBarcodeString = (
  bcbpString: string,
  airportShortCode: string,
  referenceDate = new Date(),
): Leg => {
  const output = decodeBcbpString(bcbpString, referenceDate);

  // check path to legs to narrow type
  if (!output?.data?.legs || output?.data?.legs?.length < 1) {
    throw new Error(ERROR_MESSAGE.noLegsFound);
  }

  const { legs } = output.data;

  const hasDepartureAirport = legs.some((leg: Leg) => leg.departureAirport);

  if (!hasDepartureAirport) {
    throw new Error(ERROR_MESSAGE.noDepartureAirports);
  }

  const arrivalFlight = legs.find(
    (leg: Leg) => leg.arrivalAirport?.toUpperCase() === airportShortCode,
  );
  const departureFlight = legs.find(
    (leg: Leg) => leg.departureAirport?.toUpperCase() === airportShortCode,
  );

  /**
   * The activeFlight is determined by the following logic: The arrival itinerary should be shown
   * for boarding passes with destination ‘leg’ AMS and no further destinations. Otherwise, we show
   * the departure itinerary.
   */
  const activeFlight: Leg | undefined = departureFlight || arrivalFlight;

  if (!activeFlight) {
    throw new Error(ERROR_MESSAGE.notArrivingOrDeparting);
  }

  return activeFlight;
};

// Check if the string is a valid BCBP or the config route
export const isValidBcbp = (bcbp: string) => {
  const regex =
    /^M1[A-Z\/]+(\s+)?[A-Z0-9]+(\s+)?[A-Z]{1,3}(\s+)?[A-Z]{1,3}(\s+)?[A-Z0-9]{1,3}(\s+)?[0-9]{1,4}(\s+)?[0-9]{3}[0-9A-Z]+\s+[0-9]{3}$/;
  return regex.test(bcbp);
};
