import { useRouter } from 'next/router';

interface useQueryParamOptions {
  forceSingle?: boolean;
}

export const useQueryParam = (
  parameter: string,
  options: useQueryParamOptions = {},
) => {
  const {
    query: { [parameter]: requestedParam },
  } = useRouter();
  const { forceSingle = true } = options;

  return forceSingle && Array.isArray(requestedParam)
    ? requestedParam[0]
    : requestedParam;
};
