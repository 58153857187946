import type { ChangeEvent, FC, FormEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { ContainsPIIError } from '../../lib/pii';
import { containsPII } from '../../lib/pii';
import { ButtonBare } from '../../../../components/button-bare/button';
import { FeedbackReceivedTitle } from '../feedback-received-title/feedback-received-title';
import css from './csat-feedback-input.module.scss';

interface CsatFeedbackInputProps {
  onSubmit: (feedback: string) => void;
}

const errorMessageTranslationKeyMap: Record<string, Record<string, string>> = {
  EMPTY: {
    key: 'general.csat.feedback.input.error.empty',
    defaultValue: 'Please provide feedback',
  },
  EMAIL: {
    key: 'general.csat.feedback.input.error.pii',
    defaultValue:
      'Please do not provide personal information in your feedback.',
  },
  PHONE: {
    key: 'general.csat.feedback.input.error.pii',
    defaultValue:
      'Please do not provide personal information in your feedback.',
  },
};

export const CsatFeedbackInput: FC<CsatFeedbackInputProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<ContainsPIIError | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const feedback = inputValue.trim();

    if (!feedback?.length) {
      setError({
        type: 'EMPTY',
        message: 'Please provide feedback',
      });
      return;
    }

    onSubmit(feedback);
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const feedback = event.target.value || '';
    setInputValue(feedback);

    if (error) {
      setError(null);
    }

    const pii = containsPII(feedback);

    if (pii?.length) {
      setError(pii[0]);
      return;
    }
  };

  return (
    <div className={css.root}>
      <FeedbackReceivedTitle />
      <p className={css.text}>
        {t('general.csat.feedback.input.description', {
          defaultValue:
            'Do you have any suggestions how we can improve this service?',
        })}
      </p>
      <form onSubmit={handleSubmit}>
        <div className={css.inputWrapper}>
          <textarea className={css.input} onChange={onChange} />
        </div>
        {!!error && (
          <p className={css.errorText}>
            {t(errorMessageTranslationKeyMap[error.type].key, {
              defaultValue:
                errorMessageTranslationKeyMap[error.type].defaultValue,
            })}
          </p>
        )}
        <ButtonBare
          className={css.button}
          disabled={!!error}
          type="submit"
          size="SMALL"
          label={t('general.csat.feedback.button.submit', {
            defaultValue: 'Submit feedback',
          })}
        />
      </form>
    </div>
  );
};
