import React, { useEffect } from 'react';
import classnames from 'classnames';
import { TheFooter } from '../components/the-footer/the-footer';
import { TheHeader } from '../components/the-header/the-header';
import css from './fullscreenLayout.module.scss';
import type { LayoutProps } from './get-layout';

export const FullscreenLayout: React.FC<LayoutProps> = ({
  className,
  children,
}) => {
  const classes = classnames(css.root, className);

  useEffect(() => {
    document.body.classList.add('fullScreen');

    return () => {
      document.body.classList.remove('fullScreen');
    };
  }, []);

  return (
    <div className={classes}>
      <TheHeader />
      <main className={css.main}>{children}</main>
      <TheFooter className={css.footer} />
    </div>
  );
};
