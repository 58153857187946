import { useTranslation } from 'next-i18next';

import { IconName } from '@/types/icon';

export interface FeedbackOption {
  icon: IconName;
  label: string;
  value: number;
}

export function useFeedbackOptions(): FeedbackOption[] {
  const { t } = useTranslation();

  return [
    {
      value: 1,
      icon: IconName.FEEDBACK_E,
      label: t('video-call.feedback.option.1', { defaultValue: 'Very poor' }),
    },
    {
      value: 2,
      icon: IconName.FEEDBACK_D,
      label: t('video-call.feedback.option.2', { defaultValue: 'Poor' }),
    },
    {
      value: 3,
      icon: IconName.FEEDBACK_C,
      label: t('video-call.feedback.option.3', { defaultValue: 'Average' }),
    },
    {
      value: 4,
      icon: IconName.FEEDBACK_B,
      label: t('video-call.feedback.option.4', { defaultValue: 'Good' }),
    },
    {
      value: 5,
      icon: IconName.FEEDBACK_A,
      label: t('video-call.feedback.option.5', { defaultValue: 'Excellent' }),
    },
  ];
}
