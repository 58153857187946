import React from 'react';
import { FlightDirection } from '@/modules/flight-info/types/service';
import type { FlightInfoModule } from 'pxp-api/clients/strapi/config-type';
import type { FlightInfoItemType } from 'pxp-strapi-types';
import { Module } from '../../../enums/module';
import { useModule } from '../../../hooks/use-module';

export const useFlightInfoItems = (
  items: Partial<Record<FlightInfoItemType, React.ReactNode>>,
  flightDirection: FlightDirection = FlightDirection.DEPARTURE,
) => {
  const flightInfoModule = useModule<FlightInfoModule>(Module.FLIGHT_INFO);

  const flightDirectionConfiguration =
    flightInfoModule?.properties.flightDirectionConfigurations?.find(
      ({ direction }) => direction === flightDirection,
    );

  return (
    <>
      {flightDirectionConfiguration?.flightInfoItems?.map(
        (item) => items?.[item] ?? null,
      )}
    </>
  );
};
