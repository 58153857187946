import { useSSU } from '@/context/ssu-provider';
import type { QRCodeImpressionType } from '@/modules/analytics/types/event-props';
import type { IconName } from '@/types/icon';

import { ExternalLinkKiosk } from './external-link-kiosk';
import { ExternalLinkMobile } from './external-link-mobile';
interface Props {
  label: string;
  value?: string;
  modalTitle?: string;
  icon?: IconName;
  qrCodeImpressionType: QRCodeImpressionType;
  isButtonStyle?: boolean;
  isNumber?: boolean;
}
export const ExternalLink = ({
  modalTitle,
  qrCodeImpressionType,
  isNumber,
  ...sharedProps
}: Props) => {
  const {
    config: { isMobile },
  } = useSSU();

  if (!sharedProps.value) {
    return null;
  }

  return isMobile ? (
    <ExternalLinkMobile isNumber={isNumber} {...sharedProps} />
  ) : (
    <ExternalLinkKiosk
      modalTitle={modalTitle}
      qrCodeImpressionType={qrCodeImpressionType}
      {...sharedProps}
    />
  );
};
