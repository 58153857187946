import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import css from './container.module.scss';

export interface ContainerProps {
  className?: string;
  children: ReactNode;
}

export const Container: FC<ContainerProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);
  return <div className={classes}>{children}</div>;
};
