import { useEffect } from 'react';

import { isPublic } from '@/lib/environment';

export const usePreventRightClick = () => {
  useEffect(() => {
    if (isPublic) {
      return;
    }

    const handleRightClick = (e: Event) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleRightClick);

    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);
};
