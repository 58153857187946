import type { SSU } from '../../types/content-schema';

export type ActionType = NonNullable<
  SSU['config']['barcodeScanner']
>['actionType'];

export interface Config {
  productId: number;
  vendorId: number;
  isEnabled: boolean;
  actionType: ActionType;
  shouldNotify: boolean;
  successNotificationKey: string | null;
  errorNotificationKey: string | null;
  type?: string;
  usbDeviceId?: string;
}

export const defaultConfig: Config = {
  productId: 0,
  vendorId: 0,
  isEnabled: false,
  actionType: 'SHOW_FLIGHT_INFO',
  shouldNotify: false,
  successNotificationKey: null,
  errorNotificationKey: null,
};

/**
 * Helper function to parse USB spec ids
 * @param id hex string representation of a 16bit number (0x1234)
 * @param name hex string representation of a 16bit number (0x1234)
 * @returns tuple of numbers representing the scanner productId and vendorId
 */
function parseIds(id?: string, name?: string) {
  if (!id || !name) {
    return [0, 0];
  }

  const ids = [id, name].map((n) => parseInt(n, 16));
  if (ids.some(Number.isNaN)) {
    throw new TypeError(
      `Could not parse productId and vendorId for barcode scanner with id ${id} and name ${name}.`,
    );
  }
  return ids;
}

export function makeConfig(baseConfig: SSU['config']): Config {
  const { barcodeScanner } = baseConfig;
  if (!barcodeScanner) {
    return defaultConfig;
  }

  // Correct format for ids is enforced in Strapi using a regex.
  const ids = parseIds(barcodeScanner.id, barcodeScanner.name);
  return {
    productId: ids[0],
    vendorId: ids[1],
    isEnabled: !!barcodeScanner.isEnabled,
    shouldNotify: !!barcodeScanner.notifyOnScan,
    actionType: barcodeScanner.actionType || 'SHOW_FLIGHT_INFO',
    successNotificationKey: !!barcodeScanner.notifyOnScan
      ? barcodeScanner.successNotification ?? ''
      : null,
    errorNotificationKey: !!barcodeScanner.notifyOnScan
      ? barcodeScanner.errorNotification ?? ''
      : null,
    type: barcodeScanner.type,
    usbDeviceId: barcodeScanner.usbDeviceId,
  };
}
