import type {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

import type { Page } from '../types/content-schema';

import { ContentLayout } from './contentLayout';
import { DefaultLayout } from './defaultLayout';
import { EmptyFullscreenLayout } from './emptyFullscreenLayout';
import { EmptyLayout } from './emptyLayout';
import { FullscreenLayout } from './fullscreenLayout';
import { HeaderOnlyLayout } from './headerOnlyLayout';
import { HomeLayout } from './homeLayout';
import { WideLayout } from './wideLayout';

export interface LayoutProps extends Partial<Page> {
  className?: string;
  children: ReactNode;
}

export enum LayoutName {
  DEFAULT = 'DEFAULT',
  FULLSCREEN = 'FULLSCREEN',
  FULLSCREEN_EMPTY = 'FULLSCREEN_EMPTY',
  HOME = 'HOME',
  CONTENT = 'CONTENT',
  WIDE = 'WIDE',
  HEADER_ONLY = 'HEADER_ONLY',
  EMPTY_LAYOUT = 'EMPTY_LAYOUT',
}

type LayoutMap = Record<string, ComponentType<LayoutProps>>;

export const layoutMap: LayoutMap = {
  [LayoutName.DEFAULT]: DefaultLayout,
  [LayoutName.FULLSCREEN]: FullscreenLayout,
  [LayoutName.FULLSCREEN_EMPTY]: EmptyFullscreenLayout,
  [LayoutName.HOME]: HomeLayout,
  [LayoutName.CONTENT]: ContentLayout,
  [LayoutName.WIDE]: WideLayout,
  [LayoutName.HEADER_ONLY]: HeaderOnlyLayout,
  [LayoutName.EMPTY_LAYOUT]: EmptyLayout,
};

export const createGetLayout =
  (layouts: LayoutMap) =>
  (
    element: ReactElement<{ page?: Page }>,
    layoutName: string = LayoutName.DEFAULT,
  ) => {
    const {
      props: { page },
    } = element;

    const layout = page?.properties?.layout?.type ?? layoutName;
    const Layout = layouts[layout] as ComponentType<
      PropsWithChildren<Page['properties']>
    >;

    return <Layout {...page}>{element}</Layout>;
  };

export const getLayout = createGetLayout(layoutMap);
