import { AudioVideoStatus, VideoCallStatus } from '@/types/component';

type CallStatus = VideoCallStatus | AudioVideoStatus;
type CallStatusMap = {
  [key in CallStatus]?: 'connecting' | 'connected' | 'busy' | 'completed';
};

export const getCallStatus = (videoCallStatus: CallStatus) =>
  (
    ({
      [VideoCallStatus.CONNECTING]: 'connecting',
      [VideoCallStatus.CONNECTED]: 'connected',
      [VideoCallStatus.BUSY]: 'busy',
      [VideoCallStatus.CALL_ENDED]: 'completed',
      [VideoCallStatus.ON_HOLD]: 'connected',
      [AudioVideoStatus.CAMERA_OFF]: 'connected',
      [AudioVideoStatus.CAMERA_ON]: 'connected',
    }) as CallStatusMap
  )[videoCallStatus];
