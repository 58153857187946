import { useState } from 'react';

import { State } from './types';

export type UseFeedbackStateKeys = State | string;
export type UseFeedbackStates = Record<
  UseFeedbackStateKeys,
  {
    title:
      | 'general.feedback.title.initial'
      | 'general.feedback.title.positive'
      | 'general.feedback.title.negative'
      | 'general.feedback.translations.title.initial'
      | 'general.feedback.translations.title.success';
    text?: 'general.feedback.text.initial';
  }
>;

export const useFeedbackState = (states: UseFeedbackStates) => {
  const [state, setState] = useState(states[State.INIT]);

  const setStateByKey = (key: UseFeedbackStateKeys) => {
    setState(states[key]);
  };

  return {
    state,
    setStateByKey,
  };
};
