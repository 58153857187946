import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { i18n } from 'next-i18next';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';

export const I18nEffects: FC<PropsWithChildren> = () => {
  const dataLayer = useDataLayer();

  useEffect(() => {
    document.documentElement.setAttribute('dir', i18n?.dir() || 'ltr');
    document.documentElement.setAttribute('lang', i18n?.language || '');
  });

  useEffect(() => {
    dataLayer.update({
      language: i18n?.language,
      translations: i18n?.store?.data,
    });
  });

  return null;
};
