import classnames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import { forwardRef, useCallback, useEffect } from 'react';

import { getTestIdProp } from '@/lib/get-test-id-prop';
import { Breakpoint } from '@/types/breakpoint';

import css from './map-search-wrapper.module.scss';

interface MapSearchWrapperProps {
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  onAfterOpen?: () => void;
}

export const MapSearchWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<MapSearchWrapperProps>
>(({ children, className, isOpen, onClose, onAfterOpen }, ref) => {
  const controls = useAnimation();
  const classNames = classnames(css.root, className);

  const animateSearchOpen = useCallback(async () => {
    const isMobile =
      typeof window !== undefined &&
      window.innerWidth < Breakpoint.MEDIUM.valueOf();

    await controls.start({
      top: isMobile ? '0vh' : '30vh',
      transition: { type: 'easeInOut' },
    });

    if (isMobile) window.scrollTo({ top: 0, behavior: 'smooth' });

    onAfterOpen?.();
  }, [controls, onAfterOpen]);

  const animateSearchClose = useCallback(() => {
    onClose?.();

    controls.start({
      top: 'auto',
      transition: { type: 'easeInOut' },
    });
  }, [controls, onClose]);

  useEffect(() => {
    if (isOpen) {
      animateSearchOpen();
      return;
    }

    animateSearchClose();
  }, [animateSearchClose, animateSearchOpen, isOpen]);

  return (
    <motion.section
      animate={controls}
      className={classNames}
      ref={ref}
      {...getTestIdProp('map-search-container')}
    >
      {children}
    </motion.section>
  );
});
