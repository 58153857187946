import classnames from 'classnames';
import { isBefore, parse } from 'date-fns';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import type { getFlightStatus } from 'pxp-api/clients/flight-info-api/helpers/status';

import { SrOnly } from '@/components/sr-only/sr-only';

import css from './flight-time.module.scss';

interface DepartureTimeProps {
  className?: string;
  estimatedTime?: string;
  scheduledTime: string;
  flightStatus: ReturnType<typeof getFlightStatus>;
  isInline?: boolean;
  isMonochrome?: boolean;
}

export const FlightTime: FC<DepartureTimeProps> = ({
  className,
  estimatedTime,
  scheduledTime,
  flightStatus,
  isInline,
  isMonochrome,
}) => {
  const classNames = classnames(
    css.root,
    isInline && css.isInline,
    isMonochrome && css.isMonochrome,
    className,
  );
  const { t } = useTranslation();
  const { isCancelled, isDelayed, isEarly } = flightStatus;

  const isLaterThanPlanned =
    isDelayed ||
    isBefore(
      parse(scheduledTime, 'HH:mm', new Date()),
      parse(estimatedTime || scheduledTime, 'HH:mm', new Date()),
    );

  const isEarlierThanPlanned =
    isEarly ||
    isBefore(
      parse(estimatedTime || scheduledTime, 'HH:mm', new Date()),
      parse(scheduledTime, 'HH:mm', new Date()),
    );

  if (isCancelled) {
    return (
      <span className={classNames}>
        <span className={css.strike}>{scheduledTime}</span>
      </span>
    );
  }

  return (
    <span className={classNames}>
      {isLaterThanPlanned || isEarlierThanPlanned ? (
        <>
          <SrOnly>
            {t('components.flight-time.scheduled-time', 'Scheduled time')}
          </SrOnly>
          <del className={css.strike}>{scheduledTime}</del>
          <SrOnly>
            {t('components.flight-time.estimated-time', 'Estimated time')}
          </SrOnly>
          <span className={isLaterThanPlanned ? css.warn : ''}>
            {estimatedTime}
          </span>
        </>
      ) : (
        estimatedTime || scheduledTime
      )}
    </span>
  );
};
