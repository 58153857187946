import classnames from 'classnames';
import type { PropsWithChildren } from 'react';
import { FlightStatus } from 'pxp-api/clients/flight-info-api/types/flight';
import type { Flight } from 'pxp-flight-api-schema/types';

import { Badge } from '../badge/badge';

import css from './gate-label.module.scss';

interface GateLabelProps {
  gateNumber: string;
  flightStatus?: Flight['status'];
  prefix?: string;
  className?: string;
}

export const GateLabel = ({
  prefix,
  gateNumber,
  flightStatus,
  className,
}: GateLabelProps) => {
  const classNames = classnames(
    css.root,
    flightStatus === FlightStatus.GATE_CHANGED && css.changed,
    className,
  );

  return (
    <Badge className={classNames}>
      {prefix && <span className={css.prefix}>{prefix}&nbsp;</span>}
      {gateNumber}
    </Badge>
  );
};

export const UnknownGate = ({ children }: PropsWithChildren) => (
  <div className={css.unknown}>{children}</div>
);
