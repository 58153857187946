import type { UseFeedbackStates } from '@/components/feedback/use-feedback-state';

import { Mood, State } from '../types';

export const FEEDBACK_BLOCK_STATE = {
  [State.INIT]: {
    text: 'general.feedback.text.initial',
    title: 'general.feedback.title.initial',
  },
  [Mood.POSITIVE]: {
    title: 'general.feedback.title.positive',
  },
  [Mood.NEGATIVE]: {
    title: 'general.feedback.title.negative',
  },
} satisfies UseFeedbackStates;
