import dynamic from 'next/dynamic';
import {
  type ComponentType,
  type FC,
  type ReactNode,
  createContext,
  useContext,
} from 'react';

import type { IconProviderProps } from '../lib/create-icon-provider';
import type { IconSetName } from '../types/icon';

import { useSSU } from './ssu-provider';

const DEFAULT_ICON_SET_NAME = 'RUNWAY';

const iconSetMap: Record<IconSetName, ComponentType<IconProviderProps>> = {
  RUNWAY: dynamic<IconProviderProps>(
    () => import('../components/icon/lib/runway'),
  ),
  HAMAD: dynamic<IconProviderProps>(
    () => import('../components/icon/lib/hamad'),
  ),
};

interface IconSetProviderProps {
  children: ReactNode;
}
export const IconSetContext =
  createContext<ComponentType<IconProviderProps> | null>(null);

export const IconSetProvider: FC<IconSetProviderProps> = ({ children }) => {
  const { theme } = useSSU();
  const iconSetName = theme?.icons?.lib ?? DEFAULT_ICON_SET_NAME;
  const iconSet = iconSetMap[iconSetName];

  return (
    <IconSetContext.Provider value={iconSet}>
      {children}
    </IconSetContext.Provider>
  );
};

export function useIconSet() {
  const context = useContext(IconSetContext);

  if (!context) {
    throw new Error('useIconSet must be used within an IconSetProvider');
  }

  return context;
}
