import type { HTMLAttributes, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import classnames from 'classnames';
import css from './card.module.scss';

export type CardProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, ...props }, ref) => {
    const classNames = classnames(css.root, className);
    return <div ref={ref} className={classNames} {...props} />;
  },
);
