import { getFeedbackEventLabel } from '@/modules/analytics/services/schiphol/helpers/get-feedback-event-label';

import type {
  DefaultMeta,
  Feedback,
  FeedbackCsat,
} from '../../../types/event-props';
import { ContentType } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

const GTMContentTypeMap = {
  [ContentType.QUESTION]: 'content_question',
  [ContentType.TOPIC]: 'content_topic',
};

export const mapFeedbackSubmit: Mapper<Feedback> = ({
  meta: { value, slug, contentType, label = '' },
  data,
}) => ({
  eventName:
    value === 'positive'
      ? GTMEventName.FEEDBACK_SELECT_POSITIVE
      : GTMEventName.FEEDBACK_SELECT_NEGATIVE,
  eventLabel: getFeedbackEventLabel(label, data, slug, contentType),
  contentType: contentType ? GTMContentTypeMap[contentType] : '',
});

export const mapFeedbackVideoCall: Mapper<DefaultMeta> = ({
  meta: { value },
}) => ({
  eventName: GTMEventName.FEEDBACK_VIDEO_CALL,
  eventLabel: value,
});

export const mapFeedbackCSAT: Mapper<FeedbackCsat> = ({
  meta: { value, feedback },
}) => {
  if (feedback) {
    return {
      eventName: GTMEventName.CSAT_INPUT,
      csat_feedback: feedback,
    };
  }

  return {
    eventName: GTMEventName.CSAT_SCORE,
    eventLabel: value,
    csat_score: value,
  };
};

export const mapFeedbackTranslations: Mapper<Feedback> = ({
  meta: { value, slug },
}) => ({
  eventName: GTMEventName.FEEDBACK_TRANSLATIONS,
  eventLabel: slug,
  value,
});

export const mapFeedbackCSATImpression = () => {
  return {
    eventName: GTMEventName.CSAT_IMPRESSION,
  };
};
