import type { ParsedUrlQuery } from 'querystring';

import type { NextRouter } from 'next/router';
import { isGateChanged } from 'pxp-api/clients/flight-info-api/helpers/status';
import type { Flight } from 'pxp-flight-api-schema/types';

import { isPublic } from '@/lib/environment';
import type { FlightDirection } from '@/modules/flight-info/types/service';

import { Path, QueryParam, QueryParamState } from '../../enums/route';
import { mergeSsuQueryParams } from '../../lib/url';
import type { BoardingPassInfo } from '../../store/boardingPassInfoStore';
import { useBoardingPassInfoStore } from '../../store/boardingPassInfoStore';
import { Modal, useModalStore } from '../../store/modalStore';

function enrichBoardingPassInfo(
  boardingPassInfo: BoardingPassInfo,
  flight?: Flight,
): BoardingPassInfo {
  if (!flight) {
    return boardingPassInfo;
  }
  return {
    ...boardingPassInfo,
    flightNumber: flight.iataCode ?? boardingPassInfo.flightNumber,
    destination: flight.arrival.airportName,
    originalDepartureTime: flight.departure.scheduledDepartureTime
      ? new Date(flight.departure.scheduledDepartureTime)
      : null,
    newDepartureTime: flight.departure.estimatedDepartureTime
      ? new Date(flight.departure.estimatedDepartureTime)
      : null,
    gate: flight.departure.gateNumber,
    hasNewGate: isGateChanged(flight),
    departures: flight.departure.terminalNumber,
    checkIn: flight.departure.checkInDeskNumber ?? null,
  };
}

export function boardingPassActionFactory(
  router: NextRouter,
  getFlightById: (
    flightId: string,
    flightDirection: FlightDirection,
  ) => Promise<Flight | undefined>,
) {
  return {
    route: function (pathname: Path, queryParams?: ParsedUrlQuery) {
      void router.push({
        pathname,
        query: mergeSsuQueryParams(router.query, queryParams),
      });
    },
    showFlightSearch: function (flightId?: string) {
      this.route(
        Path.FLIGHT_INFO,
        flightId ? { [QueryParam.FLIGHT_ID]: flightId } : undefined,
      );
    },
    showFlightInfo: function (
      flightId: string,
      flightDirection: FlightDirection,
    ) {
      this.route(Path.FLIGHT_INFO_DETAIL, {
        [QueryParam.FLIGHT_DIRECTION]: flightDirection,
        [QueryParam.FLIGHT_ID]: flightId,
        [QueryParam.BOARDING_PASS_SCAN]: !isPublic ? 'true' : undefined,
        [QueryParam.SHOCON]: isPublic ? 'true' : undefined,
      });
    },
    showMap: function (flightId: string) {
      this.route(Path.MAP, { [QueryParam.FLIGHT_ID]: flightId });
    },
    showConfig: function () {
      this.route(Path.CONFIG);
    },
    startVideoCall: function () {
      useModalStore.getState().openModal(Modal.VIDEO_CALL);
    },
    persistBoardingPassInfo: async function (
      flightId: string,
      boardingPassInfo: BoardingPassInfo,
      flightDirection: FlightDirection,
    ) {
      let enrichedBoardingPassInfo = boardingPassInfo;

      try {
        // getFlightById will throw an error when a 404 is received.
        const flight = await getFlightById(flightId, flightDirection);
        enrichedBoardingPassInfo = enrichBoardingPassInfo(
          boardingPassInfo,
          flight,
        );
      } catch {
      } finally {
        useBoardingPassInfoStore
          .getState()
          .setBoardingPassInfo(enrichedBoardingPassInfo);
      }
    },
    handleScanError: function () {
      this.route(Path.FLIGHT_INFO, {
        [QueryParam.STATE]: QueryParamState.ERROR,
      });
    },
  };
}
