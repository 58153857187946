import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { useSSU } from '@/context/ssu-provider';
import { Route } from '@/enums/route';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type { DefaultMeta } from '@/modules/analytics/types/event-props';
import { useFlightInfo } from '@/modules/flight-info/hooks/use-flight-info';
import type { BoardingPassInfo } from '@/store/boardingPassInfoStore';
import { useBoardingPassInfoStore } from '@/store/boardingPassInfoStore';
import { useVideoCallStore, videoCallIsStarted } from '@/store/videoCallStore';

import { useToast } from '../use-toast';

import { boardingPassActionFactory } from './actions';
import type { ActionType } from './config';
import { makeConfig } from './config';
import {
  flightInformationFromBarcodeString,
  getBasicBoardingPassInfo,
} from './helpers';

type useBoardingPassScanProps = {
  onSuccess?: (boardingPassInfo: BoardingPassInfo) => void;
  onError?: (e?: unknown) => void;
  isCamera?: boolean;
};

export const useBoardingPassScan = ({
  onSuccess,
  onError,
  isCamera,
}: useBoardingPassScanProps = {}) => {
  const { t } = useTranslation();
  const { config: ssuConfig, airport } = useSSU();
  const router = useRouter();
  const dataLayer = useDataLayer();
  const { getById } = useFlightInfo();
  const { showSuccessToast, showDangerToast } = useToast();
  const videoCallHasStarted = useVideoCallStore(videoCallIsStarted);

  const scannerConfig = makeConfig(ssuConfig);

  const handleBarcodeScan = useCallback(
    async (barcode: string) => {
      // If scanner is disabled, and it's not a camera scan, do nothing
      if (!scannerConfig.isEnabled && !isCamera) {
        return;
      }

      const boardingPassActions = boardingPassActionFactory(router, getById);

      // Route to config page when barcode with content 'config' is scanned
      // Used to configure machine by technicians
      if (barcode === Route.CONFIG.toString()) {
        return boardingPassActions.showConfig();
      }

      try {
        const { flightDirection, flightId } =
          flightInformationFromBarcodeString(barcode, airport.shortCode);

        if (!flightId) {
          throw new TypeError(
            `Expected to get flightId from barcode string, got undefined instead.`,
          );
        }

        const boardingPassInfo = getBasicBoardingPassInfo(
          barcode,
          airport.shortCode,
        );

        // Handle success: run onSuccess callback and optionally show success toast
        if (onSuccess) {
          onSuccess(boardingPassInfo);
        }
        if (
          scannerConfig.shouldNotify &&
          scannerConfig.successNotificationKey
        ) {
          // @ts-expect-error: successNotificationKey is dynamic
          showSuccessToast(t(scannerConfig.successNotificationKey));
        }

        await boardingPassActions.persistBoardingPassInfo(
          flightId,
          boardingPassInfo,
          flightDirection,
        );

        const boardingPassInfoFromState = useBoardingPassInfoStore.getState()
          .boardingPass as BoardingPassInfo;

        dataLayer.publish<
          DefaultMeta & {
            actionType: ActionType;
            boardingPassInfo: BoardingPassInfo;
          }
        >(UIEventName.BOARDING_PASS_SCAN, {
          value: 'success',
          boardingPassInfo: boardingPassInfoFromState,
          actionType: scannerConfig.actionType,
        });

        // If video call has already started, do not redirect
        if (videoCallHasStarted) return;

        // Redirect based on action type
        switch (scannerConfig.actionType) {
          case 'START_VIDEO_CALL':
            return boardingPassActions.startVideoCall();

          case 'SHOW_MAP':
            return boardingPassActions.showMap(flightId);

          case 'SHOW_FLIGHT_INFO':
            return boardingPassActions.showFlightInfo(
              flightId,
              flightDirection,
            );

          default:
            break;
        }
      } catch (e) {
        if (onError) {
          onError(e);
        }

        dataLayer.publish<DefaultMeta>(UIEventName.BOARDING_PASS_SCAN, {
          value: 'error',
        });

        if (scannerConfig.shouldNotify && scannerConfig.errorNotificationKey) {
          // @ts-expect-error: errorNotificationKey is dynamic
          showDangerToast(t(scannerConfig.errorNotificationKey));
        }

        // Route to the flight search page if something goes wrong with getting
        // the flight info from barcode. Not necessary for video calls or camera scans.
        if (scannerConfig.actionType != 'START_VIDEO_CALL' && !isCamera) {
          boardingPassActions.showFlightSearch();
        }
      }
    },
    [
      airport.shortCode,
      dataLayer,
      getById,
      isCamera,
      onError,
      onSuccess,
      router,
      scannerConfig.actionType,
      scannerConfig.errorNotificationKey,
      scannerConfig.isEnabled,
      scannerConfig.shouldNotify,
      scannerConfig.successNotificationKey,
      showDangerToast,
      showSuccessToast,
      t,
      videoCallHasStarted,
    ],
  );

  const showFlightSearch = useCallback(() => {
    const boardingPassActions = boardingPassActionFactory(router, getById);
    boardingPassActions.showFlightSearch();
  }, [getById, router]);

  return { handleBarcodeScan, showFlightSearch } as const;
};
