export enum ButtonAction {
  LINK_TO_POI = 'LINK_TO_POI',
  LINK_TO_PAGE = 'LINK_TO_PAGE',
  LINK_TO_CATEGORY = 'LINK_TO_CATEGORY',
  START_VIDEO_CALL = 'START_VIDEO_CALL',
  OPEN_MODAL = 'OPEN_MODAL',
}

export enum AudioVideoStatus {
  CAMERA_OFF = 'CAMERA_OFF',
  CAMERA_ON = 'CAMERA_ON',
}

export enum VideoCallStatus {
  BUSY = 'busy',
  CONNECTING = 'connecting',
  START = 'START',
  STOP = 'STOP',
  CONNECTED = 'CONNECTED',
  STOP_BY_USER = 'STOP_BY_USER',
  STOP_BY_AGENT = 'STOP_BY_AGENT',
  ON_HOLD = 'ON_HOLD',
  CALL_ENDED = 'CALL_ENDED',
  UNINITIALIZED = 'UNINITIALIZED',
}

export enum ChatStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
