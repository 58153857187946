import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { SrOnly } from '@/components/sr-only/sr-only';

import { Icon } from '../../../../components/icon/icon';
import type { MapFloor } from '../../../../types/content-schema';
import { IconName } from '../../../../types/icon';

import { FloorSwitcherItem } from './floor-switcher-item';
import css from './floor-switcher-modal.module.scss';

export interface FloorSwitcherModalProps {
  floors?: MapFloor[];
  activeFloor?: MapFloor;
  onClickFloor: (id: string) => void;
  onClickClose: (isOpen: boolean) => void;
}

export const FloorSwitcherModal: FC<FloorSwitcherModalProps> = ({
  floors = [],
  activeFloor,
  onClickClose,
  onClickFloor,
}) => {
  const { t } = useTranslation();

  return (
    <div className={css.root}>
      <header className={css.header}>
        <span className={css.label}>{t('general.map.floors')}</span>
        <button className={css.closeButton} onClick={() => onClickClose(false)}>
          <Icon className={css.closeIcon} name={IconName.CLOSE} />
          <SrOnly>
            {t('general.map.floorSwitcherClose', {
              defaultValue: 'Close floor switcher modal',
            })}
          </SrOnly>
        </button>
      </header>
      <ul className={css.floorList}>
        {floors.map((floor) => {
          const isActiveFloor = activeFloor?.id === floor.id;
          return (
            <FloorSwitcherItem
              key={floor.id}
              floor={floor}
              isActiveFloor={isActiveFloor}
              onClickFloor={onClickFloor}
            />
          );
        })}
      </ul>
    </div>
  );
};
