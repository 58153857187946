import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Icon } from '../../../../components/icon/icon';
import { IconName } from '../../../../types/icon';
import { InfoRow } from '../info-row/info-row';
import css from './bus-gate.module.scss';

export const BusGate: FC = () => {
  const { t } = useTranslation();
  return (
    <InfoRow>
      <div className={css.root}>
        <Icon className={css.icon} name={IconName.BUS} />
        <p className={css.text}>
          {t('general.flightInfo.busGate', {
            defaultValue: 'A bus will take you from the gate to the plane',
          })}
        </p>
      </div>
    </InfoRow>
  );
};
