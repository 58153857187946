import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import classnames from 'classnames';
import css from './action-sheet-wrapper.module.scss';

interface ActionSheetWrapperProps {
  className?: string;
}

export const ActionSheetWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ActionSheetWrapperProps>
>(({ children, className }, ref) => {
  const classNames = classnames(css.root, className);

  return (
    <div className={classNames} ref={ref}>
      <div className={css.wrapper}>{children}</div>
    </div>
  );
});
