import classnames from 'classnames';
import delay from 'lodash/delay';
import type { MouseEvent } from 'react';
import { useEffect } from 'react';

import { IconName } from '../../types/icon';
import type { ButtonBareProps } from '../button-bare/button';
import { Icon } from '../icon/icon';

import css from './session-timeout-button.module.scss';

const DEFAULT_TIMEOUT_SECONDS = 5;

interface SessionTimeoutButtonProps extends ButtonBareProps {
  onTimeout?(): void;
  onClick?(event: MouseEvent): void;
  timeOutInSeconds?: number;
}

export const SessionTimeoutButton = ({
  onTimeout,
  timeOutInSeconds = DEFAULT_TIMEOUT_SECONDS,
  className,
  label,
  ...props
}: SessionTimeoutButtonProps) => {
  const timeOutInMilliseconds = timeOutInSeconds * 1000;

  useEffect(() => {
    const timeoutId = delay(() => {
      onTimeout?.();
    }, timeOutInMilliseconds);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [timeOutInMilliseconds, onTimeout]);

  return (
    <button
      {...props}
      className={classnames(css.root, className)}
      style={{ '--duration': `${timeOutInMilliseconds}ms` }}
    >
      {label}
      <Icon className={css.icon} name={IconName.TOUCH} />
    </button>
  );
};
