import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { getTestIdProp } from '../../lib/get-test-id-prop';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';
import type { ModalEvent } from '../../modules/analytics/types/event-props';
import { ModalAction } from '../../modules/analytics/types/event-props';
import { Modal, selectIsOpen, useModalStore } from '../../store/modalStore';
import type { ContentComponentProps } from '../../types/app';
import { ButtonBare } from '../button-bare/button';
import { ModalContainer } from '../modal-container/modal-container';

import { FEEDBACK_BLOCK_STATE } from './constants/feedback-block-state';
import { FeedbackButtons } from './feedback-buttons';
import css from './feedback-overlay.module.scss';
import { State } from './types';
import { useFeedbackState } from './use-feedback-state';

export type FeedbackOverlayProps = ContentComponentProps<{
  context: string;
}>;

export const FeedbackOverlay: FC<FeedbackOverlayProps> = ({ context }) => {
  const { state, setStateByKey } = useFeedbackState(FEEDBACK_BLOCK_STATE);
  const { t } = useTranslation();
  const dataLayer = useDataLayer();
  const isOpen = useModalStore(selectIsOpen(Modal.FEEDBACK_OVERLAY));
  const closeModal = useModalStore((state) => state.closeModal);

  const onClose = () => {
    dataLayer.publish<ModalEvent>(UIEventName.MODAL, {
      type: Modal.FEEDBACK_OVERLAY,
      action: ModalAction.CLOSE,
    });

    closeModal(Modal.FEEDBACK_OVERLAY);
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <div className={css.body}>
        <h1 className={css.title}>{t(state.title)}</h1>
        {state.text && <p className={css.text}>{t(state.text)}</p>}
        {state === FEEDBACK_BLOCK_STATE[State.INIT] && (
          <FeedbackButtons onSubmit={setStateByKey} context={context} />
        )}
        {state !== FEEDBACK_BLOCK_STATE[State.INIT] && (
          <ButtonBare
            onClick={onClose}
            label={t('general.close', { defaultValue: 'Close' })}
            className={css.closeButton}
            {...getTestIdProp('feedback-button-close')}
          />
        )}
      </div>
    </ModalContainer>
  );
};
