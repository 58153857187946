import { RenderExternalLink } from '@/components/render-components-rich-text/render-external-link';
import { RenderLinkToPoi } from '@/components/render-components-rich-text/render-link-to-poi';
import { QueryParam } from '@/enums/route';

type RenderLinkProps = {
  node: HTMLElement;
};
export const RenderLink = ({ node }: RenderLinkProps) => {
  const href = node.getAttribute('href') ?? '';
  const isLinkToPoi = href.includes(QueryParam.POI_ID);
  return isLinkToPoi ? (
    <RenderLinkToPoi node={node} />
  ) : (
    <RenderExternalLink node={node} />
  );
};
