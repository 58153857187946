import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import { DEFAULT_LOCALE } from '@/constants/i18n';
import { useSSU } from '@/context/ssu-provider';
import { Module } from '@/enums/module';
import { LocalStorageRoute, Path, QueryParam, Route } from '@/enums/route';
import { useCustomLocalStorageState } from '@/hooks/use-custom-local-storage-state';
import { useHistory } from '@/hooks/use-history';
import { useModule } from '@/hooks/use-module';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { createSsuHref, isIndexPage, mergeSsuQueryParams } from '@/lib/url';
import { Modal, ModalTrigger } from '@/modals';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type { DefaultMeta } from '@/modules/analytics/types/event-props';
import { ContactModule } from '@/modules/contact';
import { MoveToMobileModule } from '@/modules/move-to-mobile';
import { IconName } from '@/types/icon';

import type {
  ContactModule as ContactModuleType,
  MoveToMobileModule as MoveToMobileModuleType,
} from '../../types/content-schema';
import { ButtonIcon, ButtonVariant } from '../button-icon/button-icon';

import { getBackPath, getUpdatedList } from './helpers';
import css from './the-footer.module.scss';

interface TheFooterProps {
  className?: string;
}

export const TheFooter: FC<TheFooterProps> = ({ className }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { list, update } = useHistory();
  const [isHomeClicked, setIsHomeClicked] = useState(false);
  const [showHomeButton, setShowHomeButton] = useState(false);
  const [_, setHomeHref] = useCustomLocalStorageState<string>(
    LocalStorageRoute.HOME_HREF,
    '',
  );
  const isIndex = isIndexPage(router);
  const classNames = classnames(
    css.root,
    isIndex && css.isIndexPage,
    className,
  );
  const contactModule = useModule<ContactModuleType>(Module.CONTACT);
  const moveToMobileModule = useModule<MoveToMobileModuleType>(
    Module.MOVE_TO_MOBILE,
  );
  const dataLayer = useDataLayer();
  const { pages } = useSSU();
  const homeHref = createSsuHref(router);
  const hasDisclaimer = pages?.includes(Route.DISCLAIMER);

  useEffect(() => {
    setHomeHref(homeHref);
  }, [homeHref, setHomeHref]);

  useEffect(() => {
    const previousUrl = getBackPath(list, router);
    const previousUrlWithLocale = `/${[
      router.locale !== DEFAULT_LOCALE ? router.locale : undefined,
      previousUrl,
    ]
      .filter(Boolean)
      .join('/')}`.replace('//', '/');

    if (previousUrl === homeHref || previousUrlWithLocale === homeHref) {
      setShowHomeButton(false);
    } else {
      setShowHomeButton(true);
    }
  }, [list, router, homeHref]);

  const handleBackButtonClick = () => {
    const previousUrl = getBackPath(list, router);
    update(getUpdatedList(list, previousUrl));
    void router.push(previousUrl);
  };

  const handleHomeButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    setIsHomeClicked(true);

    update([]);

    dataLayer.publish<DefaultMeta>(UIEventName.BUTTON_CLICK, {
      value: 'HOME',
    });

    window.location.href = homeHref;
  };

  const handleDisclaimerClick = () => {
    void router.push({
      pathname: Path.CATCH_ALL,
      query: mergeSsuQueryParams(router.query, {
        [QueryParam.SSU_PATH]: [Route.DISCLAIMER],
      }),
    });
  };

  return (
    <footer className={classNames}>
      <div className={css.column}>
        {!isIndex && (
          <div className={css.buttons}>
            <ButtonIcon
              title={t('general.back', { defaultValue: 'Back' })}
              iconName={IconName.ARROW_LEFT}
              onClick={handleBackButtonClick}
              iconClassName={css.backButtonIcon}
              variant={ButtonVariant.SECONDARY}
              {...getTestIdProp('footer-back-button')}
            />
            {(isHomeClicked || showHomeButton) && (
              <ButtonIcon
                isLoading={isHomeClicked}
                iconName={IconName.HOME}
                title={t('general.home', { defaultValue: 'Home' })}
                onClick={handleHomeButtonClick}
                variant={ButtonVariant.SECONDARY}
                {...getTestIdProp('footer-home-button')}
              />
            )}
          </div>
        )}
        {isIndex && hasDisclaimer && (
          <button
            className={css.disclaimer}
            onClick={handleDisclaimerClick}
            {...getTestIdProp('footer-disclaimer-button')}
          >
            {t('general.button.disclaimer', { defaultValue: 'Disclaimer' })}
          </button>
        )}
      </div>
      <div className={css.column}>
        <div className={css.buttons}>
          {contactModule?.enabled && <ContactModule />}
          {moveToMobileModule?.enabled && <MoveToMobileModule />}
          <ModalTrigger modalId={Modal.LANGUAGE_MENU}>
            <ButtonIcon
              iconName={IconName.LANGUAGE}
              title={t('general.selectLanguage', {
                defaultValue: 'Select language',
              })}
              {...getTestIdProp('language-switcher')}
            />
          </ModalTrigger>
        </div>
      </div>
    </footer>
  );
};
