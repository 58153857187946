import type { PropsWithChildren } from 'react';

import { Module } from '@/enums/module';
import { useModule } from '@/hooks/use-module';

import type { FlightInfoModule } from '../../../types/content-schema';
import { FlightInfoProvider } from '../provider';

export const FlightInfoModuleProvider = ({ children }: PropsWithChildren) => {
  const flightInfoModule = useModule<FlightInfoModule>(Module.FLIGHT_INFO);

  if (!flightInfoModule) {
    return null;
  }

  return <FlightInfoProvider>{children}</FlightInfoProvider>;
};
