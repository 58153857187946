import { AudioVideoStatus } from '../../../../../types/component';
import type { AudioVideo } from '../../../types/event-props';
import { GTMEventName } from '../event';
import { getCallStatus } from '../helpers/get-call-status';
import type { Mapper } from '../types';

export const mapAudioVideo: Mapper<AudioVideo> = ({ meta: { action } }) => {
  const callStatus = getCallStatus(action);

  const data: { eventLabel: string; call_status?: string } = {
    eventLabel: 'video_call',
  };

  if (callStatus) {
    data.call_status = callStatus;
  }

  switch (action) {
    case AudioVideoStatus.CAMERA_OFF:
      return {
        eventName: GTMEventName.VIDEO_USER_CAMERA_OFF,
        ...data,
      };

    case AudioVideoStatus.CAMERA_ON:
      return {
        eventName: GTMEventName.VIDEO_USER_CAMERA_ON,
        ...data,
      };

    default:
      return;
  }
};
