import type { UseMapSearchParameters } from '../../hooks/use-map-search';
import { useMapSearch } from '../../hooks/use-map-search';
import { MapSearchNoResults } from '../map-search-no-results/map-search-no-results';
import { MapSearchResults } from '../map-search-results/map-search-results';
import { SmokingAreaMessage } from '../smoking-area-message/smoking-area-message';

export const MapSearchResultsContainer = (props: UseMapSearchParameters) => {
  const { data: pois, isLoading } = useMapSearch(props);

  const hasResults = pois?.length && !isLoading;

  return (
    <>
      <SmokingAreaMessage query={props.query} />
      {hasResults ? <MapSearchResults pois={pois} /> : <MapSearchNoResults />}
    </>
  );
};
