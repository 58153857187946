import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC, MutableRefObject } from 'react';

import { Icon } from '@/components/icon/icon';
import { InputSearch } from '@/components/input-search/input-search';
import { IconName } from '@/types/icon';

import css from './map-search-input.module.scss';

export interface MapSearchInputProps {
  className?: string;
  onChangeSearchInput: (query: string) => void;
  onSearchOpen: () => void;
  onCancelSearch: () => void;
  searchIsOpen?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  defaultValue?: string;
}

export const MapSearchInput: FC<MapSearchInputProps> = ({
  onSearchOpen,
  onCancelSearch,
  onChangeSearchInput,
  searchIsOpen,
  inputRef,
  className,
  defaultValue,
}) => {
  const classNames = classnames(css.root, className);
  const { t } = useTranslation();
  return (
    <div className={classNames}>
      <InputSearch
        inputClassName={css.input}
        label={t('general.map.search.label', {
          defaultValue: 'Search for amenities or locations',
        })}
        placeholder={t('general.map.search.placeholder', {
          defaultValue: "Try 'coffee', 'gifts' or 'gate'",
        })}
        onChange={onChangeSearchInput}
        inputRef={inputRef}
        defaultValue={defaultValue}
        onFocus={onSearchOpen}
      />

      {searchIsOpen && (
        <button className={css.cancelButton} onClick={onCancelSearch}>
          <Icon className={css.cancelButtonIcon} name={IconName.CLOSE} />{' '}
          {t('general.cancel', { defaultValue: 'Cancel' })}
        </button>
      )}
    </div>
  );
};
