import type { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import css from './badge.module.scss';

interface BadgeProps {
  className?: string;
}

export const Badge: FC<PropsWithChildren<BadgeProps>> = ({
  children,
  className,
}) => {
  const classNames = classnames(css.root, className);

  return <span className={classNames}>{children}</span>;
};
