import { STATIC_ROUTES } from '@/constants/app';
import {
  INDEX_WITHOUT_PAGETITLE,
  NO_LANGUAGE_STRING,
} from '@/modules/analytics/services/schiphol/constants';
import {
  getPageTitleByLocale,
  getPageTitleForDefaultLocale,
} from '@/modules/analytics/services/schiphol/helpers/find-translation';
import type { GenericSchipholData } from '@/modules/analytics/services/schiphol/types';
import type { Data, UnitType } from '@/modules/analytics/types/data-layer';
import type { Module, SSU } from '@/types/content-schema';
import { destructPageTitle } from '@/lib/title';

const getEnabledModules = (ssu?: SSU) => {
  if (!ssu) {
    return '';
  }

  const modules = Object.entries<Module>(ssu.modules)
    .filter(([, module]) => module.enabled)
    .map(([name]) => name);

  return modules.join('_');
};

const getStrippedPageUrl = (ssu: SSU) => {
  // Remove the ssuId and iataCode from the url
  return window.location.href
    .replace(`/${ssu.id}`, '')
    .replace(`/${ssu.airport.shortCode}`, '');
};

function getUnitType(ssu: SSU) {
  const unitTypeMap: Record<SSU['type'], UnitType> = {
    UNIT: 'SSU',
    PRM: 'PRM',
    MOBILE: 'MOBILE',
  };
  return unitTypeMap[ssu.type];
}

function getPageTitle(data: Data, ssu: SSU, fallback: string) {
  const isStaticRoute = STATIC_ROUTES.some((route) =>
    window.location.pathname.includes(route),
  );

  if (isStaticRoute) {
    return fallback;
  }

  const fallbackSlug = window.location.pathname.split('/').pop() || '';
  const isIndexPage = fallbackSlug === ssu.id;
  const slug = isIndexPage ? INDEX_WITHOUT_PAGETITLE : fallbackSlug;
  return getPageTitleForDefaultLocale(data, slug);
}

function getTranslatedPageTitle(
  data: Data,
  language: string | undefined,
  fallback: string,
) {
  return getPageTitleByLocale(data, language) ?? fallback;
}

export const getGenericData = (data: Data): GenericSchipholData => {
  const { ssu, language } = data;
  const documentPageTitle = destructPageTitle(document.title).title;

  if (!language) {
    return {
      language: NO_LANGUAGE_STRING,
    };
  }

  if (!ssu) {
    return {
      language,
    };
  }

  return {
    language,
    uniqueIdentifier: ssu.id,
    unitType: getUnitType(ssu),
    name: ssu.name,
    modules: getEnabledModules(ssu),
    pageUrl: getStrippedPageUrl(ssu),
    pageTitle: getPageTitle(data, ssu, documentPageTitle),
    translatedPageTitle: getTranslatedPageTitle(
      data,
      language,
      documentPageTitle,
    ),
  };
};
