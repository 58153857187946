import type { AllHTMLAttributes, FC } from 'react';
import { createElement } from 'react';

import { useIconSet } from '../../context/icon-set-provider';
import type { IconName } from '../../types/icon';

export interface IconProps {
  name: IconName;
}

export const Icon: FC<IconProps & AllHTMLAttributes<SVGElement>> = ({
  name,
  ...props
}) => {
  const iconSet = useIconSet();

  if (!name || !iconSet) {
    return null;
  }

  return createElement(iconSet, { name, 'aria-hidden': true, ...props });
};
