import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { isArrivalFlight } from 'pxp-api/clients/flight-info-api/helpers/direction';
import type { Flight } from 'pxp-flight-api-schema/types';

import { Icon } from '@/components/icon/icon';
import { SrOnly } from '@/components/sr-only/sr-only';
import { ArrivalGate } from '@/modules/flight-info/components/arrival-gate/arrival-gate';
import { ArrivalTime } from '@/modules/flight-info/components/arrival-time/arrival-time';
import { DepartureGate } from '@/modules/flight-info/components/departure-gate/departure-gate';
import { DepartureTime } from '@/modules/flight-info/components/departure-time/departure-time';
import type { IconName } from '@/types/icon';

import css from './flight-info-compact-bar.module.scss';

interface SearchResultProps {
  flight: Flight;
  iconName?: IconName;
  className?: string;
}

interface ContentProps {
  flight: Flight;
  isArrival: boolean;
}

const Content = ({ flight, isArrival }: ContentProps) => {
  const { t } = useTranslation();
  const contentConfiguration = isArrival
    ? {
        Time: ArrivalTime,
        Gate: ArrivalGate,
        timeLabel: t('general.flightInfo.arrivalTime', {
          defaultValue: 'Arrival Time',
        }),
        destinationLabel: t('general.flightInfo.origin', {
          defaultValue: 'Origin',
        }),
        location: flight.departure.airportName,
      }
    : {
        Time: DepartureTime,
        Gate: DepartureGate,
        timeLabel: t('general.flightInfo.departureTime', {
          defaultValue: 'Departure Time',
        }),
        destinationLabel: t('general.flightInfo.destination', {
          defaultValue: 'Destination',
        }),
        location: flight.arrival.airportName,
      };
  return (
    <>
      <SrOnly as="dt">{contentConfiguration.timeLabel}</SrOnly>
      <dd>
        <contentConfiguration.Time flight={flight} />
      </dd>

      <div className={css.flightInfo}>
        <SrOnly as="dt">{contentConfiguration.destinationLabel}</SrOnly>
        <dd className={css.destination}>{contentConfiguration.location}</dd>

        <SrOnly as="dt">
          {t('general.flightInfo.flight', { defaultValue: 'Flight' })}
        </SrOnly>
        <dd>
          {flight.iataCode} {flight.airline.name}
        </dd>
      </div>

      <div className={css.gate}>
        <SrOnly as="dt">{t('general.gate', { defaultValue: 'Gate' })}</SrOnly>
        <dd>
          <contentConfiguration.Gate flight={flight} />
        </dd>
      </div>
    </>
  );
};

export const FlightInfoCompactBar: FC<SearchResultProps> = ({
  flight,
  iconName,
  className,
}) => {
  const classNames = classnames(css.root, className);

  return (
    <dl className={classNames}>
      <Content flight={flight} isArrival={isArrivalFlight(flight)} />
      {iconName && (
        <span className={css.iconWrapper}>
          <Icon name={iconName} className={css.icon} />
        </span>
      )}
    </dl>
  );
};
