import { Modal } from '../../../../../store/modalStore';
import type { ModalEvent } from '../../../types/event-props';
import { ModalAction } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapModal: Mapper<ModalEvent> = ({ meta: { type, action } }) => {
  if (type === Modal.FEEDBACK_OVERLAY) {
    return {
      eventName:
        action === ModalAction.OPEN
          ? GTMEventName.MAP_OPEN_FEEDBACK
          : GTMEventName.MAP_CLOSE_FEEDBACK,
    };
  }

  if (type === Modal.CONTACT_OPTIONS) {
    return {
      eventName:
        action === ModalAction.OPEN
          ? GTMEventName.CONTACT_OPEN_CONTACT
          : GTMEventName.CONTACT_CLOSE_CONTACT,
    };
  }

  if (type === Modal.LANGUAGE_MENU) {
    return {
      eventName:
        action === ModalAction.OPEN
          ? GTMEventName.LANGUAGE_OPEN
          : GTMEventName.LANGUAGE_CLOSE,
    };
  }

  return undefined;
};
