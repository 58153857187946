import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

export type Ref = MutableRefObject<HTMLElement | null>;

type TouchPosition = {
  x: number;
  y: number;
};
export interface UseOutsideDragOptions {
  minDistance?: number;
}

const DEFAULT_MIN_DISTANCE = 50;

export const useOutsideDrag = (
  ref: Ref | Ref[],
  onOutSideDrag: (event: MouseEvent | TouchEvent) => void,
  options: UseOutsideDragOptions = {},
) => {
  const { minDistance = DEFAULT_MIN_DISTANCE } = options;

  useEffect(() => {
    let touchStart: TouchPosition | null = null;
    let touchEnd: TouchPosition | null = null;

    const checkIfRefIsInArray = (event: TouchEvent, refs: Ref[]): boolean => {
      return refs.some((ref) => ref.current?.contains(event.target as Node));
    };

    const handler = (event: TouchEvent): void => {
      const refsArray = Array.isArray(ref) ? ref : [ref];
      if (
        refsArray.some((ref) => ref.current) &&
        !checkIfRefIsInArray(event, refsArray)
      ) {
        onOutSideDrag(event);
      }
    };

    const onTouchStart = (event: TouchEvent) => {
      touchEnd = null;
      touchStart = {
        x: event.targetTouches[0].clientX,
        y: event.targetTouches[0].clientY,
      };
    };

    const onTouchMove = (event: TouchEvent) => {
      if (!touchStart) {
        return;
      }

      touchEnd = {
        x: event.targetTouches[0].clientX,
        y: event.targetTouches[0].clientY,
      };

      const distanceX = Math.abs(touchStart.x - touchEnd.x);
      const distanceY = Math.abs(touchStart.y - touchEnd.y);

      if (distanceX > minDistance || distanceY > minDistance) {
        handler(event);
        touchStart = null;
      }
    };

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
    };
  }, [ref, onOutSideDrag, minDistance]);
};
