import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDataLayer } from '../../data-layer-provider';
import { UIEventName } from '../../events';

export const VirtualPageTracker = () => {
  const dataLayer = useDataLayer();
  const { events } = useRouter();
  const lastPathname = useRef<string>();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const pathname = new URL(window.location.href).pathname;

      if (lastPathname.current === pathname) {
        return;
      }

      lastPathname.current = pathname;
      dataLayer.publish(UIEventName.PAGE_VIEW, {
        value: url,
      });
    };

    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events, dataLayer]);

  return null;
};
