import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';

import { Icon } from '../../../../components/icon/icon';
import { IconName } from '../../../../types/icon';

import css from './flight-gate-floor-indication.module.scss';

export type FlightGateFloorIndicationProps = {
  floorIndicationNumber?: number;
  floorNumber?: string;
  className?: string;
  children: ReactNode;
};

export const FlightGateFloorIndication: FC<FlightGateFloorIndicationProps> = ({
  floorIndicationNumber = 0,
  floorNumber,
  className,
  children,
}) => {
  const isSameFloor = floorIndicationNumber === 0;
  const isFloorUp = floorIndicationNumber > 0;
  const { t } = useTranslation();

  if (isSameFloor || floorNumber === undefined) {
    return <span>{children}</span>;
  }

  return (
    <span className={className}>
      <span className={css.row}>
        {children}
        {isFloorUp && <Icon className={css.icon} name={IconName.STAIRS_UP} />}
        {!isFloorUp && (
          <Icon className={css.icon} name={IconName.STAIRS_DOWN} />
        )}
      </span>
      <span className={css.text}>
        {t('general.flightInfo.gateFloorIndication', { floorNumber })}
      </span>
    </span>
  );
};
