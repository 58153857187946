import { getPageTitleForDefaultLocale } from '@/modules/analytics/services/schiphol/helpers/find-translation';
import type { GenericData } from '@/modules/analytics/types/data-layer';
import { ContentType } from '@/modules/analytics/types/event-props';

export const getFeedbackEventLabel = (
  label: string,
  data: Partial<GenericData>,
  slug: string,
  contentType?: ContentType,
): string => {
  if (
    contentType === ContentType.TOPIC ||
    contentType === ContentType.QUESTION
  ) {
    return slug;
  } else {
    return label ? label : getPageTitleForDefaultLocale(data, slug);
  }
};
