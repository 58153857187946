import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { Icon } from '@/components/icon/icon';
import { SrOnly } from '@/components/sr-only/sr-only';
import { IconName } from '@/types/icon';

import { useGetFloorIndication } from '../../hooks/use-get-floor-indication';

import css from './floor-indication.module.scss';

export interface FloorIndicationProps {
  className?: string;
  floorId: string;
}

export const FloorIndication: FC<FloorIndicationProps> = ({
  className,
  floorId,
}) => {
  const { t } = useTranslation();
  const {
    currentFloor,
    floorIndicationNumber: floorDiff,
    floorNumber,
    isFloorUp,
    isSameFloor,
  } = useGetFloorIndication(floorId);

  if (!currentFloor || isSameFloor) {
    return null;
  }

  const srOnlyText = t('general.floorIndication.ariaLabel', {
    count: floorDiff,
    currentFloor,
    defaultValue: `You are currently on floor ${currentFloor}. You need to go to floor ${floorNumber}.`,
    direction: isFloorUp ? 'up' : 'down',
  });

  return (
    <div className={classnames(css.root, className)}>
      <Icon
        className={css.icon}
        name={isFloorUp ? IconName.STAIRS_UP : IconName.STAIRS_DOWN}
      />
      <SrOnly>{srOnlyText}</SrOnly>
      <span className={css.number} aria-hidden="true">
        {floorNumber}
      </span>
    </div>
  );
};
