import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOutsideClick } from 'pxp-utils/hooks/use-outside-click';
import { useShallow } from 'zustand/react/shallow';

import { useSSU } from '@/context/ssu-provider';

import { Icon } from '../../../../components/icon/icon';
import { Module } from '../../../../enums/module';
import { useModule } from '../../../../hooks/use-module';
import { getTestIdProp } from '../../../../lib/get-test-id-prop';
import type { MapModule } from '../../../../types/content-schema';
import { IconName } from '../../../../types/icon';
import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import { useMap } from '../../hooks/use-map';
import { useCurrentFloorStore } from '../../store/currentFloorStore';

import { FloorSwitcherModal } from './floor-switcher-modal';
import css from './floor-switcher.module.scss';

export interface FloorSwitcherProps {
  className?: string;
}

export const FloorSwitcher = ({ className }: FloorSwitcherProps) => {
  const classNames = classnames(css.root, className);
  const dataLayer = useDataLayer();
  const { t } = useTranslation();
  const { goToFloor, setDetailCardCollapsed } = useMap();
  const mapModule = useModule<MapModule>(Module.MAP);
  const {
    config: { orientation },
  } = useSSU();

  const floors = useMemo(
    () => mapModule?.properties?.floors || [],
    [mapModule?.properties?.floors],
  );
  const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const currentFloor = useCurrentFloorStore(
    useShallow((state) => state.currentFloor ?? orientation.floor),
  );

  const getFloorById = useCallback(
    (floorId: string) => floors.find((floor) => floor.id === floorId),
    [floors],
  );
  const activeFloor = floors.find((floor) => floor.id === currentFloor);
  const activeFloorButtonClassNames = classnames(
    css.activeFloorButton,
    modalIsOpen && css.open,
  );

  const handleClickFloor = (id: string) => {
    goToFloor(id);
    setModalIsOpen(false);

    const floor = getFloorById(id);
    dataLayer.update({ map: { floor } });
    dataLayer.publish(UIEventName.MAP_SELECT_FLOOR, {
      floor,
    });
  };

  const handleClick = () => {
    setDetailCardCollapsed(true);
    setModalIsOpen(!modalIsOpen);
  };
  useOutsideClick(wrapperRef, () => setModalIsOpen(false));
  const currentFloorLabel = activeFloor?.number
    ? t('general.map.floor.current', {
        floorNumber: activeFloor?.number,
        defaultValue: `Current floor: ${activeFloor?.number}.`,
      })
    : '';

  const openFloorSwitcherLabel = `${
    t('general.map.floorSwitcherOpen', {
      defaultValue: 'Open floor switcher modal',
    }) as string
  } ${currentFloorLabel}`;

  useEffect(
    () => () => {
      // reset the floor indicator to the orientation floor when the component is unmounted
      goToFloor(orientation.floor);
    },
    [goToFloor, orientation.floor],
  );

  return (
    <div
      className={classNames}
      ref={wrapperRef}
      {...getTestIdProp('floor-switcher')}
    >
      <div className={css.inner}>
        <button
          className={activeFloorButtonClassNames}
          onClick={handleClick}
          aria-label={openFloorSwitcherLabel}
        >
          <span className={css.activeFloorLabel}>
            <span>{activeFloor?.number} </span>
          </span>
          <div className={css.icons}>
            <Icon className={css.icon} name={IconName.CHEVRON_UP} />
            <Icon className={css.icon} name={IconName.CHEVRON_DOWN} />
          </div>
        </button>
        {modalIsOpen && (
          <FloorSwitcherModal
            floors={floors}
            activeFloor={activeFloor}
            onClickClose={setModalIsOpen}
            onClickFloor={handleClickFloor}
          />
        )}
      </div>
    </div>
  );
};
