import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import {
  getFlightStatus,
  getFlightStatusTranslationKey,
} from 'pxp-api/clients/flight-info-api/helpers/status';
import type { Flight } from 'pxp-flight-api-schema/types';

import css from './flight-status.module.scss';

interface FlightStatusProps {
  flight: Flight;
  className?: string;
}

export const FlightStatus: FC<FlightStatusProps> = ({ flight, className }) => {
  const { t } = useTranslation();
  const flightStatusTranslationKey = getFlightStatusTranslationKey(flight);
  const { isGateChanged, isCancelled } = getFlightStatus(flight);

  const classNames = classnames(
    css.root,
    {
      [css.error]: isGateChanged || isCancelled,
    },
    className,
  );

  return flightStatusTranslationKey ? (
    // @ts-expect-error: translationKey is dynamic
    <span className={classNames}>{t(flightStatusTranslationKey)}</span>
  ) : null;
};
