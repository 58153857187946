import { forwardRef } from 'react';
import classnames from 'classnames';
import { IconName } from '../../../../types/icon';
import { Icon } from '../../../../components/icon/icon';
import css from './location-marker.module.scss';

interface LocationMarkerProps {
  id?: string;
  className?: string;
}

export const LocationMarker = forwardRef<HTMLDivElement, LocationMarkerProps>(
  ({ className, id }, ref) => {
    const classNames = classnames(css.root, className);

    return (
      <div ref={ref} id={id} className={classNames}>
        <Icon name={IconName.MAP_PIN} className={css.icon} />
      </div>
    );
  },
);
