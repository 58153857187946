import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import type { Flight } from 'pxp-flight-api-schema/types';

import { GateLabel } from '../../../../components/gate-label/gate-label';
import { QueryParam } from '../../../../enums/route';
import { mergeSsuQueryParams } from '../../../../lib/url';

interface TransferDeskProps {
  flight: Flight;
}

export const TransferDesk: FC<TransferDeskProps> = ({ flight }) => {
  const { pathname, query } = useRouter();

  if (
    !flight.departure.transferDeskNumber ||
    !flight.departure.transferDeskLocationId
  ) {
    return null;
  }

  const href = {
    pathname,
    query: mergeSsuQueryParams(query, {
      ...query,
      [QueryParam.POI_ID]: flight.departure.transferDeskLocationId,
      [QueryParam.FLIGHT_ID]: query[QueryParam.FLIGHT_ID],
    }),
  };

  return (
    <Link href={href}>
      <GateLabel gateNumber={flight.departure.transferDeskNumber} />
    </Link>
  );
};
