const Api = require('./api');

class I18nextStrapiTranslationsBackend {
  constructor() {
    this.type = 'backend';
  }

  async read(locale, namespace, callback) {
    try {
      const translations = await Api.getTranslations({ locale });
      const values = this.formatTranslations(translations);

      const localValues = require(`../src/i18n/locales/${locale}/app.json`);

      // Merge local translations with remote translations
      // The remote ones will overwrite local translations
      callback(null, { ...localValues, ...values });
    } catch (e) {
      callback(e);
    }
  }

  formatTranslations(translations) {
    return translations.reduce((acc, translation) => {
      return {
        ...acc,
        [translation.attributes.key]: translation.attributes.value,
      };
    }, {});
  }
}

module.exports = new I18nextStrapiTranslationsBackend();
