import classnames from 'classnames';
import debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback } from 'react';

import type { FeedbackOption } from '@/modules/hooks/use-feedback-options';

import { Icon } from '../icon/icon';
import { SrOnly } from '../sr-only/sr-only';

import css from './feedback-with-icons.module.scss';

interface FeedbackWithIconsProps {
  className?: string;
  onSelect: (value: number) => void;
  options: FeedbackOption[];
  receivedFeedbackDebounceTimeout?: number;
  title?: string;
}

const ONCLICK_DEBOUNCE_TIMEOUT = 1_000;

export const FeedbackWithIcons: FC<FeedbackWithIconsProps> = ({
  className,
  onSelect,
  options,
  receivedFeedbackDebounceTimeout = ONCLICK_DEBOUNCE_TIMEOUT,
  title,
}) => {
  const classNames = classnames(css.root, className);

  const debouncedOnClick = useCallback(
    (value: number) =>
      debounce(onSelect, receivedFeedbackDebounceTimeout)(value),
    [onSelect, receivedFeedbackDebounceTimeout],
  );

  return (
    <div className={classNames}>
      {title && <h2 className={css.title}>{title}</h2>}
      <ul className={css.options}>
        {options.map(({ value, label, icon }) => (
          <li key={value}>
            <button
              className={css.option}
              onClick={() => debouncedOnClick(value)}
            >
              <Icon className={css.icon} name={icon} />
              <SrOnly>{label}</SrOnly>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
