import type { RefCallback } from 'react';

import type { MoveToMobileContentProps } from './03_move-to-mobile/move-to-mobile';
import type { ContactOptionTextContentProps } from './05_contact-option-text/contact-option-text';

export const Modal = {
  LANGUAGE_MENU: 'LANGUAGE_MENU',
  BARCODE_CAMERA: 'BARCODE_CAMERA',
  MOVE_TO_MOBILE: 'MOVE_TO_MOBILE',
  CONTACT_OPTIONS: 'CONTACT_OPTIONS',
  CONTACT_OPTIONS_TEXT: 'CONTACT_OPTIONS_TEXT',
} as const;
export type Modal = keyof typeof Modal;

export type ModalContentProps = {
  [Modal.LANGUAGE_MENU]: {};
  [Modal.BARCODE_CAMERA]: {};
  [Modal.MOVE_TO_MOBILE]: MoveToMobileContentProps;
  [Modal.CONTACT_OPTIONS]: {};
  [Modal.CONTACT_OPTIONS_TEXT]: ContactOptionTextContentProps;
};

export type BaseContentProps<Element extends HTMLElement = HTMLElement> = {
  /**
   * Pass this ref to a static element in your content to have it receive focus
   * as the modal opens. use this only if there's no interactive elements.
   * see https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/#:~:text=If%20the%20dialog,in%20this%20scenario..
   */
  initialFocusRef?: RefCallback<Element>;
};
export type ModalMap = {
  [Id in Modal]: ModalComponent<ModalContentProps[Id] & BaseContentProps>;
};

export interface ModalOptions {
  /** Should the modal use the background image configured in the cms?
   *
   * @default false
   */
  backgroundImage: boolean;
  /** Dismiss the modal when clicking outside of the content?
   *
   * @default true
   */
  dismissOnClickOutside: boolean;
  /** Dismiss the modal by pressing the escape key?
   *
   * @default true
   */
  dismissOnEscape: boolean;
  /** Should the model content be shown fullscreen?
   *
   * @default false
   */
  fullscreen: boolean;
  /** block interaction of elements behind the overlay?
   *
   * @default true
   */
  modal: boolean;
  /** Show the footer with the close button?
   *
   * @default true
   */
  showFooter: boolean;
}

export type ModalComponent<
  Props extends ModalContentProps[keyof ModalContentProps] = {},
> = {
  options?: Partial<ModalOptions>;
  content: (props: Props) => React.ReactNode;
  closeButton?: () => React.ReactNode;
};
