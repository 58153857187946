import { Flight } from 'pxp-flight-api-schema/types';

import { AjaxState } from '../types/ajax';
import { Poi } from '../types/poi';

export interface CurrentFloorStore {
  currentFloor?: string;
  setCurrentFloor: (floor: string) => void;
}

export interface DetailCardStore {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

export interface FlightCardStore {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

export enum WayFindingType {
  POI = 'POI',
  GATE = 'GATE',
}

export interface WayFindingMeta {
  flight: Flight;
}

export interface WayFindingState {
  isShowingRoute: boolean;
  from?: Poi;
  to?: Poi;
  type?: WayFindingType;
  meta?: WayFindingMeta;
}
export interface WayFindingStore extends WayFindingState {
  showRoute: () => void;
  hideRoute: () => void;
  startRoute: (to: Poi, type: WayFindingType, meta?: WayFindingMeta) => void;
  stopRoute: () => void;
}

export interface PoiSearchFilters {
  query?: string;
  categories?: string[];
}
export interface PoiSearchStore {
  state: AjaxState;
  filters: PoiSearchFilters;
  results: Poi[];
  error?: Error;
  setPending: (filters?: PoiSearchFilters) => void;
  setSuccess: (pois: Poi[]) => void;
  setError: (error: Error) => void;
  reset: () => void;
}
