import classnames from 'classnames';
import type { FC } from 'react';

import { Module } from '../../../../enums/module';
import { useModule } from '../../../../hooks/use-module';
import type { MapModule } from '../../../../types/content-schema';
import type { IconName } from '../../../../types/icon';
import { MapSearchFilter } from '../map-search-filter/map-search-filter';

import css from './map-search-filters.module.scss';

export interface MapSearchFiltersProps {
  className?: string;
  onChange: (activeFilter: string) => void;
  activeFilter?: string | string[];
  onSearchOpen: () => void;
}

export const MapSearchFilters: FC<MapSearchFiltersProps> = ({
  className,
  onChange,
  activeFilter,
  onSearchOpen,
}) => {
  const mapModule = useModule<MapModule>(Module.MAP);
  const quickFilters = mapModule?.properties?.quickFilters || [];

  if (!quickFilters.length) return null;

  function onChangeValue(value: string) {
    onChange(value);
    onSearchOpen();
  }

  return (
    <div
      className={classnames(css.root, className)}
      style={{ gridTemplateColumns: `repeat(${quickFilters.length}, 1fr)` }}
    >
      {quickFilters.map((filter) => (
        <div key={filter.value} className={css.filter}>
          <MapSearchFilter
            onChange={onChangeValue}
            isActive={activeFilter === filter.value}
            label={filter.translationKey}
            icon={filter.icon as IconName}
            value={filter.value}
          />
        </div>
      ))}
    </div>
  );
};
