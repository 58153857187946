import classnames from 'classnames';
import type { FC } from 'react';

import { TheFooter } from '@/components/the-footer/the-footer';
import { TheHeader } from '@/components/the-header/the-header';

import type { LayoutProps } from './get-layout';
import css from './wideLayout.module.scss';

export const WideLayout: FC<LayoutProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);
  return (
    <div className={classes}>
      <TheHeader />
      <main className={css.main}>{children}</main>
      <TheFooter />
    </div>
  );
};
