import type { FC } from 'react';
import { useMemo } from 'react';

import { useSSU } from '../../context/ssu-provider';
import { parseVars } from '../../lib/parse-vars';
import type { SSU } from '../../types/content-schema';

export const CssVarsProvider: FC = () => {
  const { theme } = useSSU();

  const cssVars = useMemo(() => parseVars<SSU['theme']>(theme), [theme]);
  const styles =
    cssVars &&
    Object.entries(cssVars).reduce(
      (acc, [key, value]) => `${acc} ${key}: ${value}; \n`,
      '',
    );

  return <style>{`:root { ${styles || ''} }`}</style>;
};
