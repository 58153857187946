import { decode } from 'bcbp';
import { format, isValid, parseJSON, startOfDay } from 'date-fns';
import {
  ERROR_MESSAGE,
  activeFlightFromBarcodeString,
} from 'pxp-utils/helpers/barcode';

import { FlightDirection } from '@/modules/flight-info/types/service';

import type { BoardingPassInfo } from '../../store/boardingPassInfoStore';

const isOldFlight = (flightDate: Date, referenceDate: Date = new Date()) =>
  startOfDay(flightDate) < startOfDay(referenceDate);

export function decodeBcbpString(bcbpString: string, date: Date) {
  const currentYear = format(date, 'yyyy');
  return decode(bcbpString, Number(currentYear));
}

export function flightInformationFromBarcodeString(
  bcbpString: string,
  airportShortCode: string,
  referenceDate = new Date(),
): { flightDirection: FlightDirection; flightId: string } {
  const activeFlight = activeFlightFromBarcodeString(
    bcbpString,
    airportShortCode,
    referenceDate,
  );

  if (!activeFlight) {
    throw new Error(ERROR_MESSAGE.notArrivingOrDeparting);
  }

  const direction =
    activeFlight?.arrivalAirport?.toUpperCase() === airportShortCode
      ? FlightDirection.ARRIVAL
      : FlightDirection.DEPARTURE;

  const {
    flightNumber,
    flightDate,
    operatingCarrierDesignator: carrier,
  } = activeFlight;

  if (!flightNumber || !flightDate || !isValid(flightDate) || !carrier) {
    throw new Error(ERROR_MESSAGE.noFlightInformation);
  }

  const dateOfFlight = parseJSON(flightDate.toISOString());
  const trimmedFlightNumber = flightNumber.trim(); // it can happen that the decoded qr code as extra whitespace in the flight number

  if (isOldFlight(dateOfFlight, referenceDate)) {
    throw new Error(ERROR_MESSAGE.isOldFlight);
  }

  const date = format(dateOfFlight, 'yyyyMMdd');

  return {
    flightDirection: direction,
    flightId: `${date}_${carrier}${trimmedFlightNumber}`,
  };
}

export function getBasicBoardingPassInfo(
  bcbpString: string,
  airportShortCode: string,
  referenceDate = new Date(),
): BoardingPassInfo {
  const emptyBoardingPassInfo: BoardingPassInfo = {
    name: '',
    flightNumber: '',
    destination: '',
    date: referenceDate,
    hasNewGate: false,
    originalDepartureTime: null,
    newDepartureTime: null,
    gate: null,
    departures: null,
    checkIn: null,
  };
  const output = decodeBcbpString(bcbpString, referenceDate);
  if (!output || !output.data || !output.data.legs) {
    return emptyBoardingPassInfo;
  }
  const { legs, passengerName } = output.data;
  const nameString = passengerName?.split('/').join(', ');

  const departingFlight = legs.find(
    (leg) => leg.departureAirport?.toUpperCase() === airportShortCode,
  );
  if (!departingFlight) {
    return emptyBoardingPassInfo;
  }
  const {
    flightNumber,
    flightDate,
    arrivalAirport,
    operatingCarrierDesignator: carrier,
  } = departingFlight;
  if (!flightDate || !flightNumber || !arrivalAirport || !carrier) {
    return emptyBoardingPassInfo;
  }

  return {
    ...emptyBoardingPassInfo,
    name: nameString ?? '',
    flightNumber: `${carrier} ${flightNumber}`,
    destination: arrivalAirport,
    date: flightDate,
  };
}
