import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect } from 'react';

import { useSSU } from '@/context/ssu-provider';
import { Path } from '@/enums/route';

import { OutOfServiceMessage } from '../out-of-service-message/out-of-service-message';

const RELOAD_INTERVAL = 900000; // 15 minutes

export const OutOfService: FC = () => {
  const { config } = useSSU();
  const { pathname } = useRouter();

  // Reload page on interval to check if out of service is disabled
  useEffect(() => {
    if (!config?.outOfService?.isEnabled) return;

    const interval = setInterval(() => {
      window.location.reload();
    }, RELOAD_INTERVAL);
    return () => clearInterval(interval);
  }, [config?.outOfService?.isEnabled]);

  return config?.outOfService &&
    config?.outOfService.isEnabled &&
    config?.outOfService?.outOfServiceNotice &&
    pathname !== Path.CONFIG.toString() ? (
    <OutOfServiceMessage {...config?.outOfService?.outOfServiceNotice} />
  ) : null;
};
