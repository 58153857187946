import type { FC } from 'react';
import classnames from 'classnames';
import { TheHeader } from '../components/the-header/the-header';
import type { LayoutProps } from './get-layout';
import css from './headerOnlyLayout.module.scss';

export const HeaderOnlyLayout: FC<LayoutProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);
  return (
    <div className={classes}>
      <TheHeader />
      <main className={css.main}>{children}</main>
    </div>
  );
};
