import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

export enum Modal {
  CONTACT_OPTION = 'CONTACT_OPTION',
  CONTACT_OPTIONS = 'CONTACT_OPTIONS',
  LANGUAGE_MENU = 'LANGUAGE_MENU',
  VIDEO_CALL = 'VIDEO_CALL',
  VIDEO_CALL_FEEDBACK = 'VIDEO_CALL_FEEDBACK',
  FEEDBACK_OVERLAY = 'FEEDBACK_OVERLAY',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  CSAT = 'CSAT',
  BARCODE_CAMERA = 'BARCODE_CAMERA',
}

export interface ModalOpenState {
  isOpen: boolean;
  index?: number;
}

export type ModalState = Record<Modal, ModalOpenState>;

interface ModalStore {
  modals: ModalState;
  openModal: (name: Modal, index?: number) => void;
  closeModal: (name: Modal) => void;
  resetModals: () => void;
}

export const selectIsOpen = (modalName: Modal) => (state: ModalStore) => {
  return state.modals?.[modalName]?.isOpen || false;
};

export const selectIsAnyOpen = (state: ModalStore) => {
  return Object.keys(Modal).some(
    (modalName) => state.modals[modalName as Modal].isOpen,
  );
};

const initialModalsState = Object.values(Modal).reduce((out, modalName) => {
  return {
    ...out,
    [modalName]: {
      isOpen: false,
    },
  };
}, {} as ModalState);

export const useModalStore = createWithEqualityFn<ModalStore>()(
  devtools(
    immer((set) => ({
      modals: initialModalsState,
      openModal: (name, index) =>
        set((state) => {
          state.modals[name] = { isOpen: true, index };
        }),
      closeModal: (name) =>
        set((state) => {
          state.modals[name] = { isOpen: false, index: undefined };
        }),
      resetModals: () =>
        set((state) => {
          state.modals = initialModalsState;
        }),
    })),
  ),
);
