import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

interface Balloon {
  isOpen: boolean;
  hasBeenClosed: boolean;
}

interface BalloonStore {
  balloons: Record<string, Balloon>;
  close: (balloonId: string) => void;
  setIsOpen: (balloonId: string, isOpen: boolean) => void;
}

export const selectBalloonIsOpen =
  (balloonId: string) =>
  ({ balloons }: BalloonStore) => {
    return balloons[balloonId]?.isOpen || false;
  };

export const selectBalloonHasBeenClosed =
  (balloonId: string) =>
  ({ balloons }: BalloonStore) => {
    return balloons[balloonId]?.hasBeenClosed || false;
  };

export const useBalloonStore = createWithEqualityFn<BalloonStore>()(
  devtools(
    immer((set) => ({
      balloons: {},
      close: (balloonId) => {
        set((state) => {
          state.balloons[balloonId] = { isOpen: false, hasBeenClosed: true };
        });
      },
      setIsOpen: (balloonId, isOpen) => {
        set((state) => {
          state.balloons[balloonId] = { isOpen, hasBeenClosed: false };
        });
      },
    })),
  ),
);
