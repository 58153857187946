import type { Data } from '@/modules/analytics/types/data-layer';
import type { SSU } from '@/types/content-schema';
import { getPageTitleComponentTranslationKey } from '@/lib/title';

const getDefaultLocale = ({ locales }: SSU) =>
  locales?.find((locale) => locale.default);

const DEFAULT_NAMESPACE = 'app';

export const getTranslationByKey = (
  translations: Data['translations'],
  localeCode: string,
  translationKey: string,
  namespace = DEFAULT_NAMESPACE,
) => {
  return (
    translations?.[localeCode]?.[namespace] as { [key: string]: string }
  )?.[translationKey];
};

export const getPageTitleByLocale = (
  { ssu, translations, page }: Data,
  localeCode?: string,
) => {
  if (!ssu || !page || !translations || !localeCode) {
    return undefined;
  }

  const translationKey = getPageTitleComponentTranslationKey({ page });

  if (!translationKey) {
    return undefined;
  }

  return getTranslationByKey(translations, localeCode, translationKey);
};

export const getPageTitleForDefaultLocale = (data: Data, fallback = '') => {
  const { ssu } = data;

  if (!ssu) {
    return fallback;
  }

  const defaultLocale = getDefaultLocale(ssu);
  return getPageTitleByLocale(data, defaultLocale?.code) || fallback;
};
