import type { ComponentType } from 'react';
import type { Icons } from './content-schema';

export type IconSetName = Icons['lib'];

export enum IconName {
  AGENT = 'agent',
  AIRLINE_LOUNGE = 'airline-lounge',
  ARRIVAL = 'arrival',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  ATTENTION = 'attention',
  BABY_CHILD = 'baby-child',
  BAGGAGE = 'baggage',
  BORDER_CONTROL = 'border-control',
  BUS = 'bus',
  BUSES = 'buses',
  CALL_END = 'call-end',
  CAR_RENTAL = 'car-rental',
  CASH_CHANGE = 'cash-change',
  CHECKMARK = 'checkmark',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CLOCK = 'clock',
  CLOSE = 'close',
  CUSTOMS = 'customs',
  DEPARTURE = 'departure',
  DRINK = 'drink',
  ERROR = 'error',
  EXIT = 'exit',
  FACEBOOK = 'facebook',
  FACE_MASK = 'face-mask',
  FAMILY_KIDS = 'family-kids',
  FAQ = 'FAQ',
  FEEDBACK = 'feedback',
  FEEDBACK_A = 'feedback-a',
  FEEDBACK_B = 'feedback-b',
  FEEDBACK_C = 'feedback-c',
  FEEDBACK_D = 'feedback-d',
  FEEDBACK_E = 'feedback-e',
  FIRST_NEEDS = 'first-needs',
  FLIGHT_INFO = 'flight-info',
  FLOOR_SWITCH = 'floor-switch',
  FOOD = 'food',
  FOOTBALL = 'football',
  GLOBE = 'globe',
  GPS = 'gps',
  GYM = 'gym',
  HEADSET = 'headset',
  HOME = 'home',
  HOTEL = 'hotel',
  INFO = 'info',
  LANGUAGE = 'language',
  LOCKERS = 'lockers',
  LOST_FOUND = 'lost-found',
  LUGGAGE_CLAIM = 'luggage-claim',
  MAP_PIN = 'map-pin',
  MEDICAL = 'medical',
  MIN = 'min',
  MOVE_TO_MOBILE = 'move-to-mobile',
  MOON_LANTERN = 'moon-lantern',
  MULTI_DAY_TICKETS = 'multi-day-tickets',
  M_GATES = 'm-gates',
  NOTIFICATIONS = 'notifications',
  NO_SMOKING = 'no-smoking',
  PAUSE = 'pause',
  PETS = 'pets',
  PHONE = 'phone',
  PLANE = 'plane',
  PLANE_SPOTTING = 'plane-spotting',
  PLAY = 'play',
  PLUS = 'plus',
  POD = 'pod',
  PRAYER_ROOMS = 'prayer-rooms',
  PRAYER_ROOMS_FEMALE = 'prayer-rooms-female',
  PRAYER_ROOMS_MALE = 'prayer-rooms-male',
  PUBLIC_TRANSPORT = 'public-transport',
  PUBLIC_TRANSPORT_INFO = 'public-transport-info',
  QUESTIONS = 'questions',
  REFRESH = 'refresh',
  RELAXATION = 'relaxation',
  RESTAURANT = 'restaurant',
  SCANNER = 'scanner',
  SCANNER_NEW = 'scanner-new',
  SEARCH = 'search',
  SERVICES = 'services',
  SHOP = 'shop',
  SHUTTLES = 'shuttles',
  SMOKING_AREAS = 'smoking-areas',
  SPA = 'spa',
  SPECIAL_ASSISTANCE = 'special-assistance',
  SPINNER = 'spinner',
  STAIRS_DOWN = 'stairs-down',
  STAIRS_UP = 'stairs-up',
  STAR = 'star',
  TAXIS = 'taxis',
  TAX_REFUND = 'tax-refund',
  TELEPHONE_CALL = 'telephone-call',
  THUMBS_DOWN = 'thumbs-down',
  THUMBS_UP = 'thumbs-up',
  TOILET = 'toilet',
  TOUCH = 'touch',
  TRAINS = 'trains',
  TRANSFERS = 'transfers',
  TRANSFER_DESKS = 'transfer-desks',
  TRANSPORT_TICKET = 'transport-ticket',
  VALID = 'valid',
  VIDEO_CALL = 'video-call',
  VOLUME = 'volume',
  WALKING = 'walking',
  WE_CHAT = 'we-chat',
  WHATSAPP = 'whatsapp',
  WIFI = 'wifi',
  PRM_PHONE = 'prm-phone',
  PRM_THUMBS_UP = 'prm-thumbs-up',
  PRM_INFO = 'prm-info',
  PRM_FLY_RIGHT = 'prm-fly-right',
}

export type IconLibrary = Record<IconName, ComponentType>;
