import type { CSSProperties } from 'react';
import kebabCase from 'lodash/kebabCase';
import { pipe } from './pipe';

type Input<T = unknown> = T | Record<string, CSSProperties> | CSSProperties;

const handleUrl = (value: string | number) => {
  if (String(value).startsWith('http')) {
    return `url(${value})`;
  }
  return value;
};

const handleValue = pipe(handleUrl);

interface RenameKeysOptions {
  prepend?: string[];
  exclude?: string[];
}

export const renameKeys = <T>(
  input: Input<T>,
  normalizeValue = handleValue,
  { prepend = [], exclude = [] }: RenameKeysOptions = {},
): Record<string, string | number> | undefined =>
  typeof input === 'object'
    ? Object.entries(input as object).reduce((acc, [key, value]) => {
        const keys = [...prepend, kebabCase(key)];

        if (
          (typeof value === 'string' && value.length === 0) ||
          exclude?.includes(keys.join('-'))
        ) {
          return acc;
        }

        if (typeof value === 'string' || typeof value === 'number') {
          return {
            ...acc,
            [`--${keys.join('-')}`]: normalizeValue(value),
          };
        }

        return {
          ...acc,
          ...renameKeys(value as Input, normalizeValue, {
            prepend: keys,
            exclude,
          }),
        };
      }, {})
    : undefined;

export const parseVars = <T>(vars: Input<T>) =>
  renameKeys<T>(vars, undefined, { exclude: ['font-family'] });
