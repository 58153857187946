import type { FC } from 'react';
import classnames from 'classnames';
import { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';
import css from './loader.module.scss';

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => {
  const classNames = classnames(css.root, className);
  return <Icon name={IconName.SPINNER} className={classNames} />;
};
