import type { Duration } from 'date-fns';
import { formatDuration, formatRelative } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { getDateLocale } from '@/lib/date';

export function useDateFormatting() {
  const { i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);
  const timeZone = process.env.NEXT_PUBLIC_AIRPORT_TIME_ZONE;

  const getFormattedDate = ({
    date = new Date(),
    dateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    localeCode = locale.code,
  }: {
    date?: Date;
    dateTimeFormatOptions?: Intl.DateTimeFormatOptions;
    localeCode?: string;
  }) => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      ...dateTimeFormatOptions,
    };
    return new Intl.DateTimeFormat(localeCode, options).format(date);
  };

  const getRelativeDate = ({ date = new Date() }) =>
    formatRelative(date, new Date(), { locale });

  const getFormattedDuration = (duration: Duration) =>
    formatDuration(duration, { locale });

  return {
    getFormattedDate,
    getFormattedDuration,
    getRelativeDate,
  };
}
