import { useEffect, useMemo, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { QueryParam } from '../../../../enums/route';
import { useQueryParam } from '../../../../hooks/use-query-param';
import { useMap } from '../../hooks/use-map';
import { useCurrentFloorStore } from '../../store/currentFloorStore';
import { usePoiSearchStore } from '../../store/poiSearchStore';
import { LocationMarker } from '../location-marker/location-marker';

import css from './category-markers.module.scss';

export const CategoryMarkers = () => {
  const { addMarkersGroup } = useMap();

  const filters = usePoiSearchStore(useShallow((state) => state.filters));
  const results = usePoiSearchStore(useShallow((state) => state.results));
  const currentFloorId = useCurrentFloorStore(
    useShallow((state) => state.currentFloor),
  );
  const isPoiDetail = !!useQueryParam(QueryParam.POI_ID);
  const ref = useRef<HTMLDivElement | null>(null);

  const categoryPoisForCurrentFloor = useMemo(() => {
    if (!filters.categories?.length || !currentFloorId) {
      return [];
    }

    return results.filter((result) => result.floor === currentFloorId);
  }, [currentFloorId, filters, results]);

  useEffect(() => {
    if (!categoryPoisForCurrentFloor?.length || isPoiDetail || !ref.current) {
      return;
    }

    return addMarkersGroup(categoryPoisForCurrentFloor, ref.current);
  }, [addMarkersGroup, categoryPoisForCurrentFloor, isPoiDetail]);

  return <LocationMarker ref={ref} className={css.root} />;
};
