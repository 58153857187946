import { useEffect } from 'react';

import { useSSU } from '@/context/ssu-provider';
import { makeConfig } from '@/hooks/use-boarding-pass-scan/config';
import { useBoardingPassScan } from '@/hooks/use-boarding-pass-scan/use-boarding-pass-scan';
import { useFakeBarcodeScan } from '@/hooks/use-fake-boarding-pass-scan/use-fake-barcode-scan';
import {
  BarcodeScannerState,
  useHIDBarcodeScanner,
} from '@/hooks/use-hid-barcode-scanner';
import { useSiteKioskBarcodeScanner } from '@/hooks/use-site-kiosk-barcode-scanner';

import { LocalStorageConfigName } from '../../enums/localStorageConfig';
import { getLocalStorageConfig } from '../../lib/get-local-storage-config';

export const BoardingPassScanner = () => {
  const { config: ssuConfig, type } = useSSU();
  const scannerConfig = makeConfig(ssuConfig);
  const isMobile = type === 'MOBILE';
  const isHIDScanner =
    scannerConfig.type === 'HID' || scannerConfig.type === undefined;
  const isUSBScanner = scannerConfig.type === 'USB';
  const enableHIDBarcodeScanner =
    scannerConfig.isEnabled && !isMobile && isHIDScanner;
  const enableUSBBarcodeScanner =
    scannerConfig.isEnabled && !isMobile && isUSBScanner;

  const { handleBarcodeScan } = useBoardingPassScan();

  // Only allow test barcode scans on non-mobile SSUs
  // This allows us to test the barcodes using a keyboard shortcuts
  useFakeBarcodeScan({
    isEnabled: !isMobile,
    onScan: handleBarcodeScan,
  });

  // Link the strapi barcode scanner config to the HID API
  const { connect, state } = useHIDBarcodeScanner({
    scanner: {
      productId: scannerConfig.productId,
      vendorId: scannerConfig.vendorId,
    },
    onScan: handleBarcodeScan,
    isEnabled: enableHIDBarcodeScanner,
  });

  // Connect to the scanner when the component mounts
  // if its not a mobile ssu and the scanner is enabled or disabled in local storage
  useEffect(() => {
    const isDisabled = getLocalStorageConfig(
      LocalStorageConfigName.BARCODE_HID_API,
    );
    if (
      state === BarcodeScannerState.UNCONNECTED &&
      !isDisabled &&
      enableHIDBarcodeScanner
    ) {
      void connect();
    }
  }, [scannerConfig.isEnabled, connect, state, enableHIDBarcodeScanner]);

  useSiteKioskBarcodeScanner({
    deviceId: scannerConfig.usbDeviceId,
    onScan: handleBarcodeScan,
    isEnabled: enableUSBBarcodeScanner,
  });

  return null;
};
