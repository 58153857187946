import type { ModalEvent } from '@/modules/analytics/types/event-props';
import { ModalAction } from '@/modules/analytics/types/event-props';
import type { Modal as OldModal } from '@/store/modalStore';

import type { Modal, ModalMap } from './types';

const ModalEventActionMap: Record<'open' | 'close', ModalAction> = {
  open: ModalAction.OPEN,
  close: ModalAction.CLOSE,
};

// The datalayer event still expects the old modal enum values, so cast the new
// values to the old enum, even though their string representations are the same.
// TODO: Remove cast to OldModal after all modals have been converted.
export const modalEventProps = (
  modalId: Modal,
  state: 'open' | 'close',
): ModalEvent => ({
  type: modalId as OldModal,
  action: ModalEventActionMap[state],
});

export const getModalMap = async (): Promise<ModalMap> => {
  return {
    LANGUAGE_MENU: (await import('./01_language-menu/language-menu')).default,
    BARCODE_CAMERA: (await import('./02_barcode_camera/barcode-camera'))
      .default,
    MOVE_TO_MOBILE: (await import('./03_move-to-mobile/move-to-mobile'))
      .default,
    CONTACT_OPTIONS: (await import('./04_contact-options/contact-options'))
      .default,
    CONTACT_OPTIONS_TEXT: (
      await import('./05_contact-option-text/contact-option-text')
    ).default,
  };
};
