import type { StrapiBaseRequestParams } from 'strapi-sdk-js';

export const stripNullValues = <T>(input: T): Partial<T> => {
  if (typeof input !== 'object' || Array.isArray(input)) {
    return input;
  }

  return Object.entries(input as object).reduce((acc, [key, value]) => {
    if (typeof value === 'undefined' || value === null) {
      return acc;
    }
    return {
      ...acc,
      [key]: stripNullValues(value),
    };
  }, {});
};

type FieldsToPopulate = Record<string, string[]>;

export const fieldsToPopulate = (
  populateSubFieldsMap: FieldsToPopulate,
): StrapiBaseRequestParams['populate'] => {
  const getPopulateObject = (value: string[]) => ({
    populate: value?.length ? value : '*',
  });

  return Object.entries(populateSubFieldsMap).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: getPopulateObject(value),
    };
  }, {});
};
