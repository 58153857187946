const StrapiTranslationsBackend = require('./lib/i18next-strapi-translations-backend');

const DEFAULT_NAMESPACE = 'app';
const DEFAULT_LOCALE = 'en';
const LOCALES = [
  'ar',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'pl',
  'pt',
  'ru',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh',
];

module.exports = {
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: LOCALES,
    defaultNS: DEFAULT_NAMESPACE,
    ns: DEFAULT_NAMESPACE,
  },
  localePath: './src/i18n/locales',
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  preload: false,
  reloadOnPrerender: true,
  use: [StrapiTranslationsBackend],
  serializeConfig: false,
  debug: false,
};
