import type { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import type { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';
import css from './icon-round.module.scss';

export interface IconRoundProps extends HTMLAttributes<HTMLSpanElement> {
  name: IconName;
}

export const IconRound: FC<IconRoundProps> = ({ className, name, style }) => {
  const classNames = classnames(css.root, className);
  return (
    <span className={classNames} style={style}>
      <Icon className={css.icon} name={kebabCase(name) as IconName} />
    </span>
  );
};
