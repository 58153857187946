import useSWR, { type SWRResponse } from 'swr';

import type { Poi } from '../types/poi';

import type { MapContextInterface } from './use-map';
import { useMap } from './use-map';
import { useMapLocale } from './use-map-locale';

/**
 * Custom hook to fetch a Point of Interest (POI) by its ID.
 * Falls back to returning `undefined` if the map module can not be found.
 *
 * @param poiId - The ID of the POI to fetch.
 * @returns An SWRResponse object containing the fetched POI or undefined.
 */
export const useGetPoiById = (poiId: string): SWRResponse<Poi | undefined> => {
  let map: MapContextInterface | undefined = undefined;
  let locale = '';
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- This is needed because the useMap hook throws an error conditionaly. THis is the best way to work around that.
    map = useMap();
    // eslint-disable-next-line react-hooks/rules-of-hooks -- This is needed because the useMap hook throws an error conditionaly. THis is the best way to work around that.
    locale = useMapLocale();
  } catch (e) {
    console.error('Map provider is not initialized', e);
  } finally {
    return useSWR<Poi | undefined>(
      `${poiId}${locale}`,
      () => map?.getPoiById?.(poiId) ?? undefined,
    );
  }
};
