import classnames from 'classnames';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { MouseEvent } from 'react';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import { Card } from '@/components/card/card';
import { Icon } from '@/components/icon/icon';
import { Pills } from '@/components/pills/pills';
import { RenderComponentsRichText } from '@/components/render-components-rich-text/render-components-rich-text';
import { ResizablePanel } from '@/components/resizable-panel/resizable-panel';
import { ScrollArea } from '@/components/scroll-area/scroll-area';
import { QueryParam } from '@/enums/route';
import { usePassportNotification } from '@/hooks/use-passport-notification';
import { useQueryParam } from '@/hooks/use-query-param';
import { cleanQueryParams } from '@/lib/url';
import { IconName } from '@/types/icon';

import { PassportControl } from '../../../flight-info/components/passport-control/passport-control';
import { WayFindingType } from '../../store/types';
import type { Poi } from '../../types/poi';
import { ButtonRouting } from '../button-routing/button-routing';
import { FloorIndication } from '../floor-indication/floor-indication';
import { ImageSlider } from '../image-slider/image-slider';
import { OpeningTimes } from '../opening-times/opening-times';

import css from './map-poi-detail-card.module.scss';

const imageFitCategories = ['airline_lounges'];

export interface MapPoiDetailCardProps {
  className?: string;
  poi: Poi;
  onClose?: (event: MouseEvent) => void;
  showBackButton?: boolean;
  isCollapsed?: boolean;
  setDetailCardCollapsed: (isCollapsed: boolean) => void;
}

export const MapPoiDetailCard = forwardRef<
  HTMLDivElement,
  MapPoiDetailCardProps
>(
  (
    {
      className,
      poi,
      onClose,
      showBackButton,
      setDetailCardCollapsed,
      isCollapsed: isCollapsedState,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);
    const tagListRef = useRef<HTMLLIElement | null>(null);
    const hasFlight = useQueryParam(QueryParam.FLIGHT_ID);
    const {
      name,
      description,
      category,
      categories,
      bubble,
      openingTimes,
      floor,
      icon,
      tags,
      images,
      title,
    } = poi;
    const showPassportNotification = usePassportNotification(bubble?.id);
    const collapsedOnly = !description || description === '';
    const showSlider = images && images?.length > 0;
    const dietTags = tags?.filter((tag) => tag.type === 'diet');
    const nonDietTags = tags?.filter((tag) =>
      ['kitchen', 'product', 'service'].includes(tag?.type ?? ''),
    );
    const { push, pathname, query } = useRouter();
    const isCollapsed = collapsedOnly || isCollapsedState;
    const hasOpeningTimes =
      openingTimes &&
      Object.values(openingTimes).some((value) => value !== false);
    const classNames = classnames(css.root, className);
    const contentClassNames = classnames(
      css.content,
      hasFlight && showSlider && css.compact,
    );
    const handlers = useSwipeable({
      onSwipedUp: () => {
        setDetailCardCollapsed(false);
      },
      onSwipedDown: () => {
        setDetailCardCollapsed(true);
      },
    });

    const onCardClick = (event: MouseEvent) => {
      if (
        !isCollapsed ||
        closeButtonRef.current?.contains(event.target as Node) ||
        tagListRef.current?.contains(event.target as Node)
      ) {
        return;
      }

      setDetailCardCollapsed(false);
    };

    const handleTagClick = useCallback((label?: string) => {
      const newQuery = cleanQueryParams({
        ...query,
        [QueryParam.POI_ID]: undefined,
        [QueryParam.SEARCH_QUERY]: label,
      });

      push({ pathname, query: newQuery });
    }, []);

    const thumbnail = images?.[0] ?? undefined;

    const cardActions = useMemo(() => {
      const labelKey = showBackButton
        ? 'general.map.poiDetail.backToResults'
        : 'general.close';
      const iconName = showBackButton ? IconName.ARROW_LEFT : IconName.CLOSE;
      const collapsedIconName = isCollapsed
        ? IconName.CHEVRON_UP
        : IconName.CHEVRON_DOWN;

      return (
        <div className={css.cardActions}>
          <button
            type="button"
            ref={closeButtonRef}
            onClick={onClose}
            className={css.backButton}
          >
            <Icon name={iconName} className={css.icon} />
            <span>{t(labelKey)}</span>
          </button>
          {!collapsedOnly && (
            <button
              type="button"
              title={collapsedIconName}
              onClick={() => setDetailCardCollapsed(true)}
              className={css.collapseButton}
            >
              <Icon name={collapsedIconName} className={css.collapseIcon} />
            </button>
          )}
        </div>
      );
    }, [showBackButton, onClose, t, isCollapsed, collapsedOnly]);

    const tagList = useMemo(
      () =>
        nonDietTags &&
        nonDietTags?.length > 0 &&
        icon && (
          <li
            ref={tagListRef}
            className={classnames(css.informationItem, css.tagList)}
          >
            <Icon className={css.informationItemIcon} name={IconName[icon]} />
            <Pills
              items={nonDietTags.map((tag) => tag.name)}
              handleClick={handleTagClick}
            />
          </li>
        ),
      [icon, tags],
    );

    const categorySpan = useMemo(
      () => category && <span className={css.category}>{category}</span>,
      [category],
    );
    const thumbnailFit = useMemo(
      () => categories.some((cat) => imageFitCategories.includes(cat)),
      [categories],
    );

    const thumbnailAlt: string = t('general.map.poiImageAlt', {
      imageTitle: thumbnail?.title || 'poi',
      defaultValue: 'Image of poi',
    });

    return (
      <Card ref={ref} className={classNames} onClick={onCardClick}>
        <ResizablePanel>
          <div className={css.header} {...handlers}>
            {isCollapsed && thumbnail && (
              <div className={css.thumbnail}>
                <Image
                  src={thumbnail?.src}
                  alt={thumbnailAlt}
                  layout="fill"
                  objectFit={thumbnailFit ? 'contain' : 'cover'}
                />
              </div>
            )}
            <div className={css.headerContent}>
              <div className={css.titleWrapper}>
                <h4 className={css.name}>{name}</h4>
                {cardActions}
              </div>
              {categorySpan}
              {isCollapsed && hasOpeningTimes && (
                <OpeningTimes openingTimes={openingTimes} />
              )}
            </div>
          </div>
          {!isCollapsed && (
            <div>
              {showSlider && (
                <div className={css.slider}>
                  <ImageSlider images={images} />
                </div>
              )}
              {description && (
                <div className={css.body}>
                  <ScrollArea orientation="vertical" type="always">
                    <div className={contentClassNames}>
                      {title && <h5 className={css.subTitle}>{title}</h5>}
                      <RenderComponentsRichText htmlString={description} />
                    </div>
                  </ScrollArea>
                </div>
              )}
              <ul className={css.tagList}>
                {nonDietTags && nonDietTags?.length > 0 && (
                  <li>
                    <h6 className={css.tagTitle}>
                      {t('general.map.poiDetail.tags', {
                        defaultValue: 'Tags',
                      })}
                    </h6>
                    <Pills
                      items={nonDietTags.map((tag) => tag.name)}
                      handleClick={handleTagClick}
                    />
                  </li>
                )}
                {dietTags && dietTags?.length > 0 && (
                  <li>
                    <h6 className={css.tagTitle}>
                      {t('general.map.poiDetail.dietTags', {
                        defaultValue: 'Allergies and dietary requirements',
                      })}
                    </h6>
                    <Pills
                      items={dietTags.map((tag) => tag.name)}
                      handleClick={handleTagClick}
                    />
                  </li>
                )}
              </ul>
            </div>
          )}
          <footer className={css.footer}>
            <ul className={css.informationList}>
              {!isCollapsed ? (
                <>
                  {hasOpeningTimes && (
                    <li className={css.informationItem}>
                      <Icon
                        className={css.informationItemIcon}
                        name={IconName.CLOCK}
                      />
                      <OpeningTimes openingTimes={openingTimes} />
                    </li>
                  )}
                </>
              ) : (
                tagList
              )}
              {bubble && (
                <li className={css.informationItem}>
                  <Icon
                    className={css.informationItemIcon}
                    name={IconName.MAP_PIN}
                  />
                  <span>{bubble.name}</span>
                </li>
              )}
            </ul>
            <div className={css.route}>
              {floor && (
                <FloorIndication
                  className={css.floorIndicator}
                  floorId={floor}
                />
              )}
              <ButtonRouting poi={poi} wayFindingType={WayFindingType.POI} />
            </div>
          </footer>
          {showPassportNotification && <PassportControl />}
        </ResizablePanel>
      </Card>
    );
  },
);
