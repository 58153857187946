import { useEffect } from 'react';

import { useCustomLocalStorageState } from '@/hooks/use-custom-local-storage-state';

import { usePostHealthCheck } from '../../hooks/use-post-health-check';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';

const DEFAULT_DATE = '1970-01-01T00:00:00.000Z';

export const HealthCheck = () => {
  const dataLayer = useDataLayer();
  const [lastInteraction, setLastInteraction] =
    useCustomLocalStorageState<string>('ssu:lastInteraction', DEFAULT_DATE);
  const [lastBoardingPassScan, setLastBoardingPassScan] =
    useCustomLocalStorageState<string>(
      'ssu:lastBoardingPassScan',
      DEFAULT_DATE,
    );
  const [lastFlightSearch, setLastFlightSearch] =
    useCustomLocalStorageState<string>('ssu:lastFlightSearch', DEFAULT_DATE);
  usePostHealthCheck({
    lastBoardingPassScan,
    lastInteraction,
    lastFlightSearch,
  });

  useEffect(() => {
    const unsubscribe = dataLayer.subscribe(({ event }) => {
      const now = new Date().toISOString();
      if (event === UIEventName.BOARDING_PASS_SCAN) {
        setLastBoardingPassScan(now);
      }
      if (event === UIEventName.FLIGHT_INFO_SEARCH) {
        setLastFlightSearch(now);
      }
      setLastInteraction(now);
    });

    return () => unsubscribe();
  }, [
    dataLayer,
    setLastInteraction,
    setLastBoardingPassScan,
    setLastFlightSearch,
  ]);

  return null;
};
