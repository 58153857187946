import { useEffect, useMemo, useState } from 'react';

export enum NetworkState {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export const useNetworkState = () => {
  const initialState =
    (typeof navigator !== 'undefined' && navigator.onLine) ||
    typeof window === 'undefined'
      ? NetworkState.ONLINE
      : NetworkState.OFFLINE;
  const [networkState, setNetworkState] = useState(initialState);

  useEffect(() => {
    const handleNetworkChange = (event: Event) => {
      setNetworkState(
        event.type === 'offline' ? NetworkState.OFFLINE : NetworkState.ONLINE,
      );
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  });

  return useMemo(
    () => ({
      state: networkState,
    }),
    [networkState],
  );
};
