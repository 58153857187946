import type { DataLayer } from './data-layer';
import { initAnalyticsLogger } from './services/logger';
import { initSchipholAnalytics } from './services/schiphol';
import type { InitAnalyticsService } from './types/service';

const services: InitAnalyticsService[] = [
  ...(process.env.NODE_ENV === 'development' ? [initAnalyticsLogger] : []),
  initSchipholAnalytics,
];

export const initServices = (dataLayer: DataLayer, initializers = services) => {
  return initializers.map((service) => service(dataLayer));
};
