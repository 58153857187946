import type { InitAnalyticsService } from '../../types/service';

import { schipholSubscriptionHandler } from './subscription-handler';

const TRACK_PERFORMANCE = false;

export const initSchipholAnalytics: InitAnalyticsService = async (
  dataLayer,
) => {
  try {
    const Analytics = await import('@schiphol/analytics').then(
      (r) => r.default,
    );
    const analytics = new Analytics();

    if (!process.env.NEXT_PUBLIC_KIOSK_GTM_ID) {
      throw new Error('No Schiphol GTM ID provided');
    }

    analytics.init(process.env.NEXT_PUBLIC_KIOSK_GTM_ID, TRACK_PERFORMANCE);

    dataLayer.subscribe(schipholSubscriptionHandler(Analytics.track));
  } catch (e) {
    console.warn("Couldn't load Schiphol Analytics service:", e);
  }
};
