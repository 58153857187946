export function setAbortableTimeout(
  callback: (...args: unknown[]) => unknown,
  delayInMilliseconds: number,
  signal: AbortSignal,
) {
  // listen to incoming aborts
  signal?.addEventListener('abort', handleAbort);

  function handleCallback() {
    signal?.removeEventListener('abort', handleAbort);
    callback();
  }

  const timeout = setTimeout(handleCallback, delayInMilliseconds);

  function handleAbort() {
    clearTimeout(timeout);
  }
}
