import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC, HTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useSSU } from '@/context/ssu-provider';
import { isPublic } from '@/lib/environment';

import { QueryParam } from '../../../../enums/route';
import { useQueryParam } from '../../../../hooks/use-query-param';
import { ComponentId } from '../../enums/components';
import { useMap } from '../../hooks/use-map';
import { useMapLocale } from '../../hooks/use-map-locale';
import { useCurrentFloorStore } from '../../store/currentFloorStore';
import {
  selectWayFinding,
  useWayFindingStore,
} from '../../store/wayFindingStore';
import { CategoryMarkers } from '../category-markers/category-markers';
import { LocationMarker } from '../location-marker/location-marker';
import { MapSpinner } from '../map-spinner/map-spinner';
import { YouAreHereMarker } from '../you-are-here-marker/you-are-here-marker';

import css from './render-map.module.scss';

const STATIC_MAP_ID = 'the-map';

export interface RenderMapProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onInit?: () => void;
  onUnload?: () => void;
}

export const RenderMap: FC<RenderMapProps> = ({
  onInit,
  onUnload,
  className,
  ...props
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const locale = useMapLocale();
  const {
    i18n: { language },
  } = useTranslation();

  const [currentFloor, setCurrentFloor] = useCurrentFloorStore(
    useShallow((state) => [state.currentFloor, state.setCurrentFloor]),
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const wayFinding = useWayFindingStore(selectWayFinding);
  const { init, terminate, resetSearch } = useMap();
  const { config } = useSSU();
  const poiId = useQueryParam(QueryParam.POI_ID);
  const classNames = classnames(
    css.root,
    className,
    isInitialized && css.active,
  );
  const locationMarkerClassNames = classnames(
    css.currentPin,
    isInitialized &&
      (!wayFinding.isShowingRoute ||
        (wayFinding.isShowingRoute && poiId !== wayFinding.to?.id)) &&
      css.active,
  );

  useEffect(() => {
    if (ref.current) {
      const initialize = async () => {
        setIsInitialized(false);

        try {
          await init({
            element: ref.current!,
            currentLocation: {
              latitude: config?.orientation?.coordinates?.latitude ?? 0,
              longitude: config?.orientation?.coordinates?.longitude ?? 0,
              floor: config.orientation.floor,
              rotation: config.orientation.rotation,
              bubble: config.bubble?.id,
            },
            locale,
          });
          setCurrentFloor(currentFloor ?? config.orientation.floor);
          setIsInitialized(true);
          onInit?.();
        } catch (e) {
          throw e;
        }
      };

      void initialize();
    }

    return () => {
      onUnload?.();
      resetSearch();
      terminate();
    };
  }, [
    config.bubble?.id,
    config.orientation?.coordinates?.latitude,
    config.orientation?.coordinates?.longitude,
    config.orientation.floor,
    config.orientation.rotation,
    init,
    locale,
    onInit,
    onUnload,
    setCurrentFloor,
    terminate,
    language, // Added language to the dependencies to trigger the useEffect when the language changes, fixes: https://schiphol.atlassian.net/browse/MS-2394
  ]);

  return (
    <>
      {!isInitialized && <MapSpinner className={css.spinner} />}
      <div ref={ref} id={STATIC_MAP_ID} className={classNames} {...props} />
      {isInitialized && !isPublic && <YouAreHereMarker />}
      <LocationMarker
        id={ComponentId.LOCATION_MARKER}
        className={locationMarkerClassNames}
      />
      <CategoryMarkers />
    </>
  );
};
