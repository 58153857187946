import { DEFAULT_LOCALE } from '@/constants/i18n';
import type { Locale } from 'date-fns';
import { addDays, isAfter, isBefore, set } from 'date-fns';
import {
  ar, // Arabic
  da, // Danish
  de, // German
  el, // Greek
  enGB, // British English
  es, // Spanish
  fi, // Finnish
  fr, // French
  hi, // Hindi
  it, // Italian
  ja, // Japanese
  ko, // Korean
  nb, // Norwegian Bokmål
  nl, // Dutch
  pl, // Polish
  pt, // Portuguese
  ru, // Russian
  sv, // Swedish
  th, // Thai
  tr, // Turkish
  uk, // Ukranian
  vi, // Vietnamese
  zhCN, // Chinese Simplified
} from 'date-fns/locale';

export const localeMap: Record<string, Locale> = {
  ar,
  da,
  de,
  el,
  en: enGB,
  es,
  fi,
  fr,
  hi,
  it,
  ja,
  ko,
  nb,
  nl,
  pl,
  pt,
  ru,
  sv,
  th,
  tr,
  uk,
  vi,
  zh: zhCN,
};

export const getDateLocale = (currentLocale: string): Locale => {
  const language = currentLocale?.substring(0, 2);
  return localeMap[language] ? localeMap[language] : localeMap[DEFAULT_LOCALE];
};

const getDateTimeFromTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  return set(Date.now(), {
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: 0,
  });
};

export const dateIsInHourRange = (
  date: Date,
  fromHours: string,
  tillHours: string,
) => {
  const fromDateTime = getDateTimeFromTime(fromHours);
  let tillDateTime = getDateTimeFromTime(tillHours);

  if (isBefore(tillDateTime, fromDateTime)) {
    // till time is next day e.g. {fromTime: '05:00', tillTime: '01:30'}
    tillDateTime = addDays(tillDateTime, 1);
  }

  return isAfter(date, fromDateTime) && isBefore(date, tillDateTime);
};
