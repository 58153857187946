import { useMemo } from 'react';
import { useRouter } from 'next/router';
import type { Locale, MapModule } from 'pxp-api/clients/strapi/config-type';
import { DEFAULT_LOCALE } from '../../../constants/i18n';
import { Module } from '../../../enums/module';
import { useModule } from '../../../hooks/use-module';

interface GetPoiLocaleProps {
  localeCode?: string;
  localeCodeFallback?: string;
  locales?: Locale[];
}

export const getPoiLocale = ({
  locales,
  localeCode,
  localeCodeFallback = DEFAULT_LOCALE,
}: GetPoiLocaleProps) => {
  if (!locales?.length) {
    return localeCode || localeCodeFallback;
  }

  const allowedLocale = locales.find((locale) => locale.code === localeCode);
  return allowedLocale?.code || localeCodeFallback;
};

export const useMapLocale = (): string => {
  const { locale, defaultLocale } = useRouter();
  const mapModule = useModule<MapModule>(Module.MAP);

  return useMemo(
    () =>
      getPoiLocale({
        locales: mapModule?.properties?.locales,
        localeCode: locale,
        localeCodeFallback: defaultLocale,
      }),
    [locale],
  );
};
