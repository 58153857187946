import { Locale } from '../../../../enums/i18n';

const smokingTerms = {
  [Locale.NL]: ['roken', 'rook', 'rookruimte'],
  [Locale.EN]: [
    'smoking',
    'smoke',
    'smokingroom',
    'smokingarea',
    'smokearea',
    'smokeroom',
  ],
};

const MIN_CHARS = 2;

export const isMatchingSmokingTerm = (
  locale: Locale,
  searchTerm = '',
  minChars: number = MIN_CHARS,
): boolean => {
  const query = searchTerm.toLowerCase().replaceAll(' ', '');
  const terms =
    (smokingTerms as unknown as Record<string, string[]>)[locale] ||
    smokingTerms[Locale.EN];
  return (
    query.length >= minChars && terms.some((term) => term.startsWith(query))
  );
};
