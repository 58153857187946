import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

export type Ref = MutableRefObject<HTMLElement | null>;

export const useOutsideClick = (
  ref: Ref | Ref[],
  onOutSideClick: (event: MouseEvent | TouchEvent) => void,
  ignoreAttribute?: string,
) => {
  useEffect(() => {
    const checkIfElementHasIgnoreAttribute = (
      event: MouseEvent | TouchEvent,
      attribute: string,
    ): boolean => {
      let element = event.target as HTMLElement | null;

      // Check if the element or any of its parents have the ignore attribute
      while (element) {
        if (element.hasAttribute(attribute)) {
          return true;
        }
        element = element.parentElement;
      }
      return false;
    };

    const checkIfRefIsInArray = (
      event: MouseEvent | TouchEvent,
      refs: Ref[],
    ): boolean => {
      return refs.some((ref) => ref?.current?.contains(event.target as Node));
    };

    function handleClickOutside(event: MouseEvent | TouchEvent): void {
      const refsArray = Array.isArray(ref) ? ref : [ref];
      if (
        refsArray.some((ref) => ref?.current) &&
        !checkIfRefIsInArray(event, refsArray) &&
        (!ignoreAttribute ||
          !checkIfElementHasIgnoreAttribute(event, ignoreAttribute))
      ) {
        onOutSideClick(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, onOutSideClick, ignoreAttribute]);
};
