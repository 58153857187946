import { getFlightStatus } from 'pxp-api/clients/flight-info-api/helpers/status';
import type { Flight } from 'pxp-flight-api-schema/types';

import { FlightTime } from '@/modules/flight-info/components/flight-time/flight-time';

import { useFlightTimes } from '../../hooks/use-flight-times';

interface Props {
  flight: Flight;
  className?: string;
}

export const ArrivalTime = ({ flight, className }: Props) => {
  const { getArrivalTime } = useFlightTimes();
  const flightStatus = getFlightStatus(flight);
  const { estimatedTime, scheduledTime } = getArrivalTime(flight);

  if (!scheduledTime) return null;

  return (
    <FlightTime
      className={className}
      estimatedTime={estimatedTime}
      flightStatus={flightStatus}
      scheduledTime={scheduledTime}
    />
  );
};
