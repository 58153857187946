import { FC, ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { useOutsideClick } from 'pxp-utils/hooks/use-outside-click';
import { useFlightCardStore } from '../../store/flightCardStore';
import css from './content-top.module.scss';

export interface ContentTopProps {
  className?: string;
  children: ReactNode;
}

export const ContentTop: FC<ContentTopProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);
  const { setCollapsed } = useFlightCardStore();
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => {
    setCollapsed(true);
  });
  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};
