import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { ButtonBare } from '../../../../components/button-bare/button';
import css from './flight-info-detail-error.module.scss';

interface FlightInfoDetailErrorProps {
  onRetry?: () => void;
}

export const FlightInfoDetailError: FC<FlightInfoDetailErrorProps> = ({
  onRetry,
}) => {
  const { t } = useTranslation();
  return (
    <div className={css.root}>
      <p className={css.text}>
        {t('general.flightInfo.error', {
          defaultValue:
            'Unfortunately, something went wrong while collecting your flight information. Please try again',
        })}
      </p>
      <ButtonBare
        onClick={onRetry}
        label={'Search again'}
        size={'SMALL'}
        className={css.button}
      >
        {t('general.flightInfo.errorRetryButton', {
          defaultValue: 'Search again',
        })}
      </ButtonBare>
    </div>
  );
};
