'use client';
export const useHasPrivateMethodSupport = () => {
  try {
    eval(`
      class TestClass {
        #privateMethod() {
          return true;
        }
        test() {
          return this.#privateMethod();
        }
      }
      const instance = new TestClass();
      instance.test();
    `);
    return true;
  } catch (e) {
    return false;
  }
};
