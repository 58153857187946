import type { ButtonClick } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapButtonClick: Mapper<ButtonClick> = ({
  meta: { value, target = '(not set)', contentType },
}) => {
  if (value === 'HOME') {
    return {
      eventName: GTMEventName.HOME_BUTTON,
    };
  }

  return {
    eventName: GTMEventName.CONTENT_OPEN_LINK,
    eventLabel: value,
    contentType: contentType || target,
  };
};
