import css from '@/components/external-link/external-link.module.scss';
import { Icon } from '@/components/icon/icon';
import { ModalTrigger } from '@/modals';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type {
  QRCodeImpression,
  QRCodeImpressionType,
} from '@/modules/analytics/types/event-props';
import { Modal, useModalStore } from '@/store/modalStore';
import type { IconName } from '@/types/icon';
interface Props {
  label: string;
  value?: string;
  icon?: IconName;
  isButtonStyle?: boolean;
  modalTitle?: string;
  qrCodeImpressionType: QRCodeImpressionType;
}
export const ExternalLinkKiosk = ({
  label,
  icon,
  value,
  isButtonStyle = false,
  modalTitle,
  qrCodeImpressionType,
}: Props) => {
  const dataLayer = useDataLayer();
  const closeModal = useModalStore((state) => state.closeModal);

  const handleClick = () => {
    // Todo: now have to manually close the CSAT modal if it's open, this isn't needed anymore if
    // the CSAT modal is migrated to a dialog
    closeModal(Modal.CSAT);
    dataLayer.publish<QRCodeImpression>(UIEventName.QR_CODE_IMPRESSION, {
      type: qrCodeImpressionType,
    });
  };

  return (
    <ModalTrigger
      modalId="MOVE_TO_MOBILE"
      contentProps={{ qrCodeValue: value, title: modalTitle }}
      onClick={handleClick}
    >
      <button className={isButtonStyle ? css.button : css.link} type="button">
        <span className={css.label}>{label}</span>
        {icon && <Icon className={css.icon} name={icon} />}
      </button>
    </ModalTrigger>
  );
};
