/* eslint-disable react-hooks/rules-of-hooks  -- This module contains a whole lot
of conditionally called hooks, which is not OK accoridng to React. This Needs
To be rafactored.
*/
import { useCallback, useContext } from 'react';
import type { MapModule } from 'pxp-api/clients/strapi/config-type';
import { useShallow } from 'zustand/react/shallow';

import { Module } from '../../../enums/module';
import { useModule } from '../../../hooks/use-module';
import { MapContext } from '../context/map-context';
import { useCurrentFloorStore } from '../store/currentFloorStore';
import {
  DEFAULT_COLLAPSED_STATE,
  useDetailCardStore,
} from '../store/detailCardStore';
import { usePoiSearchStore } from '../store/poiSearchStore';
import type { WayFindingMeta, WayFindingType } from '../store/types';
import { useWayFindingStore } from '../store/wayFindingStore';
import type { Poi } from '../types/poi';
import type { MapGoToPoiOptions, MapProviderAdapter } from '../types/provider';

export interface MapContextInterface
  extends Omit<MapProviderAdapter, 'startRoute'> {
  startRoute(
    poi: Poi,
    type: WayFindingType,
    meta?: WayFindingMeta,
  ): Promise<void>;
  setDetailCardCollapsed(isCollapsed: boolean): void;
  resetSearch(): void;
}

export const useMap = (): MapContextInterface => {
  const map = useContext(MapContext);
  const mapModule = useModule<MapModule>(Module.MAP);

  const poiSearchActions = usePoiSearchStore(
    useShallow(({ setPending, setSuccess, setError, reset }) => ({
      setPending,
      setSuccess,
      setError,
      reset,
    })),
  );

  const setCurrentFloor = useCurrentFloorStore(
    useShallow((state) => state.setCurrentFloor),
  );

  const wayFindingActions = useWayFindingStore(
    useShallow(({ startRoute, stopRoute }) => ({
      startRoute,
      stopRoute,
    })),
  );

  const setCollapsed = useDetailCardStore(
    useShallow((state) => state.setCollapsed),
  );

  if (!map) {
    throw new Error('Map provider is not initialized');
  }

  const startRoute = useCallback(
    (poi: Poi, type: WayFindingType, meta?: WayFindingMeta) => {
      wayFindingActions.startRoute(poi, type, meta);
      return map.startRoute(poi.id);
    },
    [map, wayFindingActions],
  );

  const stopRoute = useCallback(() => {
    wayFindingActions.stopRoute();
    return map.stopRoute();
  }, [map, wayFindingActions]);

  const setDetailCardCollapsed = useCallback(
    (isCollapsed: boolean) => {
      setCollapsed(isCollapsed);
    },
    [setCollapsed],
  );

  const goToFloor = useCallback(
    (floor: string) => {
      setCurrentFloor(floor);
      return map.goToFloor(floor);
    },
    [map, setCurrentFloor],
  );

  const goToPoi = useCallback(
    async (id: string, options?: MapGoToPoiOptions) => {
      const poi = await map.goToPoi(id, options);

      const defaultCollapseState =
        !mapModule?.properties?.expandPoiOnSelect ?? DEFAULT_COLLAPSED_STATE;
      setCollapsed(defaultCollapseState);

      if (poi?.floor) {
        setCurrentFloor(poi?.floor);
      }

      return poi;
    },
    [map, mapModule, setCollapsed, setCurrentFloor],
  );

  const search: typeof map.search = useCallback(
    async (filters, options) => {
      poiSearchActions.setPending(filters);

      try {
        const results = await map.search(filters, options);
        poiSearchActions.setSuccess(results);
        return results;
      } catch (e) {
        poiSearchActions.setError(e as Error);
      }

      return [];
    },
    [map, poiSearchActions],
  );

  const resetSearch = useCallback(() => {
    poiSearchActions.reset();
  }, [poiSearchActions]);

  return {
    ...map,
    startRoute,
    stopRoute,
    setDetailCardCollapsed,
    search,
    resetSearch,
    goToFloor,
    goToPoi,
  };
};
