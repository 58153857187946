import type { AllHTMLAttributes, FC } from 'react';

export const Info: FC<AllHTMLAttributes<SVGElement>> = (
  props: AllHTMLAttributes<SVGElement>,
) => (
  <svg
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M99.9987 166.666C136.818 166.666 166.665 136.819 166.665 99.9997C166.665 63.1807 136.818 33.333 99.9987 33.333C63.1797 33.333 33.332 63.1807 33.332 99.9997C33.332 136.819 63.1797 166.666 99.9987 166.666Z"
      fill="currentColor"
    />
    <path
      d="M99.9996 76.9083C103.643 76.9083 106.597 73.9546 106.597 70.3111C106.597 66.6675 103.643 63.7139 99.9996 63.7139C96.356 63.7139 93.4023 66.6675 93.4023 70.3111C93.4023 73.9546 96.356 76.9083 99.9996 76.9083Z"
      fill="white"
    />
    <path d="M106.597 86.8057H93.4023V136.285H106.597V86.8057Z" fill="white" />
  </svg>
);
