import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { MapModule } from 'pxp-api/clients/strapi/config-type';
import { Icon } from '../../../../components/icon/icon';
import type { Locale } from '../../../../enums/i18n';
import { Module } from '../../../../enums/module';
import { useModule } from '../../../../hooks/use-module';
import { IconName } from '../../../../types/icon';
import { isMatchingSmokingTerm } from './helpers';
import css from './smoking-area-message.module.scss';

interface SmokingAreaMessageProps {
  query?: string;
}

export const SmokingAreaMessage: FC<SmokingAreaMessageProps> = ({ query }) => {
  const mapModule = useModule<MapModule>(Module.MAP);
  const { t, i18n } = useTranslation();

  const showMessage = useMemo(
    () => isMatchingSmokingTerm(i18n.language as Locale, query),
    [i18n.language, query],
  );

  if (mapModule?.properties?.hasSmokingAreas || !showMessage) {
    return null;
  }

  return (
    <div className={css.root}>
      <Icon name={IconName.NO_SMOKING} className={css.icon} />
      {t('general.map.noSmokingAreas', {
        defaultValue: 'This airport has become a completely non-smoking area.',
      })}
    </div>
  );
};
