import type { UrlObject } from 'url';

import classnames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Icon } from '@/components/icon/icon';
import { SrOnly } from '@/components/sr-only/sr-only';
import { IconName } from '@/types/icon';

import type { Poi } from '../../types/poi';
import { FloorIndication } from '../floor-indication/floor-indication';
import { OpeningTimes } from '../opening-times/opening-times';
import { WalkingTime } from '../walking-time/walking-time';

import css from './map-search-result.module.scss';

export interface MapSearchResultProps {
  className?: string;
  poi: Poi;
  href: string | UrlObject;
}

export const MapSearchResult = ({
  className,
  poi,
  href,
}: MapSearchResultProps) => {
  const classNames = classnames(css.root, className);
  const { t } = useTranslation();
  const poiIcon = poi.icon
    ? IconName[poi.icon] || IconName.MAP_PIN
    : IconName.MAP_PIN;

  // Decimal precision of 5 gives about 1 meter accuracy
  // https://wiki.openstreetmap.org/wiki/Precision_of_coordinates.
  // Good balance between accuracy and not reading too many characters to a
  // sccreenreader.
  const coordinates = {
    lat: poi.position.lat.toFixed(5),
    lon: poi.position.lon.toFixed(5),
  };

  return (
    <li className={classNames}>
      <Link className={css.link} href={href}>
        <div className={css.category}>
          <Icon name={poiIcon} className={css.categoryIcon} />
        </div>
        <div className={css.body}>
          <div className={css.header}>
            <span className={css.name}>{poi.name}</span>
            <SrOnly>
              {t('general.map.searchResult.coordinates', {
                ...coordinates,
                defaultValue: `Coordinates: ${coordinates.lat}, ${coordinates.lon}`,
              })}
            </SrOnly>
            <FloorIndication floorId={poi.floor} />
          </div>
          <span className={css.bubble}>{poi.bubble?.name}</span>
        </div>
        <div className={css.info}>
          {poi.position.distanceInSeconds && (
            <WalkingTime distanceInSeconds={poi.position.distanceInSeconds} />
          )}
          {poi.openingTimes && (
            <OpeningTimes
              className={css.openingTimes}
              openingTimes={poi.openingTimes}
            />
          )}
        </div>
      </Link>
    </li>
  );
};
