import React from 'react';
import { snakeCase } from 'lodash';
import { Pill } from '../pill/pill';
import { ScrollArea } from '../scroll-area/scroll-area';
import css from './pills.module.scss';

export interface Props {
  items: (string | undefined)[];
  handleClick?: (label?: string) => void;
}

export const Pills = ({ items, handleClick }: Props) => {
  return (
    <ScrollArea orientation="horizontal" hideScrollbar>
      <div className={css.root}>
        {items
          ?.map(
            (item) =>
              item && (
                <Pill
                  key={snakeCase(item)}
                  label={item}
                  onClick={handleClick}
                />
              ),
          )
          .filter(Boolean)}
      </div>
    </ScrollArea>
  );
};
