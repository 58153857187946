import type { MapFloor, MapModule } from 'pxp-api/clients/strapi/config-type';

import type { SSU } from '../../../../../types/content-schema';
import type { DataLayerCallbackProps } from '../../../data-layer';
import type {
  MapFilter,
  MapFloorSelect,
  MapPoiDetail,
  MapRouting,
  MapSearch,
} from '../../../types/event-props';
import { MapFilterAction, MapRoutingAction } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

const getInitialFloor = (ssu?: SSU) => {
  const initialFloorId = ssu?.config.orientation.floor;

  if (!initialFloorId) {
    return undefined;
  }

  return (
    (ssu?.modules?.map as MapModule)?.properties?.floors as MapFloor[]
  )?.find((floor) => floor.id === initialFloorId);
};

const withMapData =
  <T>(callback: Mapper<T>) =>
  (props: DataLayerCallbackProps<T>) => {
    const result = callback(props);
    const initialFloor = getInitialFloor(props.data.ssu);
    const floor = Number(
      props.data.map?.floor?.number ?? initialFloor?.number ?? 0,
    );

    return {
      ...result,
      contentType: 'map',
      floor,
    };
  };

export const mapMapSearch = withMapData<MapSearch>(({ meta: { query } }) => ({
  eventName: GTMEventName.MAP_SEARCH,
  eventLabel: query,
}));

export const mapMapSelectFloor = withMapData<MapFloorSelect>(
  ({ meta: { floor } }) => ({
    eventName: GTMEventName.MAP_SELECT_FLOOR,
    eventLabel: Number(floor.number),
  }),
);

export const mapMapFilter = withMapData<MapFilter>(
  ({ meta: { filter, action } }) => ({
    eventName:
      action === MapFilterAction.SELECT
        ? GTMEventName.MAP_OPEN_CATEGORY
        : GTMEventName.MAP_CLOSE_CATEGORY,
    eventLabel: filter,
  }),
);

export const mapMapPoiDetail = withMapData<MapPoiDetail>(
  ({ meta: { poi, searchQuery } }) => ({
    eventName: GTMEventName.MAP_OPEN_POI_DETAIL,
    eventLabel: poi,
    poi_title: poi,
    searchQuery,
  }),
);

export const mapMapRouting = withMapData<MapRouting>(
  ({ meta: { poiName, action } }) => ({
    eventName:
      action === MapRoutingAction.START
        ? GTMEventName.MAP_START_ROUTING
        : GTMEventName.MAP_STOP_ROUTING,
    eventLabel: poiName,
  }),
);
