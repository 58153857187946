import { useCallback, useContext } from 'react';

import type { ToastOptions } from '../context/toast-context';
import { ToastContext } from '../context/toast-context';

export const useToast = () => {
  const toastContext = useContext(ToastContext);
  if (toastContext === undefined) {
    throw new Error('useToast must be called from within a ToastProvider');
  }

  const { showToast } = toastContext;

  const showSuccessToast = useCallback(
    (message: string, options?: Partial<ToastOptions>) =>
      showToast('success', message, options),
    [showToast],
  );

  const showDangerToast = useCallback(
    (message: string, options?: Partial<ToastOptions>) =>
      showToast('danger', message, options),
    [showToast],
  );

  return {
    toast: toastContext.activeToast,
    showSuccessToast,
    showDangerToast,
    dismissToast: toastContext.dismissToast,
  };
};
