import { isAfter } from 'date-fns';
import { isCancelled } from 'pxp-api/clients/flight-info-api/helpers/status';
import type { DateTime, Flight } from 'pxp-flight-api-schema/types';

import { useDateFormatting } from '@/hooks/use-date-formatting';

export function useFlightTimes() {
  const { getFormattedDate } = useDateFormatting();

  const onlyTimeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const getTimes = (scheduled?: DateTime, estimated?: DateTime) => ({
    scheduledTime: scheduled
      ? getFormattedDate({
          date: new Date(scheduled),
          dateTimeFormatOptions: onlyTimeFormat,
        })
      : undefined,
    estimatedTime: estimated
      ? getFormattedDate({
          date: new Date(estimated),
          dateTimeFormatOptions: onlyTimeFormat,
        })
      : undefined,
  });

  const getDepartureDate = (flight: Flight) =>
    flight.departure.scheduledDepartureTime
      ? getFormattedDate({
          date: new Date(flight.departure.scheduledDepartureTime),
          dateTimeFormatOptions: {
            day: 'numeric',
            month: 'short',
          },
        })
      : undefined;

  const getDepartureTime = (flight: Flight) =>
    getTimes(
      flight.departure.scheduledDepartureTime,
      flight.departure.estimatedDepartureTime,
    );

  const getArrivalDate = (flight: Flight) =>
    flight.arrival.scheduledArrivalTime
      ? getFormattedDate({
          date: new Date(flight.arrival.scheduledArrivalTime),
          dateTimeFormatOptions: {
            day: 'numeric',
            month: 'short',
          },
        })
      : undefined;

  const getArrivalTime = (flight: Flight) =>
    getTimes(
      flight.arrival.scheduledArrivalTime,
      flight.arrival.estimatedArrivalTime,
    );

  const getBoardingTime = (flight: Flight) =>
    flight.departure.boardingTime && !isCancelled(flight)
      ? getFormattedDate({
          date: new Date(flight.departure.boardingTime),
          dateTimeFormatOptions: onlyTimeFormat,
        })
      : undefined;

  const getUpdatedAtTime = (flight: Flight) =>
    flight.updatedAtTime
      ? getFormattedDate({
          date: new Date(flight.updatedAtTime),
          dateTimeFormatOptions: onlyTimeFormat,
        })
      : undefined;

  const hasBoardingStarted = (flight: Flight) =>
    flight.departure.boardingTime
      ? isAfter(new Date(), new Date(flight.departure.boardingTime))
      : false;

  return {
    getArrivalDate,
    getArrivalTime,
    getBoardingTime,
    getDepartureDate,
    getDepartureTime,
    getUpdatedAtTime,
    hasBoardingStarted,
  };
}
