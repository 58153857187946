import type { FC } from 'react';
import { Module } from '../../enums/module';
import { useModule } from '../../hooks/use-module';
import { CustomerSatisfactionScore } from './components/customer-statisfaction-score/customer-satisfaction-score';
import type { CustomerSatisfactionScoreModule } from 'pxp-api/clients/strapi/config-type';

export const Csat: FC = () => {
  const csatModule = useModule<CustomerSatisfactionScoreModule>(Module.CSAT);

  if (!csatModule?.enabled) {
    return null;
  }

  return <CustomerSatisfactionScore />;
};
