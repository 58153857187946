import type { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';

import { ButtonBare } from '@/components/button-bare/button';
import { Path, QueryParam } from '@/enums/route';
import { mergeSsuQueryParams } from '@/lib/url';
import { IconName } from '@/types/icon';

type RenderLinkToPoiProps = {
  node: HTMLElement;
};

const getPoiIDFromNode = (node: HTMLElement) => {
  const originalHref = node.getAttribute('href') ?? '';
  const urlObject = new URL(originalHref);
  return urlObject.searchParams.get(QueryParam.POI_ID) ?? '';
};

const getHref = (query: ParsedUrlQuery, poiId: string) => {
  const newQuery = mergeSsuQueryParams(query, { [QueryParam.POI_ID]: poiId });
  const params = new URLSearchParams(newQuery);
  return `${Path.MAP}?${params.toString()}`;
};

export const RenderLinkToPoi = ({ node }: RenderLinkToPoiProps) => {
  const { query } = useRouter();
  const poiId = getPoiIDFromNode(node);
  const href = getHref(query, poiId);
  const label = node.textContent ?? '';

  return (
    <ButtonBare
      as="a"
      href={href}
      icon={IconName.MAP_PIN}
      label={label}
      size="SMALL"
      variant="RECTANGLE"
    />
  );
};
