/* eslint-disable react-hooks/exhaustive-deps -- The scanner does not work with correct deps. Needs to be investigated */
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const SCANNED_DATA_BYTES: [number, number] = [1, 57];
const LONG_SCAN_DATA_WAIT_IN_MS = 5;

export enum BarcodeScannerState {
  UNCONNECTED = 'UNCONNECTED',
  CONNECTED = 'CONNECTED',
  ERROR = 'ERROR',
}

interface UseHIDBarcodeScannerProps {
  scanner: {
    productId: number;
    vendorId: number;
  };
  onScan: (data: string) => void;
  isEnabled?: boolean;
}

type HandleInputReport = (event: HIDInputReportEvent, data: string) => void;
/*
    HID devices return data through an input report that contains a DataView (buffer).

    According to the Scanner manual the 1-57 bytes (SCANNED_DATA_BYTES) contain its decoded data
    - https://www.newland-id.com/sites/default/files/documents/2021-02/fm3080_user_guide_v1.0.0.pdf

    This function converts the buffer to actual characters.
 */

const decodeInputReportEvent =
  (callback: HandleInputReport) => (event: HIDInputReportEvent) => {
    const arrayFromBuffer = Array.from(
      new Int8Array(event.data.buffer.slice(...SCANNED_DATA_BYTES)),
    );
    const data = String.fromCharCode.apply(
      null,
      arrayFromBuffer.filter(Boolean),
    );
    const replaceRegex = new RegExp('\r|\n', 'g');
    return callback(event, data.replace(replaceRegex, ''));
  };

let scanMemory = '';

export const useHIDBarcodeScanner = ({
  scanner,
  onScan,
  isEnabled = false,
}: UseHIDBarcodeScannerProps) => {
  const [state, setState] = useState(BarcodeScannerState.UNCONNECTED);

  const debouncedOnScan = debounce(() => {
    onScan(scanMemory);
    scanMemory = '';
  }, LONG_SCAN_DATA_WAIT_IN_MS);

  const handleScanReport: HandleInputReport = (event, data) => {
    scanMemory += data;
    debouncedOnScan();
  };

  const getDevice = async () => {
    if (!('hid' in navigator)) {
      return undefined;
    }

    const devices = await navigator.hid.getDevices();

    return devices?.find(
      (dev) =>
        dev.vendorId === scanner.vendorId &&
        dev.productId === scanner.productId,
    );
  };

  const requestDevice = async () => {
    const devices = await navigator.hid.requestDevice({
      filters: [scanner],
    });

    return devices[0];
  };

  const setDeviceInputReportHandler = (device: HIDDevice) => {
    device.oninputreport = decodeInputReportEvent(handleScanReport);
    return device;
  };

  const setupDevice = async (device: HIDDevice) => {
    if (device.opened && device.oninputreport) {
      return;
    }

    if (!device.opened) {
      await device.open();
    }

    setDeviceInputReportHandler(device);

    setState(BarcodeScannerState.CONNECTED);
  };

  const connect = async () => {
    if (!('hid' in navigator)) {
      throw new Error('Can not use Barcode Scanner');
    }

    if (!scanner.vendorId || !scanner.productId) {
      console.warn('No barcode scanner config found');
      return;
    }

    const device = await getDevice();

    if (device) {
      return setupDevice(device);
    }

    const requestHIDConnection = async () => {
      try {
        const device = await requestDevice();
        if (!device) {
          throw new Error('No devices found');
        }
        return setupDevice(device);
      } catch (e) {
        console.warn('No device access granted', e);
        setState(BarcodeScannerState.ERROR);
        return;
      }
    };

    const handler = () => {
      void requestHIDConnection();
      document.removeEventListener('click', handler);
    };

    document.addEventListener('click', handler, { once: true });

    return () => {
      document.removeEventListener('click', handler);
    };
  };

  useEffect(() => {
    if (isEnabled) {
      getDevice().then(
        (device) => device && setDeviceInputReportHandler(device),
      );
    }
  }, [onScan, isEnabled]);

  return {
    connect,
    state,
  };
};
