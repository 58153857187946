import type {
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
} from 'react';
import { useRef } from 'react';
import React from 'react';

import { useModal } from '@/context/modal-provider';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type { ModalEvent } from '@/modules/analytics/types/event-props';

import type { Modal } from './types';
import { type ModalContentProps } from './types';
import { modalEventProps } from './utils';

export interface Props<ID extends Modal> {
  modalId: ID;
  contentProps?: ModalContentProps[ID];
  onClick?: () => void;
}

/**
 * ModalTrigger can wrap an interactive element and automatically handle opening
 * the right modal on interaction. It also takes care of making sure focus is
 * returned to the wrapped element when the dialog is closed.
 * The wrapped element must forward a ref to its root element.
 */
export function ModalTrigger<ID extends Modal>({
  modalId,
  contentProps,
  onClick: onClickFromProps,
  children,
}: PropsWithChildren<Props<ID>>) {
  const { openModal, activeModals } = useModal();
  const triggerRef = useRef<HTMLElement | null>(null);
  const dataLayer = useDataLayer();

  const isExpanded = activeModals.some(({ id }) => id === modalId);
  const handleClick = () => {
    onClickFromProps?.();
    openModal(
      modalId,
      contentProps as ModalContentProps[typeof modalId],
      triggerRef.current,
    );
    dataLayer.publish<ModalEvent>(
      UIEventName.MODAL,
      modalEventProps(modalId, 'open'),
    );
  };

  const childElement = React.Children.only(children);

  const childWithClickHandler = React.isValidElement<
    {
      onClick: () => void;
      ref: MutableRefObject<HTMLElement | null>;
    } & HTMLAttributes<HTMLElement>
  >(childElement)
    ? React.cloneElement(childElement, {
        onClick: handleClick,
        ref: triggerRef,
        'aria-haspopup': 'dialog',
        'aria-expanded': isExpanded,
        'aria-controls': modalId,
      })
    : null;

  if (!childWithClickHandler) {
    throw new Error(
      'Could not render modal trigger. Was not able to clone child component.',
    );
  }

  return childWithClickHandler;
}

export default ModalTrigger;
