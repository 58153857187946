import { stripNullValues } from 'pxp-api/clients/strapi/helpers';
import { eventDataMap } from './map-events';
import { getGenericData } from './mappers/data';
import type { SubscriptionHandler } from './types';

const DEFAULT_EVENT_NAME = 'GAEvent';

export const schipholSubscriptionHandler: SubscriptionHandler =
  (track) => (props) => {
    const data = eventDataMap[props.event]?.(props);
    const genericData = getGenericData(props.data);

    if (!data) {
      return;
    }

    track({
      event: DEFAULT_EVENT_NAME,
      ...stripNullValues(data),
      ...genericData,
    });
  };
