import type { UrlObject } from 'url';
import type { NextRouter } from 'next/router';
import { createSsuHref } from '../../lib/url';
import { Path, QueryParam, Route } from '../../enums/route';

export const getLastDifferentPrevUrl = (
  urls: string[],
  differentFrom: string,
) => {
  return urls.find((url) => !url.includes(differentFrom));
};

export const getBackPath = (
  urls: string[],
  router: NextRouter,
  fallBackUrl?: UrlObject | string,
) => {
  const { pathname, asPath, query } = router;
  const fallback = fallBackUrl || createSsuHref(router);

  if (pathname.includes(Path.MAP)) {
    return getLastDifferentPrevUrl(urls, Route.MAP) || fallback;
  }

  if (pathname.includes(Path.FLIGHT_INFO) && !query[QueryParam.FLIGHT_ID]) {
    return fallback;
  }

  return urls.find((url) => url !== asPath) || fallback;
};

export const getUpdatedList = (list: string[], url: UrlObject | string) => {
  const index = list.findIndex((item) => item === url);
  const sliceIndex = index + 1;

  return index > -1 && list.length >= sliceIndex ? list.slice(sliceIndex) : [];
};
