import type { Flight } from 'pxp-flight-api-schema/types';

export enum FlightDirection {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export interface FlightInfoByDate {
  date: string;
  flights: ReadonlyArray<Flight>;
}

export type FlightInfoByIdResponse = Flight;
