'use client';

import * as Dialog from '@radix-ui/react-dialog';
import * as Sentry from '@sentry/nextjs';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { createElement, memo, useCallback } from 'react';

import {
  ButtonIcon,
  ButtonVariant,
} from '@/components/button-icon/button-icon';
import { useModal } from '@/context/modal-provider';
import type { BaseContentProps, ModalMap, ModalOptions } from '@/modals/types';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import { IconName } from '@/types/icon';

import css from './modal-container.module.scss';
import { modalEventProps } from './utils';

export interface Props {
  modalMap: ModalMap;
}

const baseModalOptions: ModalOptions = {
  backgroundImage: false,
  dismissOnClickOutside: true,
  dismissOnEscape: true,
  fullscreen: false,
  modal: true,
  showFooter: true,
};

/**
 * Returns the callback if the predicate is `true`, otherwise returns an empty
 * event with `event.preventDefault()`.
 */
function maybePreventedEvent<E extends Event>(
  predicate: boolean,
  callback?: (event: E) => void,
) {
  return predicate
    ? callback
    : (e: Event) => {
        e.preventDefault();
      };
}

export const ModalContainer = memo(({ modalMap }: Props) => {
  const { activeModals, closeModal, popModal } = useModal();
  const { t } = useTranslation();
  const datalayer = useDataLayer();

  // Set the inital focus as per https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/
  // This should only be used if the modal content does not have any interactive elements.
  const initialFocusRef = useCallback(<T extends HTMLElement>(el: T) => {
    if (!el) {
      return;
    }
    el.tabIndex = -1;
    el.focus();
  }, []);

  // The top of the stack is the one that should be rendered.
  // This will be the last item in the list.
  const topStackModal = activeModals.at(-1);

  const handleClose = useCallback(() => {
    if (!topStackModal) {
      return;
    }
    datalayer.publish(
      UIEventName.MODAL,
      modalEventProps(topStackModal.id, 'close'),
    );
    closeModal();
  }, [topStackModal, closeModal, datalayer]);

  const handleBack = useCallback(() => {
    // TODO check type of datalayer event that needs to happen here.
    popModal();
  }, [popModal]);

  if (!topStackModal) return null;

  if (!modalMap[topStackModal.id]) {
    const errorMessage = `Could not find modal with id ${topStackModal.id}`;
    console.error(errorMessage);
    Sentry.captureMessage(errorMessage);
    return null;
  }

  const activeModalOptions = {
    ...baseModalOptions,
    ...(modalMap[topStackModal.id].options ?? {}),
  };

  // Create the actual content element.
  // Typescript can't narrow to the correct type here, so cast to something more
  // generic.
  const modalContent = createElement(
    modalMap[topStackModal.id].content as React.ComponentType<BaseContentProps>,
    {
      ...topStackModal.contentProps,
      initialFocusRef,
    },
  );

  return (
    <Dialog.Root
      defaultOpen
      modal={activeModalOptions.modal}
      data-test={topStackModal.id}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          className={classnames(
            css.overlay,
            activeModalOptions.backgroundImage && css.background,
          )}
        />
        <Dialog.Content
          id={topStackModal.id}
          className={classnames(
            css.content,
            activeModalOptions.fullscreen && css.fullscreen,
          )}
          onPointerDownOutside={maybePreventedEvent(
            activeModalOptions.dismissOnClickOutside,
            handleClose,
          )}
          onEscapeKeyDown={maybePreventedEvent(
            activeModalOptions.dismissOnEscape,
            handleClose,
          )}
          key={topStackModal.id}
        >
          {modalContent}
          {activeModalOptions.showFooter && (
            <div className={css.bottom}>
              {activeModals.length > 1 && (
                <ButtonIcon
                  iconClassName={css.backIcon}
                  variant={ButtonVariant.SECONDARY}
                  iconName={IconName.ARROW_LEFT}
                  title={t('general.back', { defaultValue: 'Back' })}
                  onClick={handleBack}
                />
              )}
              <ButtonIcon
                className={css.close}
                variant={ButtonVariant.SECONDARY}
                iconName={IconName.CLOSE}
                title={t('general.close', { defaultValue: 'Close' })}
                onClick={handleClose}
              />
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
