import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { FlightCardStore } from './types';

export const useFlightCardStore = createWithEqualityFn<FlightCardStore>()(
  devtools(
    immer((set) => ({
      isCollapsed: false,
      setCollapsed: (isCollapsed) =>
        set((state) => {
          state.isCollapsed = isCollapsed;
        }),
    })),
  ),
);
