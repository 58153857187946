import type { ActionType } from '@/hooks/use-boarding-pass-scan/config';
import { format, getUnixTime } from 'date-fns';
import { isDelayed } from 'pxp-api/clients/flight-info-api/helpers/status';
import { FlightType } from 'pxp-api/clients/flight-info-api/types/flight';
import type { Flight } from 'pxp-flight-api-schema/types';
import type { BoardingPassInfo } from '../../../../../store/boardingPassInfoStore';
import type {
  DefaultMeta,
  FightInfoSearch,
  FlightInfoAction,
  FlightInfoDetail,
} from '../../../types/event-props';
import {
  FlightInfoActionType,
  FlightInfoDetailDisplayType,
} from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapFlightInfoSearch: Mapper<FightInfoSearch> = ({
  meta: { query },
}) => ({
  eventName: GTMEventName.FLIGHT_INFO_SEARCH,
  eventLabel: query,
});

const createFlightInfo = (flight: Flight) => ({
  flightDestination: flight.arrival.airportName,
  flightDirection: flight.direction.toLowerCase(),
  flightNumber: flight.iataCode?.replaceAll(' ', ''),
  flightStatus: flight.status,
  flightDate: flight.departure.scheduledDepartureTime
    ? format(new Date(flight.departure.scheduledDepartureTime), 'yyyy-MM-dd')
    : null,
  departureDatetime: flight.departure.scheduledDepartureTime
    ? getUnixTime(new Date(flight.departure.scheduledDepartureTime))
    : null,
  gateNumber: flight.departure.gateNumber,
  schengen: flight.flightType === FlightType.SCHENGEN,
  terminal: flight.departure.terminalNumber,
  checkIn: flight.departure.checkInDeskNumber,
  ...(isDelayed(flight)
    ? {
        newDepartureDatetime: flight.departure.estimatedDepartureTime
          ? getUnixTime(new Date(flight.departure.estimatedDepartureTime))
          : null,
      }
    : {}),
});

export const mapFlightInfoDetail: Mapper<FlightInfoDetail> = ({ meta }) => ({
  eventName:
    meta?.displayType === FlightInfoDetailDisplayType.ITINERARY
      ? GTMEventName.FLIGHT_INFO_ITINERARY
      : GTMEventName.FLIGHT_INFO_FLIGHT_DETAIL,
  ...(meta?.flight ? createFlightInfo(meta.flight) : {}),
});

const flightInfoActionTypeMap = {
  [FlightInfoActionType.CLICK_SHOW_ON_MAP]:
    GTMEventName.FLIGHT_INFO_SHOW_ON_MAP,
  [FlightInfoActionType.CLICK_DISCOVER_POIS]:
    GTMEventName.FLIGHT_INFO_ITINERARY_DISCOVER_POIS,
};

const flightInfoActionDataKeyMap = {
  [FlightInfoActionType.CLICK_SHOW_ON_MAP]: 'linkPosition',
  [FlightInfoActionType.CLICK_DISCOVER_POIS]: 'poiCategory',
};

export const mapFlightInfoAction: Mapper<FlightInfoAction> = ({ meta }) => ({
  eventName: flightInfoActionTypeMap[meta?.type],
  ...(meta?.flight ? createFlightInfo(meta.flight) : {}),
  [flightInfoActionDataKeyMap[meta?.type]]: meta?.data,
});

export const mapFlightInfoAirlineLounge: Mapper<DefaultMeta> = ({
  meta: { value },
}) => ({
  eventName: GTMEventName.FLIGHT_INFO_OPEN_WAY_FINDING,
  eventLabel: value,
});

export const mapBoardingPassScan: Mapper<
  DefaultMeta & { actionType: ActionType; boardingPassInfo?: BoardingPassInfo }
> = ({ meta: { actionType, value, boardingPassInfo } }) => {
  const eventName =
    actionType === 'SHOW_FLIGHT_INFO'
      ? GTMEventName.FLIGHT_INFO_SCAN_BOARDING_PASS
      : GTMEventName.SCAN_BOARDING_PASS;

  if (!boardingPassInfo)
    return {
      eventLabel: value,
      eventName,
    };

  const {
    checkIn,
    date,
    destination: flightDestination,
    flightNumber,
    gate: gateNumber,
    newDepartureTime,
    originalDepartureTime,
    departures: terminal,
  } = boardingPassInfo;

  let departureDateTime: number | undefined;

  if (originalDepartureTime) {
    departureDateTime = originalDepartureTime.getTime();
  }

  if (newDepartureTime) {
    departureDateTime = newDepartureTime.getTime();
  }

  return {
    checkIn,
    departureDateTime,
    eventLabel: value,
    eventName,
    flightDate: format(date, 'yyyy-MM-dd'),
    flightDestination,
    flightDirection: 'departure',
    flightNumber,
    gateNumber,
    terminal,
  };
};
