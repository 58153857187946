import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { SessionStorageKey } from '@/enums/session-storage';
import { useCustomSessionStorageState } from '@/hooks/use-custom-session-storage-state';
import { useToast } from '@/hooks/use-toast';

export const ConnectedToWifiToast = () => {
  const { showSuccessToast } = useToast();
  const { t } = useTranslation();
  const [notificationShown, setNotificationShown] =
    useCustomSessionStorageState(
      SessionStorageKey.CONNECTED_TO_WIFI_TOAST_SHOWN,
      false,
    );

  useEffect(() => {
    if (
      !process.env.NEXT_PUBLIC_WIFI_PORTAL_REFERRER ||
      notificationShown ||
      !document.referrer.includes(process.env.NEXT_PUBLIC_WIFI_PORTAL_REFERRER)
    ) {
      return;
    }

    showSuccessToast(
      t(
        'components.connected-to-wifi-toast.content',
        'You are successfully connected to the internet.',
      ),
      {
        autodismissTimer: 5_000,
        position: 'top',
      },
    );
    setNotificationShown(true);
  }, [notificationShown, setNotificationShown, showSuccessToast, t]);

  return null;
};
