import type { ResponseError } from '../types/http';

export const customFetch = async <T = {}>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> => {
  const results = await fetch(input, init);

  if (!results.ok) {
    const error: ResponseError = new Error(
      `An error occurred while fetching ${
        typeof input !== 'string' ? input.url : input
      }`,
    );
    error.info = (await results.json()) as object;
    error.status = results.status;
    throw error;
  }

  return results.json() as Promise<T>;
};
