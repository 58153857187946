import { VideoCall } from '@/modules/analytics/types/event-props';
import { VideoCallStatus } from '../../../../../types/component';
import { GTMEventName } from '../event';
import { getCallStatus } from '../helpers/get-call-status';
import type { Mapper } from '../types';

export const mapVideoCall: Mapper<{
  action: Exclude<VideoCallStatus, VideoCallStatus.UNINITIALIZED>;
}> = ({ meta: { action } }) => {
  const eventName = {
    [VideoCallStatus.CONNECTING]: GTMEventName.CONTACT_OPEN_CONTACT_VIDEO,
    [VideoCallStatus.CONNECTED]: GTMEventName.VIDEO_CONNECTED,
    [VideoCallStatus.BUSY]: GTMEventName.VIDEO_BUSY,
    [VideoCallStatus.START]: GTMEventName.CONTACT_START_VIDEO_CALL,
    [VideoCallStatus.STOP]: GTMEventName.CONTACT_CLOSE_VIDEO_CALL,
    [VideoCallStatus.STOP_BY_USER]: GTMEventName.VIDEO_USER_CLOSE_CALL,
    [VideoCallStatus.STOP_BY_AGENT]: GTMEventName.VIDEO_AGENT_CLOSE_CALL,
    [VideoCallStatus.ON_HOLD]: GTMEventName.VIDEO_AGENT_ON_HOLD,
    [VideoCallStatus.CALL_ENDED]: GTMEventName.VIDEO_CALL_ENDED,
  }[action];

  if (!eventName) return;

  const call_status = getCallStatus(action);

  if (!call_status) return { eventName, eventLabel: 'video_call' };

  return { eventName, eventLabel: 'video_call', call_status };
};
