import type { Dispatch, SetStateAction } from 'react';
import { useLocalstorageState } from 'rooks';

const noop = () => {};

/*
 * This hook is a wrapper around the useLocalstorageState hook from rooks.
 * The original hook was causing the website to crash when access to local storage was blocked (e.g., when cookies are disabled).
 * This wrapper catches such errors and returns a default value to prevent the website from crashing.
 */
export const useCustomLocalStorageState = <T>(
  key: string,
  initialState: T,
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  try {
    return useLocalstorageState(key, initialState);
  } catch (error) {
    console.warn('Error using local storage state');
    return [initialState, noop, noop];
  }
};
