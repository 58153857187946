import classnames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { Icon } from '@/components/icon/icon';
import { useSSU } from '@/context/ssu-provider';
import { IconName } from '@/types/icon';

import { GeoLocationState, useGeoLocation } from '../../hooks/use-geo-location';
import { useMap } from '../../hooks/use-map';

import css from './center-map-button.module.scss';

interface CenterMapButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const CenterMapButton: FC<PropsWithChildren<CenterMapButtonProps>> = ({
  className,
  ...props
}) => {
  const { config } = useSSU();
  const { centerMapToYouAreHereMarker } = useMap();
  const { state } = useGeoLocation({
    active: !!config?.orientation?.useGeoLocation,
  });
  const classNames = classnames(css.root, className);

  if (state !== GeoLocationState.GRANTED.toString()) {
    return null;
  }

  const onClick = () => {
    void centerMapToYouAreHereMarker();
  };

  return (
    <button {...props} className={classNames} onClick={onClick}>
      <Icon name={IconName.GPS} className={css.icon} />
    </button>
  );
};
