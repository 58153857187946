import classnames from 'classnames';
import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';
import css from './resizable-panel.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ResizablePanel = ({ children, className }: Props) => {
  const [ref, { height }] = useMeasure();
  const classNames = classnames(css.wrapper, className);

  return (
    <motion.div
      className={css.root}
      animate={{ height: height || 'auto' }}
      transition={{ type: 'spring', duration: 0.5 }}
    >
      <div className={classNames} ref={ref}>
        {children}
      </div>
    </motion.div>
  );
};
