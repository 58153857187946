import { useEffect, useState } from 'react';

export enum GeoLocationState {
  INIT = 'init',
  GRANTED = 'granted',
  DENIED = 'denied',
  UNAVAILABLE = 'unavailable',
}

interface UseGeoLocationProps {
  active?: boolean;
  onPositionUpdate?: PositionCallback;
  onInit?: PositionCallback;
}

export const useGeoLocation = ({
  active,
  onPositionUpdate,
  onInit,
}: UseGeoLocationProps = {}) => {
  const [state, setState] = useState<string>(GeoLocationState.INIT);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (!('geolocation' in navigator) || !('permissions' in navigator)) {
      setState(GeoLocationState.UNAVAILABLE);
    }

    navigator.geolocation.getCurrentPosition(
      (event) => {
        setState(GeoLocationState.GRANTED);
        onInit?.(event);
      },
      () => {
        setState(GeoLocationState.DENIED);
      },
    );

    const watchPositionId =
      onPositionUpdate && navigator.geolocation.watchPosition(onPositionUpdate);

    return () => {
      if (watchPositionId) {
        navigator.geolocation.clearWatch(watchPositionId);
      }
    };
  }, [active, onPositionUpdate, onInit]);

  return {
    state,
  };
};
