import merge from 'lodash/merge';
import type { UIEventName } from './events';
import type { Data, Meta } from './types/data-layer';

export interface DataLayerHistoryItem {
  event: UIEventName;
  meta: Meta;
}

export interface DataLayerCallbackProps<T = Meta, P = Data> {
  event: UIEventName;
  meta: T;
  data: P;
}

export type DataLayerPublishOptions = {
  once?: boolean;
};
export type DataLayerCallback = (props: DataLayerCallbackProps) => void;

function executeCallback(info: DataLayerCallbackProps, fn: DataLayerCallback) {
  try {
    fn(info);
  } catch (error) {
    console.warn('Error executing DataLayer callback', error);
  }
}

export class DataLayer {
  public data: Data;
  private callbacks: DataLayerCallback[] = [];
  private history: DataLayerHistoryItem[] = [];

  constructor(data: Data = {}) {
    this.data = data;
  }

  public update(data: Data): void {
    this.data = merge(this.data, data);
  }

  public subscribe(callback: DataLayerCallback): () => void {
    if (typeof callback !== 'function') {
      console.warn('Subscribe requires a function parameter');
      return () => undefined;
    }

    this.callbacks.push(callback);
    this.history.forEach(({ event, meta }) =>
      executeCallback({ event, meta, data: this.data }, callback),
    );

    // Return unsubscribe
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }

  public publish<T extends Meta>(
    event: UIEventName,
    meta: T,
    options?: DataLayerPublishOptions,
  ): void {
    if (options?.once && this.history.find((entry) => entry.event === event)) {
      return;
    }
    this.history.push({ event, meta });
    this.callbacks.forEach((callback) =>
      executeCallback({ event, meta, data: this.data }, callback),
    );
  }
}
