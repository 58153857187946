import classnames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import type { Flight } from 'pxp-flight-api-schema/types';
import { FlightInfoItemType } from 'pxp-strapi-types';

import { GateLabel } from '@/components/gate-label/gate-label';
import { usePassportNotification } from '@/hooks/use-passport-notification';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { useFlightTimes } from '@/modules/flight-info/hooks/use-flight-times';

import { BusGate } from '../../../flight-info/components/bus-gate/bus-gate';
import { DepartureGate } from '../../../flight-info/components/departure-gate/departure-gate';
import { DepartureTime } from '../../../flight-info/components/departure-time/departure-time';
import { FlightStatus } from '../../../flight-info/components/flight-status/flight-status';
import { InfoRow } from '../../../flight-info/components/info-row/info-row';
import { PassportControl } from '../../../flight-info/components/passport-control/passport-control';
import { useFlightInfoItems } from '../../../flight-info/hooks/use-flight-info-items';
import { useGetFloorIndication } from '../../hooks/use-get-floor-indication';
import { WayFindingType } from '../../store/types';
import type { Poi } from '../../types/poi';
import { ButtonRouting } from '../button-routing/button-routing';
import { FlightGateFloorIndication } from '../flight-gate-floor-indication/flight-gate-floor-indication';
import { FlightInfoAirlineLounge } from '../flight-info-airline-lounge/flight-info-airline-lounge';
import { TransferDesk } from '../transfer-desk/transfer-desk';

import css from './flight-info-detail-card.module.scss';

interface Props {
  flight: Flight;
  className?: string;
  poi?: Poi;
}

export const FlightInfoDetailCard = ({ flight, className, poi }: Props) => {
  const { t } = useTranslation();
  const { getBoardingTime } = useFlightTimes();
  const boardingTime = getBoardingTime(flight) ?? '-';
  const hasCodeShares = Boolean(flight.codeShares?.length);
  const classNames = classnames(css.root, className);
  const floorIndication = useGetFloorIndication(poi?.floor);
  const showPassportNotification = usePassportNotification(poi?.bubble?.id);

  const flightInfoItems = useFlightInfoItems({
    [FlightInfoItemType.BOARDING]: (
      <React.Fragment key={FlightInfoItemType.BOARDING}>
        <dt>{t('general.flightInfo.boardingTime')}</dt>
        <dd>{boardingTime}</dd>
      </React.Fragment>
    ),
    [FlightInfoItemType.DEPARTURE_TIME]: (
      <React.Fragment key={FlightInfoItemType.DEPARTURE_TIME}>
        <dt>{t('general.flightInfo.departureTime')}</dt>
        <dd>
          <DepartureTime flight={flight} />
        </dd>
      </React.Fragment>
    ),
    [FlightInfoItemType.DEPARTURE_HALL]: (
      <React.Fragment key={FlightInfoItemType.DEPARTURE_HALL}>
        <dt>
          {t('general.flightInfo.departures', { defaultValue: 'Departures' })}
        </dt>
        <dd>{flight.departure.terminalNumber}</dd>
      </React.Fragment>
    ),
    [FlightInfoItemType.TRANSFER]: flight.departure.transferDeskNumber ? (
      <>
        <dt>{t('general.transferDesk', { defaultValue: 'Transfer desk' })}</dt>
        <dd>
          <TransferDesk flight={flight} />
        </dd>
      </>
    ) : null,
    [FlightInfoItemType.GATE]: (
      <React.Fragment key={FlightInfoItemType.GATE}>
        <dt>{t('general.gate')}</dt>
        <dd>
          <FlightGateFloorIndication
            className={css.floorIndication}
            floorIndicationNumber={floorIndication?.floorIndicationNumber}
            floorNumber={floorIndication?.floorNumber}
          >
            <DepartureGate flight={flight} className={css.departureGate} />
          </FlightGateFloorIndication>
        </dd>
      </React.Fragment>
    ),
    [FlightInfoItemType.CHECK_IN]: flight.departure.checkInDeskNumber ? (
      <React.Fragment key={FlightInfoItemType.CHECK_IN}>
        <dt>
          {t('general.flightInfo.checkInDesk', {
            defaultValue: 'Check-in desk',
          })}
        </dt>
        <dd>
          <GateLabel gateNumber={flight.departure.checkInDeskNumber} />
        </dd>
      </React.Fragment>
    ) : null,
    [FlightInfoItemType.ROUTE]:
      typeof poi?.position.distanceInSeconds === 'number' ? (
        <React.Fragment key={FlightInfoItemType.ROUTE}>
          <dt>{t('general.route', { defaultValue: 'Route' })}</dt>
          <dd>
            <ButtonRouting
              poi={poi}
              wayFindingType={WayFindingType.GATE}
              wayFindingMeta={{
                flight,
              }}
            />
          </dd>
        </React.Fragment>
      ) : null,
  });

  return (
    <div
      className={classNames}
      {...getTestIdProp('flight-info-api-detail-card')}
    >
      <header className={css.header}>
        <h2 className={css.title}>
          <span className={css.flightNumber}>
            <Trans
              i18nKey="general.flightInfo.flightTo"
              shouldUnescape
              values={{
                airlineName: flight.airline.name,
                flightCode: flight.iataCode,
              }}
            />
          </span>{' '}
          <span className={css.destinationAirport}>
            {flight.arrival.airportName}
          </span>
        </h2>
        <FlightStatus flight={flight} />
      </header>
      <dl className={css.flightDetails}>{flightInfoItems}</dl>
      {(hasCodeShares ||
        flight.departure.loungeLocationId ||
        flight.departure.isBusGate ||
        showPassportNotification) && (
        <footer className={css.footer}>
          {hasCodeShares && (
            <InfoRow
              descriptionTerm={t('general.flightInfo.alsoKnownAs')}
              descriptionDetails={flight.codeShares
                ?.map((codeShare) => codeShare.iata)
                .join(', ')}
            />
          )}
          {flight.departure.loungeLocationId && (
            <FlightInfoAirlineLounge
              loungeLocationId={flight.departure.loungeLocationId}
            />
          )}

          {flight.departure.isBusGate && <BusGate />}

          {showPassportNotification && <PassportControl />}
        </footer>
      )}
    </div>
  );
};
