import {
  createContext,
  useContext,
  useEffect,
  useRef,
  type PropsWithChildren,
} from 'react';
import noop from 'lodash/noop';
import { DataLayer } from './data-layer';
import { initServices } from './init-services';

const defaultMethods = {
  publish: noop,
  subscribe: noop,
  update: noop,
} as DataLayer;

const DataLayerContext = createContext<DataLayer>(defaultMethods);

export function DataLayerProvider({ children }: PropsWithChildren) {
  const dataLayerRef = useRef<DataLayer>(new DataLayer());
  const init = useRef(false);

  useEffect(() => {
    if (init.current) return;

    init.current = true;

    initServices(dataLayerRef.current);
  }, []);

  return (
    <DataLayerContext.Provider value={dataLayerRef.current}>
      {children}
    </DataLayerContext.Provider>
  );
}

export function useDataLayer() {
  const context = useContext(DataLayerContext);

  if (!context) {
    throw new Error('useDataLayer must be used within a DataLayerProvider');
  }

  return context;
}
