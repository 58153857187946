import { useTranslation } from 'next-i18next';
import { Loader } from '../../../../components/loader/loader';
import css from './flight-info-detail-loading.module.scss';

export const FlightInfoDetailLoading = () => {
  const { t } = useTranslation();
  return (
    <div className={css.root}>
      <Loader className={css.loader} />
      <p>
        {t('general.flightInfo.loading', {
          defaultValue: 'One moment, we are looking up your flight details',
        })}
      </p>
    </div>
  );
};
