import classnames from 'classnames';
import delay from 'lodash/delay';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';

import { isPublic } from '@/lib/environment';

import { DEFAULT_LOCALE } from '../../constants/i18n';
import { clearCache } from '../../hooks/use-session-reset';
import { createSsuHref } from '../../lib/url';
import { ButtonBare } from '../button-bare/button';
import buttonCss from '../button-bare/button.module.scss';

import css from './error-fallback.module.scss';

interface ErrorFallbackProps {
  returnToHomeTimeout?: number;
}

const DEFAULT_TIMEOUT = 5000;

export const ErrorBareFallback: FC<ErrorFallbackProps> = ({
  returnToHomeTimeout = DEFAULT_TIMEOUT,
}) => {
  const router = useRouter();
  const homeButtonClassNames = classnames(
    buttonCss.root,
    buttonCss.variantRectangle,
    buttonCss.sizeMedium,
    css.button,
  );

  const reset = useCallback(async () => {
    await clearCache();
    window.location.href = createSsuHref({ ...router, locale: DEFAULT_LOCALE });
  }, [router]);

  useEffect(() => {
    if (!isPublic) {
      return;
    }

    const timer = delay(reset, returnToHomeTimeout);

    return () => {
      window.clearTimeout(timer);
    };
  }, [reset, returnToHomeTimeout]);

  return (
    <div className={css.root}>
      <h1>Sorry! Something went wrong.</h1>
      <ButtonBare
        label="Try again"
        onClick={() => void reset()}
        className={homeButtonClassNames}
      />
    </div>
  );
};
