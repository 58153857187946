export enum GTMEventName {
  PAGE_VIEW = 'page_view',

  CONTACT_CLOSE_CONTACT = 'contact_close_contact',
  CONTACT_START_VIDEO_CALL = 'contact_start_video_call',
  CONTACT_OPEN_CONTACT_OPTION = 'contact_open_contact_option',
  CONTACT_OPEN_CONTACT = 'contact_open_contact',
  CONTACT_CLOSE_VIDEO_CALL = 'contact_close_video_call',
  CONTACT_OPEN_CONTACT_VIDEO = 'contact_open_contact_video',

  VIDEO_CONNECTED = 'video_connected',
  VIDEO_BUSY = 'video_busy',
  VIDEO_USER_CAMERA_OFF = 'video_user_camera_off',
  VIDEO_USER_CAMERA_ON = 'video_user_camera_on',
  VIDEO_USER_CLOSE_CALL = 'video_user_close_call',
  VIDEO_AGENT_CLOSE_CALL = 'video_agent_close_call',
  VIDEO_AGENT_ON_HOLD = 'video_agent_on_hold',
  VIDEO_CALL_ENDED = 'video_call_ended',

  VIDEO_USER_OPEN_CHAT = 'video_user_open_chat',
  VIDEO_USER_CLOSE_CHAT = 'video_user_close_chat',

  ERROR_MESSAGE = 'error_error_message',
  CONTENT_OPEN_LINK = 'content_open_link',
  CONTENT_OPEN_QUESTION = 'content_open_question',

  FEEDBACK_SELECT_NEGATIVE = 'feedback_select_negative',
  FEEDBACK_SELECT_POSITIVE = 'feedback_select_positive',
  FEEDBACK_VIDEO_CALL = 'feedback_video_call',
  FEEDBACK_TRANSLATIONS = 'feedback_translations',

  // CSAT
  CSAT_SCORE = 'csat_score',
  CSAT_INPUT = 'csat_input',
  CSAT_IMPRESSION = 'csat_impression',

  FLIGHT_INFO_OPEN_WAY_FINDING = 'flight-info_open_wayfinding',
  FLIGHT_INFO_FLIGHT_DETAIL = 'flight-info_flight_detail',
  FLIGHT_INFO_ITINERARY = 'flight-info_itinerary',
  FLIGHT_INFO_ITINERARY_DISCOVER_POIS = 'flight-info_itinerary_discover_pois',
  FLIGHT_INFO_SHOW_ON_MAP = 'flight-info_show_on_map',
  FLIGHT_INFO_SEARCH = 'flight-info_search',
  FLIGHT_INFO_SCAN_BOARDING_PASS = 'flight-info_scan_boarding_pass',

  HOME_BUTTON = 'home_home_button',

  LANGUAGE_CLOSE = 'language_close_language',
  LANGUAGE_SELECT = 'language_select_language',
  LANGUAGE_OPEN = 'language_open_language',

  MAP_OPEN_CATEGORY = 'map_open_category',
  MAP_CLOSE_CATEGORY = 'map_close_category',
  MAP_OPEN_POI_DETAIL = 'map_open_pois_detail',
  MAP_SEARCH = 'map_search',
  MAP_OPEN_FEEDBACK = 'map_open_feedback',
  MAP_CLOSE_FEEDBACK = 'map_close_feedback',
  MAP_START_ROUTING = 'map_start_routing',
  MAP_STOP_ROUTING = 'map_stop_routing',
  MAP_SELECT_FLOOR = 'map_select_floor',

  SCAN_BOARDING_PASS = 'scan_boarding_pass',

  SESSION_END = 'session_end_session',

  QR_CODE_IMPRESSION_CSAT = 'qr_code_csat_impression',
  QR_CODE_IMPRESSION_MTM = 'qr_code_mtm_impression',
  QR_CODE_IMPRESSION_FAQ = 'qr_code_faq_impression',
  QR_CODE_IMPRESSION_VOUCHER = 'qr_code_voucher_impression',
  QR_CODE_IMPRESSION_ONWARD_TRAVEL = 'qr_code_onward_travel',
  QR_CODE_IMPRESSION_PROMOTION = 'qr_code_promotion_impression',
  QR_CODE_CONTACT_AIRLINE = 'qr_code_contact_airline',

  GLOBAL_SEARCH = 'global_search',
}
