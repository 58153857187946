import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { isIndexPage } from '../../lib/url';
import { selectIsOnline, useNetworkStore } from '../../store/networkStore';
import { Alert, AlertType } from '../alert/alert';
import css from './offline-message.module.scss';

const defaultValue =
  'Due to a technical issue, flight information and airport map are currently unavailable. Please check the flight screens or consult the physical airport map. Apologies for the inconvenience.';

export const OfflineMessage = () => {
  const router = useRouter();
  const isIndex = isIndexPage(router);
  const isOnline = useNetworkStore(selectIsOnline);
  const { t } = useTranslation();
  const classNames = classnames(css.root, isIndex && css.isIndexPage);

  if (isOnline) {
    return null;
  }

  return (
    <Alert
      className={classNames}
      type={AlertType.ERROR}
      text={t('general.offline.text', { defaultValue })}
    />
  );
};
