import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { isCancelled } from 'pxp-api/clients/flight-info-api/helpers/status';
import type { Flight } from 'pxp-flight-api-schema/types';

import { GateLabel, UnknownGate } from '@/components/gate-label/gate-label';
import { SrOnly } from '@/components/sr-only/sr-only';

import css from './arrival-gate.module.scss';

interface Props {
  flight: Flight;
  className?: string;
}

export const ArrivalGate = ({ flight, className }: Props) => {
  const { t } = useTranslation();
  return (
    <span className={classNames(css.root, className)}>
      {flight.arrival.terminalNumber && !isCancelled(flight) ? (
        <GateLabel
          flightStatus={flight.status}
          gateNumber={flight.arrival.terminalNumber}
        />
      ) : (
        <UnknownGate>
          <SrOnly>{t('general.gateUnknown')}</SrOnly>
          <span aria-hidden>-</span>
        </UnknownGate>
      )}
    </span>
  );
};
