import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'rooks';
import { useOutsideClick } from 'pxp-utils/hooks/use-outside-click';

import { SrOnly } from '@/components/sr-only/sr-only';
import { ElementSelector } from '@/enums/general';
import { QueryParam, Route } from '@/enums/route';
import { useHistory } from '@/hooks/use-history';
import { cleanQueryParams } from '@/lib/url';
import { Modal, selectIsOpen, useModalStore } from '@/store/modalStore';

import { useMap } from '../../hooks/use-map';
import type { UseMapSearchParameters } from '../../hooks/use-map-search';
import { MapSearchFilters } from '../map-search-filters/map-search-filters';
import { MapSearchInput } from '../map-search-input/map-search-input';
import { MapSearchResultsContainer } from '../map-search-results-container/map-search-results-container';
import { MapSearchWrapper } from '../map-search-wrapper/map-search-wrapper';

import css from './map-search.module.scss';

export const MapSearch = () => {
  const { query, pathname, replace } = useRouter();
  const { previousUrl } = useHistory();
  const { i18n, t } = useTranslation();
  const { resetView, resetSearch } = useMap();

  const prevPath = useRef('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const afterWrapperRef = useRef<HTMLDivElement | null>(null);
  const keyboardRef = useRef<HTMLElement | null>(null);
  const isSessionModalOpen = useModalStore(selectIsOpen(Modal.SESSION_TIMEOUT));

  const memoizedReplace = useCallback(replace, [replace]);

  const initialQueryValue = query[QueryParam.SEARCH_QUERY]
    ? String(query[QueryParam.SEARCH_QUERY])
    : '';
  const initialActiveCategory = query[QueryParam.CATEGORY]
    ? String(query[QueryParam.CATEGORY])
    : '';
  const initialSearchOpen =
    (previousUrl?.includes(QueryParam.POI_ID) &&
      previousUrl?.includes(QueryParam.FROM)) || // From Poi Detail
    Boolean(initialActiveCategory) ||
    Boolean(initialQueryValue);

  const [searchQuery, setSearchQuery] = useState(initialQueryValue);
  const [activeCategory, setActiveCategory] = useState(initialActiveCategory);
  const setActiveCategoryDebounced = useDebounce(setActiveCategory, 500);
  const [isSearchOpen, setSearchIsOpen] = useState(initialSearchOpen);
  const [showResults, setShowResults] = useState(initialSearchOpen);

  const searchParams = useMemo(
    () =>
      ({
        query: searchQuery,
        filters: {
          category: activeCategory,
        },
        locale: i18n.language,
      }) satisfies UseMapSearchParameters,
    [activeCategory, i18n.language, searchQuery],
  );

  const classNames = classnames(isSearchOpen ? css.searchIsOpen : null);

  const closeSearch = useCallback(() => {
    if (isSearchOpen) {
      setSearchIsOpen(false);
      void replace(
        {
          pathname,
          query: cleanQueryParams({
            ...query,
            [QueryParam.FROM]: undefined,
          }),
        },
        undefined,
        { shallow: true },
      );

      // return focus to the first element after the search wrapper so that keyboard navigation is not interrupted
      afterWrapperRef.current?.focus();
    }
  }, [isSearchOpen, pathname, query, replace]);

  const openSearch = () => {
    if (!isSearchOpen) {
      setSearchIsOpen(true);
    }
  };

  const onOpened = () => {
    setShowResults(true);
  };

  const onClosed = () => {
    setShowResults(false);
  };

  const handleCancelSearch = useCallback(() => {
    closeSearch();
    setSearchQuery('');
    setActiveCategory('');
    resetSearch();

    void resetView({ clearMarkers: true });

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [closeSearch, resetSearch, resetView]);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeSearch();
      }
    },
    [closeSearch],
  );

  useEffect(() => {
    const parameters = JSON.stringify(searchParams);

    if (prevPath.current !== parameters) {
      prevPath.current = parameters;

      const newQuery = cleanQueryParams({
        ...query,
        [QueryParam.CATEGORY]: searchParams.filters.category,
        [QueryParam.SEARCH_QUERY]: searchParams.query,
      });

      memoizedReplace(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true },
      );
    }
  }, [searchParams, memoizedReplace, pathname, query]);

  useEffect(() => {
    void resetView({
      clearMarkers: true,
      shouldGoToSsuLocation: !previousUrl?.includes(Route.MAP),
    });
  }, [previousUrl, resetView]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    i18n.on('languageChanged', handleCancelSearch);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      i18n.off('languageChanged', handleCancelSearch);
    };
  }, [handleCancelSearch, i18n, handleKeyUp]);

  useEffect(() => {
    keyboardRef.current = document.querySelector(
      `[data-selector=${ElementSelector.KEYBOARD}]`,
    );
  }, []);

  useOutsideClick([wrapperRef, keyboardRef], () => {
    if (!isSessionModalOpen) {
      closeSearch();
    }
  });

  return (
    <>
      <MapSearchWrapper
        className={classNames}
        isOpen={isSearchOpen}
        ref={wrapperRef}
        onAfterOpen={onOpened}
        onClose={onClosed}
      >
        <SrOnly>
          <header>
            <h1>
              {t('general.map.search.title', {
                defaultValue: 'Search for a location on the map',
              })}
            </h1>
          </header>
        </SrOnly>
        <MapSearchInput
          className={css.searchInput}
          onSearchOpen={openSearch}
          onChangeSearchInput={setSearchQuery}
          searchIsOpen={isSearchOpen}
          inputRef={inputRef}
          defaultValue={initialQueryValue}
          onCancelSearch={handleCancelSearch}
        />
        <MapSearchFilters
          className={css.searchCategories}
          onChange={setActiveCategoryDebounced}
          activeFilter={activeCategory}
          onSearchOpen={openSearch}
        />
        {showResults && isSearchOpen && (
          <MapSearchResultsContainer {...searchParams} />
        )}
      </MapSearchWrapper>
      <div tabIndex={-1} ref={afterWrapperRef} />
    </>
  );
};
