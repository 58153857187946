import { useRouter } from 'next/router';
import { cleanQueryParams } from '../../../../lib/url';
import { QueryParam } from '../../../../enums/route';
import { Event } from '../../enums/events';
import { useGetPoiIds } from '../../hooks/use-get-poi-ids';
import { useMap } from '../../hooks/use-map';
import { useMapEvent } from '../../hooks/use-map-event';

export const MouseEventHandler = () => {
  const { query, pathname, push } = useRouter();
  const { getPoiIdFromMapMouseEvent } = useMap();
  const poiIds = useGetPoiIds();

  const handleMouseEvent = async (event: any) => {
    const id = getPoiIdFromMapMouseEvent(event);

    if (!id || !poiIds.includes(id)) {
      return;
    }

    void push(
      {
        pathname,
        query: cleanQueryParams({
          ...query,
          [QueryParam.POI_ID]: id,
          [QueryParam.FROM]: undefined,
        }),
      },
      undefined,
      { shallow: true },
    );
  };

  useMapEvent(Event.MOUSE_UP, handleMouseEvent);

  return null;
};
