import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC, HTMLAttributes } from 'react';

import { IconRound } from '@/components/icon-round/icon-round';
import { useDefaultLocaleTranslation } from '@/hooks/use-default-locale-translation';
import type { IconName } from '@/types/icon';

import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import type { MapFilter } from '../../../analytics/types/event-props';
import { MapFilterAction } from '../../../analytics/types/event-props';

import css from './map-search-filter.module.scss';

export interface MapSearchFilterProps
  extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  className?: string;
  onChange: (value: string) => void;
  icon: IconName;
  label: string;
  value: string;
  isActive: boolean;
}

export const MapSearchFilter: FC<MapSearchFilterProps> = ({
  className,
  onChange,
  isActive,
  label,
  icon,
  value,
  ...rest
}) => {
  const isActiveClassName = isActive ? css.isActive : null;
  const { t } = useTranslation();
  const fixedT = useDefaultLocaleTranslation();
  const dataLayer = useDataLayer();

  const handleOnChange = (value: string) => {
    dataLayer.publish<MapFilter>(UIEventName.MAP_FILTER, {
      // @ts-expect-error: label is dynamic
      filter: fixedT(label),
      action: !isActive ? MapFilterAction.SELECT : MapFilterAction.DESELECT,
    });
    onChange(value);
  };

  return (
    <label
      className={classnames(css.root, className, isActiveClassName)}
      htmlFor={value}
      {...rest}
    >
      <input
        className={css.input}
        defaultChecked={isActive}
        id={value}
        name="map-filter"
        onChange={() => handleOnChange(isActive ? '' : value)}
        type="checkbox"
        value={value}
      />
      <IconRound className={css.icon} name={icon} />
      {/*
      // @ts-expect-error: label is dynamic */}
      {t(label)}
    </label>
  );
};
