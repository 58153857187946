export enum UIEventName {
  START = 'START',
  PAGE_VIEW = 'PAGE_VIEW',
  LINK_CLICK = 'LINK_CLICK',
  BUTTON_CLICK = 'BUTTON_CLICK',
  ERROR = 'ERROR',
  MODAL = 'MODAL',

  // Feedback
  FEEDBACK_COMPONENT_SUBMIT = 'FEEDBACK_COMPONENT_SUBMIT',
  FEEDBACK_VIDEO_CALL_SUBMIT = 'FEEDBACK_VIDEO_CALL_SUBMIT',
  FEEDBACK_TRANSLATIONS_SUBMIT = 'FEEDBACK_TRANSLATIONS_SUBMIT',
  FEEDBACK_CSAT_SUBMIT = 'FEEDBACK_CSAT_SUBMIT',
  FEEDBACK_CSAT_IMPRESSION = 'FEEDBACK_CSAT_IMPRESSION',

  CHAT = 'CHAT',

  AUDIO_VIDEO = 'AUDIO_VIDEO',

  // Video call
  VIDEO_CALL = 'VIDEO_CALL',

  // Flight info
  FLIGHT_INFO_SEARCH = 'FLIGHT_INFO_SEARCH',
  FLIGHT_INFO_DETAIL = 'FLIGHT_INFO_DETAIL',
  FLIGHT_INFO_ACTION = 'FLIGHT_INFO_ACTION',
  FLIGHT_INFO_AIRLINE_LOUNGE = 'FLIGHT_INFO_AIRLINE_LOUNGE',

  // FAQ
  FAQ_OPEN = 'FAQ_OPEN',

  // Map
  MAP_FILTER = 'MAP_FILTER',
  MAP_SEARCH = 'MAP_SEARCH',
  MAP_POI_DETAIL = 'MAP_POI_DETAIL',
  MAP_ROUTING = 'MAP_ROUTING',
  MAP_SELECT_FLOOR = 'MAP_SELECT_FLOOR',

  // Boarding pass
  BOARDING_PASS_SCAN = 'BOARDING_PASS_SCAN',

  // Contact
  CONTACT_SELECT = 'CONTACT_SELECT',

  // Language
  LANGUAGE_SELECT = 'LANGUAGE_SELECT',

  // Session
  SESSION_END = 'SESSION_END',

  // QR Code
  QR_CODE_IMPRESSION = 'QR_CODE_IMPRESSION',
  QR_CODE_VOUCHER_IMPRESSION = 'QR_CODE_VOUCHER_IMPRESSION',

  // Search
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
}
