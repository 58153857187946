import React from 'react';
import classNames from 'classnames';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import css from './scroll-area.module.scss';

interface Props {
  children: React.ReactNode;
  orientation?: 'vertical' | 'horizontal' | 'auto';
  hideScrollbar?: boolean;
  type?: 'auto' | 'always' | 'scroll' | 'hover';
  className?: string;
  withBackground?: boolean;
}

export const ScrollArea = ({
  children,
  orientation = 'vertical',
  hideScrollbar,
  type,
  className,
  withBackground,
}: Props) => {
  const dualScrollbar = orientation === 'auto';

  return (
    <ScrollAreaPrimitive.Root
      type={type}
      className={classNames(css.root, className)}
    >
      <ScrollAreaPrimitive.Viewport>{children}</ScrollAreaPrimitive.Viewport>
      <ScrollAreaPrimitive.Scrollbar
        className={classNames(css.scrollBar, {
          [css.hidden]: hideScrollbar,
          [css.scrollBarBackground]: withBackground,
        })}
        orientation={dualScrollbar ? 'horizontal' : orientation}
      >
        {!hideScrollbar && <ScrollAreaPrimitive.Thumb className={css.thumb} />}
      </ScrollAreaPrimitive.Scrollbar>
      {dualScrollbar && (
        <>
          <ScrollAreaPrimitive.Scrollbar
            className={classNames(css.scrollBar, {
              [css.hidden]: hideScrollbar,
              [css.scrollBarBackground]: withBackground,
            })}
            orientation="vertical"
          >
            {!hideScrollbar && (
              <ScrollAreaPrimitive.Thumb className={css.thumb} />
            )}
          </ScrollAreaPrimitive.Scrollbar>
          <ScrollAreaPrimitive.Corner
            className={classNames({
              [css.scrollBarBackground]: withBackground,
            })}
          />
        </>
      )}
    </ScrollAreaPrimitive.Root>
  );
};
