import { ChatStatus } from '../../../../../types/component';
import type { Chat } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapChat: Mapper<Chat> = ({ meta: { action } }) => {
  const eventName = {
    [ChatStatus.OPEN]: GTMEventName.VIDEO_USER_OPEN_CHAT,
    [ChatStatus.CLOSE]: GTMEventName.VIDEO_USER_CLOSE_CHAT,
  }[action];

  if (!eventName) return;

  return { eventName };
};
