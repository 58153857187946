import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useHistory } from '../../hooks/use-history';

export const SsuHistory = () => {
  const {
    list: [first],
    push,
  } = useHistory();
  const { events, locale } = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const path =
        locale && url.startsWith(`/${locale}`)
          ? url.replace(`/${locale}`, '')
          : url;
      if (first !== path) {
        push(path);
      }
    };

    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [first, locale, events, push]);

  return null;
};
