import dynamic from 'next/dynamic';
import type { ComponentType, FC } from 'react';

import { useSSU } from '@/context/ssu-provider';

const fontsMap: Record<string, ComponentType | undefined> = {
  Schiphol: dynamic(() => import('./fonts/schiphol/schiphol')),
  Hamad: dynamic(() => import('./fonts/hamad/hamad')),
};

export const FontProvider: FC = () => {
  const { theme } = useSSU();
  const Wrapper = fontsMap[theme?.font?.family || 'Schiphol'];

  if (!Wrapper) {
    return null;
  }

  return <Wrapper />;
};
