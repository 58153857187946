import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type { AdBanner } from '@/types/content-schema';

import css from './banner.module.scss';

type Props = NonNullable<AdBanner['properties']>;

export const Banner = ({
  link,
  smallBanner,
  largeBanner,
  gtmLabel,
  imageAlt = '',
}: Props) => {
  const dataLayer = useDataLayer();
  const { t } = useTranslation();
  const imageAltText: string = t(imageAlt, { defaultValue: 'Add banner' });

  const onClick = () => {
    dataLayer.publish(UIEventName.BUTTON_CLICK, {
      value: gtmLabel || 'ad-banner',
      contentType: 'banner',
    });
  };
  return (
    <>
      <div className={css.placeholder} />
      <div className={css.root}>
        <a
          className={css.link}
          href={link}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
        >
          {smallBanner?.url && (
            <Image
              className={css.smallBanner}
              src={smallBanner.url}
              alt={imageAltText}
              width={smallBanner.width}
              height={smallBanner.height}
            />
          )}
          {largeBanner?.url && (
            <Image
              className={css.largeBanner}
              src={largeBanner.url}
              alt={imageAltText}
              width={largeBanner.width}
              height={largeBanner.height}
            />
          )}
        </a>
      </div>
    </>
  );
};
