export const env = (name: string) => {
  if (!process.env[name]) {
    throw new Error(`Missing environment variable ${name}`);
  }

  return process.env[name] as string;
};

export const envMap = {
  dev: 'development',
  test: 'testing',
  acc: 'acceptance',
  prd: 'production',
} as const;

export type Env = keyof typeof envMap;

const allowedEnvs = Object.keys(envMap) as Env[];

/**
 * @param appEnv String containing both application environment and IATA code. E.g. test-ams, acc-doh
 */
export function getEnvironmentFromAppEnv(appEnv: string | undefined) {
  if (!appEnv) return envMap.dev;

  const envMatch = appEnv.match(/test|acc|prd|dev/i);

  if (!envMatch) return envMap.dev;

  const [env] = envMatch as [Env];

  if (!allowedEnvs.includes(env)) return envMap.dev;

  return envMap[env];
}
