import { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { IconName } from '../../../../types/icon';
import { Icon } from '../../../../components/icon/icon';
import css from './map-search-no-results.module.scss';

export interface MapSearchNoResultsProps {
  className?: string;
}

export const MapSearchNoResults: FC<MapSearchNoResultsProps> = ({
  className,
}) => {
  const classNames = classnames(css.root, className);
  const { t } = useTranslation();
  return (
    <div className={classNames}>
      <Icon className={css.icon} name={IconName.ERROR} />
      <p className={css.message}>{t('general.map.search.noResults')}</p>
    </div>
  );
};
