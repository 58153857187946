import { FC } from 'react';

import classnames from 'classnames';

import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { Availability } from 'pxp-content-api-schema/types';

import { dateIsInHourRange } from '../../../../lib/date';

import { Poi } from '../../../../types/content-schema';
import css from './opening-times.module.scss';

export interface OpeningTimesProps {
  className?: string;
  openingTimes?: Poi['openingTimes'];
}

const getIsOpen24Hours = (openingTimes: Poi['openingTimes']): boolean => {
  return (
    !openingTimes ||
    Object.keys(openingTimes).every(
      (dayOfTheWeek) => openingTimes[dayOfTheWeek] === true,
    )
  );
};

const getHourLabel = (
  isOpen24Hours: boolean,
  openingTimeToday: Availability,
): string => {
  if (isOpen24Hours) {
    return '24/7';
  }
  if (typeof openingTimeToday === 'boolean') {
    return '';
  }

  return `${openingTimeToday.fromTime} - ${openingTimeToday.tillTime}`;
};

export const OpeningTimes: FC<OpeningTimesProps> = ({
  openingTimes = {},
  className,
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const dayOfTheWeek = format(currentDate, 'EEEE');
  const openingTimeToday = openingTimes[dayOfTheWeek];
  const isOpen =
    typeof openingTimeToday === 'boolean'
      ? openingTimeToday
      : dateIsInHourRange(
          currentDate,
          openingTimeToday.fromTime,
          openingTimeToday.tillTime,
        );
  const isOpen24Hours = getIsOpen24Hours(openingTimes);
  const hourLabel = getHourLabel(isOpen24Hours, openingTimeToday);

  return (
    <div className={classnames(className)}>
      {isOpen && <span className={css.open}>{t('general.poi.open')}</span>}{' '}
      {!isOpen && <span className={css.closed}>{t('general.poi.closed')}</span>}{' '}
      <span className={css.hours}>{hourLabel}</span>
    </div>
  );
};
