import { FC, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { cleanQueryParams } from '../../../../lib/url';

import { QueryParam, Route } from '../../../../enums/route';
import { useOutsideDrag } from '../../../../hooks/use-outside-drag';
import { useQueryParam } from '../../../../hooks/use-query-param';
import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import { MapPoiDetail as MapPoiDetailProps } from '../../../analytics/types/event-props';
import { useGetPoiById } from '../../hooks/use-get-poi-by-id';
import { useMap } from '../../hooks/use-map';
import { useDetailCardStore } from '../../store/detailCardStore';
import { useFlightCardStore } from '../../store/flightCardStore';
import { MapPoiDetailCard } from '../map-poi-detail-card/map-poi-detail-card';
import css from './map-poi-detail.module.scss';

export const MapPoiDetail: FC = () => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const poiId = String(useQueryParam(QueryParam.POI_ID));
  const { query, push, pathname } = useRouter();
  const shouldGoBack = query[QueryParam.FROM] === Route.MAP_SEARCH;
  const { goToPoi, setDetailCardCollapsed, stopRoute } = useMap();
  const isCollapsed = useDetailCardStore((state) => state.isCollapsed);
  const { isCollapsed: isFlightCardCollapsed } = useFlightCardStore();
  const dataLayer = useDataLayer();

  const { data: poi } = useGetPoiById(poiId);

  useEffect(() => {
    if (poi) {
      goToPoi(poi.id);
    }
  }, [goToPoi, poi]);

  useEffect(() => {
    if (poi?.name) {
      dataLayer.publish<MapPoiDetailProps>(UIEventName.MAP_POI_DETAIL, {
        poi: poi.name,
        searchQuery: query[QueryParam.SEARCH_QUERY]
          ? String(query[QueryParam.SEARCH_QUERY])
          : undefined,
      });
    }
  }, [dataLayer, poi, query]);

  useEffect(() => {
    if (!isFlightCardCollapsed) {
      setDetailCardCollapsed(true);
    }
  }, [isFlightCardCollapsed, setDetailCardCollapsed]);

  useOutsideDrag(cardRef, () => {
    setDetailCardCollapsed(true);
  });

  if (!poi) {
    return null;
  }

  const onClose = async () => {
    await stopRoute();
    setDetailCardCollapsed(true);

    void push({
      pathname,
      query: cleanQueryParams({
        ...query,
        [QueryParam.POI_ID]: undefined,
        [QueryParam.FROM]: undefined,
      }),
    });
  };

  return (
    <MapPoiDetailCard
      ref={cardRef}
      className={css.root}
      poi={poi}
      onClose={onClose}
      showBackButton={shouldGoBack}
      isCollapsed={isCollapsed}
      setDetailCardCollapsed={setDetailCardCollapsed}
    />
  );
};
