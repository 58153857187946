import { useMemo } from 'react';
import useSWR from 'swr';
import { useMap } from './use-map';
import { useMapLocale } from './use-map-locale';

export const useGetPoiIds = (): string[] => {
  const { getPois } = useMap();
  const locale = useMapLocale();
  const { data: pois = [] } = useSWR(`getPois-${locale}`, () =>
    getPois({ locale }),
  );

  return useMemo(
    () =>
      pois?.reduce((out: string[], poi) => {
        return [...out, poi.id];
      }, []),
    [pois, locale],
  );
};
