import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { useToast } from '../../hooks/use-toast';

import { Toast } from './toast';
import css from './toast.module.scss';

export const ToastContainer = () => {
  const { toast, dismissToast } = useToast();

  return (
    <AnimatePresence>
      {toast && (
        <div
          className={classNames(
            css.toastContainer,
            css[toast.options.position],
          )}
        >
          <Toast
            position={toast.options.position}
            type={toast.type}
            onClose={dismissToast}
          >
            {toast.message}
          </Toast>
        </div>
      )}
    </AnimatePresence>
  );
};
