import type { MapFloor } from 'pxp-api/clients/strapi/config-type';
import type { Flight } from 'pxp-flight-api-schema/types';

import type { Modal } from '../../../store/modalStore';
import type {
  AudioVideoStatus,
  ChatStatus,
  VideoCallStatus,
} from '../../../types/component';

export enum ModalAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export enum QRCodeImpressionType {
  MTM = 'MTM',
  CSAT = 'CSAT',
  VOUCHER = 'VOUCHER',
  ONWARD_TRAVEL = 'ONWARD_TRAVEL',
  CONTACT_AIRLINE = 'CONTACT_AIRLINE',
  PROMOTION = 'PROMOTION',
  FAQ = 'FAQ',
}

export enum ContentType {
  QUESTION = 'question',
  TOPIC = 'topic',
}

export interface ModalEvent {
  type: Modal;
  action: ModalAction;
}

export interface DefaultMeta {
  value: string;
}

export interface ButtonClick {
  contentType?: string;
  value: string;
  target?: string;
}

export interface FightInfoSearch {
  query: string;
}

export enum FlightInfoDetailDisplayType {
  ITINERARY = 'ITINERARY',
  CARD = 'CARD',
}

export interface FlightInfoDetail {
  flight: Flight;
  displayType?: FlightInfoDetailDisplayType;
}

export enum FlightInfoActionType {
  CLICK_SHOW_ON_MAP = 'CLICK_SHOW_ON_MAP',
  CLICK_DISCOVER_POIS = 'CLICK_DISCOVER_POIS',
}

export interface FlightInfoAction {
  flight: Flight;
  type: FlightInfoActionType;
  data: string;
}

export interface MapPoiDetail {
  poi: string;
  searchQuery?: string;
}

export interface MapSearch {
  query: string;
}

export interface MapFloorSelect {
  floor: MapFloor;
}

export enum MapFilterAction {
  SELECT = 'SELECT',
  DESELECT = 'DESELECT',
}

export interface MapFilter {
  filter: string;
  action: MapFilterAction;
}

export enum MapRoutingAction {
  START = 'START',
  STOP = 'STOP',
}

export interface MapRouting {
  action: MapRoutingAction;
  poiName: string;
}

export interface Chat {
  action: ChatStatus;
}

export interface VideoCall {
  action: VideoCallStatus;
}

export interface AudioVideo {
  action: AudioVideoStatus;
}

export interface Feedback {
  value: string | number;
  slug: string;
  contentType?: ContentType;
  label?: string;
}

export interface FeedbackCsat {
  value?: number;
  feedback?: string;
}

export interface QRCodeImpression {
  type: QRCodeImpressionType;
  value?: string;
}

export interface GlobalSearch {
  query: string;
}
