import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { ExternalLink } from '@/components/external-link/external-link';
import { useMoveToMobileUrl } from '@/modules/move-to-mobile/hooks/useMoveToMobileUrl';

import { QueryParam } from '../../../../enums/route';
import { QRCodeImpressionType } from '../../../analytics/types/event-props';
import { FeedbackReceivedTitle } from '../feedback-received-title/feedback-received-title';

import css from './csat-to-mobile-message.module.scss';

interface CsatToMobileMessageProps {
  value: number;
}

export const CsatToMobileMessage: FC<CsatToMobileMessageProps> = ({
  value,
}) => {
  const { t } = useTranslation();

  const externalLinkLabel = t('general.csat.feedback.continueSurvey', {
    defaultValue: 'Continue the survey',
  });

  const externalLinkModalTitle = t('general.csat.feedback.modalTitle', {
    defaultValue:
      'Scan the QR code to continue the survey on your mobile device',
  });

  const queryParams = {
    [QueryParam.UTM_CAMPAIGN]: 'csat_qr_code',
    [QueryParam.CSAT_SCORE]: value.toString(),
  };

  const impressionType = QRCodeImpressionType.CSAT;

  const qrCodeUrl = useMoveToMobileUrl({ queryParams });

  return (
    <div className={css.root}>
      <div className={css.body}>
        <FeedbackReceivedTitle />
        <p className={css.text}>
          {t('general.csat.feedback.scanQrCode', {
            defaultValue:
              'Share your suggestions to help us improve our services by scanning the QR code.',
          })}
        </p>
        <ExternalLink
          label={externalLinkLabel}
          value={qrCodeUrl}
          modalTitle={externalLinkModalTitle}
          qrCodeImpressionType={impressionType}
          isButtonStyle
        />
      </div>
    </div>
  );
};
