import { useEffect } from 'react';

import { startVideoCall } from './actions/start-video-call';

type ShortcutAction = 'START_VIDEO_CALL';
export type EventMap = Record<string, ShortcutAction>;
export interface Props {
  eventMap: EventMap;
}

const shortcutActionMap: Record<ShortcutAction, () => void> = {
  START_VIDEO_CALL: startVideoCall,
};

const makeShortcutHandler = (eventMap: EventMap) => (e: KeyboardEvent) => {
  if (!Object.keys(eventMap).includes(e.code)) {
    return;
  }
  const shortcutEvent = eventMap[e.code];
  shortcutActionMap[shortcutEvent]();
};

/**
 * Renderless component to handle global app shortcuts.
 * Currently only supports single key shortcuts.
 *
 * Shortcuts actions are defined under ./actions/
 */
export const GlobalShortcutsEffects = ({ eventMap }: Props) => {
  useEffect(() => {
    const shortcutHandler = makeShortcutHandler(eventMap);
    window.addEventListener('keydown', shortcutHandler);
    return () => {
      window.removeEventListener('keydown', shortcutHandler);
    };
  }, [eventMap]);

  return null;
};
