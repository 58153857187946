import { useTranslation } from 'next-i18next';
import type { Flight } from 'pxp-flight-api-schema/types';
import type { SWRConfiguration } from 'swr';
import useSWR, { useSWRConfig } from 'swr';

import { FlightDirection } from '@/modules/flight-info/types/service';
import type { ResponseError } from '@/types/http';

import { useFlightInfo } from './use-flight-info';

interface Props {
  flightId: string;
  flightDirection?: FlightDirection;
  options?: SWRConfiguration;
}

export const useGetFlightById = ({
  flightId,
  flightDirection = FlightDirection.DEPARTURE,
  options,
}: Props) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { mutate, cache } = useSWRConfig();
  const { getById } = useFlightInfo();
  const queryString = `${flightId}${language}`;

  const retry = () => {
    cache.delete(queryString);
    void mutate(queryString);
  };

  return {
    ...useSWR<Flight | undefined, ResponseError>(
      queryString,
      () => getById(flightId, flightDirection),
      options,
    ),
    retry,
  };
};
