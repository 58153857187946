import classnames from 'classnames';
import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';

import type { ToastPosition, ToastType } from '@/context/toast-context';

import { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';
import { SrOnly } from '../sr-only/sr-only';

import css from './toast.module.scss';

const animationPositionMapping = {
  bottom: '120%',
  top: '-120%',
};

interface Props {
  position: ToastPosition;
  type: ToastType;
  onClose?: () => void;
}

export const Toast = ({
  children,
  position,
  type,
  onClose,
}: PropsWithChildren<Props>) => {
  const isDanger = type === 'danger';
  const iconName: IconName = isDanger ? IconName.ATTENTION : IconName.VALID;

  return (
    <motion.div
      className={classnames(css.toast, isDanger && css.danger)}
      initial={{ y: animationPositionMapping[position] }}
      animate={{ y: 0 }}
      exit={{ y: animationPositionMapping[position] }}
      role="status"
    >
      <Icon aria-hidden="true" name={iconName} className={css.icon} />
      {children}
      <button className={css.closeButton} onClick={onClose}>
        <Icon aria-hidden="true" name={IconName.CLOSE} />
        <SrOnly>Close status</SrOnly>
      </button>
    </motion.div>
  );
};
