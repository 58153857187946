export enum Module {
  CONTACT = 'contact',
  MAP = 'map',
  FLIGHT_INFO = 'flightInfo',
  MOVE_TO_MOBILE = 'moveToMobile',
  CSAT = 'csat',
  VIDEO_CALL = 'videoCall',
  AD_BANNER = 'adBanner',
  SECURITY_FILTER = 'securityFilter',
  SHORT_CONNECTION = 'shortConnection',
  PROMOTIONS = 'promotions',
}
