import type { FC } from 'react';
import { useEffect } from 'react';
import classnames from 'classnames';
import delay from 'lodash/delay';
import { useTranslation } from 'next-i18next';
import { useSessionReset } from '../../hooks/use-session-reset';
import { HeaderOnlyLayout } from '../../layouts/headerOnlyLayout';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';
import type { DefaultMeta } from '../../modules/analytics/types/event-props';
import { ButtonBare } from '../button-bare/button';
import buttonCss from '../button-bare/button.module.scss';
import { CssVarsProvider } from '../css-vars-provider/css-vars-provider';
import css from './error-fallback.module.scss';

interface ErrorFallbackProps {
  returnToHomeTimeout?: number;
}

const DEFAULT_TIMEOUT = 5000;

export const ErrorFallback: FC<ErrorFallbackProps> = ({
  returnToHomeTimeout = DEFAULT_TIMEOUT,
}) => {
  const { t } = useTranslation();
  const dataLayer = useDataLayer();
  const { reset } = useSessionReset();
  const homeButtonClassNames = classnames(
    buttonCss.root,
    buttonCss.variantRectangle,
    buttonCss.sizeMedium,
    css.button,
  );

  useEffect(() => {
    const timer = delay(reset, returnToHomeTimeout);

    return () => {
      window.clearTimeout(timer);
    };
  }, [reset, returnToHomeTimeout]);

  useEffect(() => {
    dataLayer.publish<DefaultMeta>(UIEventName.ERROR, {
      value: 'Client side error occurred',
    });
  });

  return (
    <HeaderOnlyLayout>
      <CssVarsProvider />
      <div className={css.root}>
        <h1>{t('general.error.boundary.title')}</h1>
        <p>
          {t('general.error.boundary.text1')}
          <br />
          {t('general.error.boundary.text2')}
        </p>
        <p>{t('general.error.boundary.text2')}</p>
        <ButtonBare
          label={t('general.error.boundary.text2')}
          onClick={() => void reset()}
          className={homeButtonClassNames}
        />
      </div>
    </HeaderOnlyLayout>
  );
};
