import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { CurrentFloorStore } from './types';

export const useCurrentFloorStore = createWithEqualityFn<CurrentFloorStore>()(
  devtools(
    immer((set) => ({
      currentFloor: undefined,
      setCurrentFloor: (floor) =>
        set((state) => {
          state.currentFloor = floor;
        }),
    })),
  ),
);
