import type { FC, ReactNode } from 'react';
import classnames from 'classnames';
import css from './info-row.module.scss';

interface InfoRowProps {
  className?: string;
  descriptionTerm?: ReactNode;
  descriptionDetails?: ReactNode;
  children?: ReactNode;
}

export const InfoRow: FC<InfoRowProps> = ({
  className,
  descriptionTerm,
  descriptionDetails,
  children,
}) => {
  const classNames = classnames(className, css.root);
  return (
    <div className={classNames}>
      {descriptionTerm && (
        <dl className={css.descriptionList}>
          <dt className={css.descriptionTerm}>{descriptionTerm}</dt>
          <dd className={css.descriptionDetails}>{descriptionDetails}</dd>
        </dl>
      )}
      {children}
    </div>
  );
};
