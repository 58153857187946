import classnames from 'classnames';
import React from 'react';

import css from './emptyFullscreenLayout.module.scss';
import type { LayoutProps } from './get-layout';

/**
 * This layout sets everyhting up for a fullscreen page, but doesn't include
 * the header or the footer. These are up to the implementing page to use
 * if necessary.
 * See @/components/fullscreen-page-slots/fullscreenPageSlots.tsx for a set
 * of components that are designed to work with this layout.
 */
export const EmptyFullscreenLayout = ({ className, children }: LayoutProps) => {
  const classes = classnames(css.root, className);

  return <div className={classes}>{children}</div>;
};
