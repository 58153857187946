import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { FeedbackWithIcons } from '@/components/feedback/feedback-with-icons';
import { useFeedbackOptions } from '@/modules/hooks/use-feedback-options';

interface CsatFeedbackScoreProps {
  onSelect: (value: number) => void;
}

export const CsatFeedbackScore: FC<CsatFeedbackScoreProps> = ({ onSelect }) => {
  const { t } = useTranslation();
  const feedbackOptions = useFeedbackOptions();

  return (
    <FeedbackWithIcons
      title={t('general.csat.feedback.title.initial', {
        defaultValue: 'How satisfied are you with this service?',
      })}
      options={feedbackOptions}
      onSelect={onSelect}
    />
  );
};
