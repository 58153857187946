import React, { useCallback } from 'react';
import css from './pill.module.scss';

export interface Props {
  label?: string;
  onClick?: (label?: string) => void;
}
export const Pill = ({ label, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(label);
  }, [label, onClick]);

  return label ? (
    <button type="button" onClick={handleClick} className={css.root}>
      {label}
    </button>
  ) : null;
};
