import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { getTestIdProp } from '../../lib/get-test-id-prop';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';
import type { Feedback } from '../../modules/analytics/types/event-props';
import type { ContentType } from '../../modules/analytics/types/event-props';
import type { ContentComponentProps } from '../../types/app';
import { IconName } from '../../types/icon';
import { ButtonBare } from '../button-bare/button';

import css from './feedback-buttons.module.scss';
import { Mood } from './types';

export type FeedbackButtonsProps = ContentComponentProps<{
  onSubmit?(mood: Mood): void;
  context: string;
  eventContentType?: ContentType;
}>;

export const FeedbackButtons = ({
  onSubmit,
  context,
  eventContentType,
}: FeedbackButtonsProps) => {
  const { t } = useTranslation();
  const dataLayer = useDataLayer();

  const buttons = useMemo(
    () => [
      {
        label: t('general.feedback.yes'),
        mood: Mood.POSITIVE,
        icon: IconName.THUMBS_UP,
      },
      {
        label: t('general.feedback.no'),
        mood: Mood.NEGATIVE,
        icon: IconName.THUMBS_DOWN,
      },
    ],
    [t],
  );

  const onClickHandler = (mood: Mood) => () => {
    onSubmit?.(mood);

    dataLayer.publish<Feedback>(UIEventName.FEEDBACK_COMPONENT_SUBMIT, {
      value: mood,
      slug: context,
      contentType: eventContentType,
    });
  };

  return (
    <>
      {buttons.map(({ label, mood, icon }) => (
        <ButtonBare
          key={mood}
          label={t(label)}
          icon={icon}
          variant="RECTANGLE"
          size="MEDIUM"
          onClick={onClickHandler(mood)}
          className={css.button}
          {...getTestIdProp(`feedback-button-${mood}`)}
        />
      ))}
    </>
  );
};
