import { useRouter } from 'next/router';
import qs from 'qs';
import { useEffect, useState } from 'react';
import type { MoveToMobileModule } from 'pxp-api/clients/strapi/config-type';

import { Module } from '@/enums/module';
import { QueryParam } from '@/enums/route';
import { useModule } from '@/hooks/use-module';
import { getGAClientId } from '@/lib/get-ga-client-id';

interface Props {
  queryParams?: Record<string, string>;
}

export const useMoveToMobileUrl = ({ queryParams }: Props) => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>(undefined);
  const moveToMobileModule = useModule<MoveToMobileModule>(
    Module.MOVE_TO_MOBILE,
  );
  const { query } = useRouter();
  const { baseUrl } = moveToMobileModule?.properties ?? {};

  useEffect(() => {
    // Get the current client id
    const clientId = getGAClientId();

    // Add query params to the url
    const queryString = qs.stringify({
      ...query,
      [QueryParam.UTM_SOURCE]: 'ssu_device',
      [QueryParam.UTM_MEDIUM]: 'move-to-mobile',
      [QueryParam.UTM_CAMPAIGN]: 'move-to-mobile',
      [QueryParam.CID]: clientId,
      ...queryParams,
    });

    // We assume that the first two segments are always the [iata] and [ssuId]
    // Because window.location.pathname starts with a leading slash, the first
    // segment is always empty, so we slice starting from the third item.
    const segments = window.location.pathname.split('/').slice(3);
    const segmentsString = `/${segments.join('/')}`;

    setQrCodeUrl(`${baseUrl}${segmentsString}?${queryString}`);
  }, [queryParams, query, baseUrl]);

  return qrCodeUrl;
};
