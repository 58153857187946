import { IataCode } from 'pxp-api/constants';

import { Route } from '@/enums/route';

export const APP_VERSION = process.env.NEXT_PUBLIC_VERSION || '0.0.0';

export const getIataCodeFromEnv = (): IataCode => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV;

  if (!appEnv) {
    throw new Error('Environment variable NEXT_PUBLIC_APP_ENV is not defined');
  }

  const [, iataCode] = appEnv.includes('-')
    ? appEnv.split('-')
    : ['prv', 'doh']; // 'doh' does not have a suffix

  const formattedIataCode = iataCode.toUpperCase();

  if (!Object.values(IataCode).includes(formattedIataCode as IataCode)) {
    throw new Error(`IATA code is not supported: ${formattedIataCode}`);
  }

  return formattedIataCode as IataCode;
};

export const IATA_CODE = getIataCodeFromEnv();

export const STATIC_ROUTES = [
  Route.MAP,
  Route.FLIGHT_INFO,
  Route.CONFIG,
] as const;
