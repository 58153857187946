import dynamic from 'next/dynamic';
import type { ComponentType, FC, PropsWithChildren } from 'react';

import { Module } from '../../../enums/module';
import { useModule } from '../../../hooks/use-module';
import type { MapModule } from '../../../types/content-schema';
import type { MapProviderProps } from '../types/provider';

const mapProviders: Record<string, ComponentType<MapProviderProps>> = {
  VISIO_GLOBE: dynamic<MapProviderProps>(
    () => import('../providers/visioglobe'),
  ),
  MAPBOX: dynamic<MapProviderProps>(() => import('../providers/mapbox')),
};

export const MapContext: FC<PropsWithChildren<{}>> = ({ children }) => {
  const mapModule = useModule<MapModule>(Module.MAP);

  if (!mapModule || !mapModule.enabled) {
    return null;
  }

  const provider = mapModule.properties.provider;

  if (!provider || !provider.type) {
    throw new Error('Please provide a valid map provider');
  }

  const ContextProvider = mapProviders[provider.type];

  return <ContextProvider options={provider}>{children}</ContextProvider>;
};
