import type { ParsedUrlQuery } from 'querystring';

import type { NextRouter } from 'next/router';

import { DEFAULT_LOCALE } from '../constants/i18n';
import { Path, QueryParam } from '../enums/route';

export const isIndexPage = (router: NextRouter) => {
  const { [QueryParam.SSU_PATH]: ssuPath } = router.query;
  return (
    (!ssuPath || ssuPath.length === 0) &&
    router.route === Path.CATCH_ALL.toString()
  );
};

export const cleanQueryParams = (query: ParsedUrlQuery) => {
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

type RequiredQueryParams = Record<
  QueryParam.IATA | QueryParam.SSU_ID,
  string | string[]
>;
export const createRequiredSsuQueryParams = (
  query: ParsedUrlQuery,
): RequiredQueryParams & ParsedUrlQuery => {
  const {
    [QueryParam.SSU_ID]: ssuId,
    [QueryParam.IATA]: iata,
    [QueryParam.SSU_PATH]: ssuPath,
  } = query;

  if (!ssuId || !iata) {
    throw new Error('Missing iata/ssuId');
  }

  return {
    [QueryParam.SSU_PATH]: ssuPath,
    [QueryParam.IATA]: iata,
    [QueryParam.SSU_ID]: ssuId,
  };
};

export const mergeSsuQueryParams = (
  currentQuery: ParsedUrlQuery,
  newQuery: ParsedUrlQuery = {},
) =>
  cleanQueryParams({
    ...createRequiredSsuQueryParams(currentQuery),
    ...newQuery,
  });

export const createSsuHref = ({ query, locale }: NextRouter, path = '') => {
  const { [QueryParam.IATA]: iata, [QueryParam.SSU_ID]: ssuId } =
    createRequiredSsuQueryParams(query);

  return `/${[locale !== DEFAULT_LOCALE ? locale : undefined, iata, ssuId]
    .filter(Boolean)
    .join('/')}${path ? `/${path}` : ''}`.replace('//', '/');
};
