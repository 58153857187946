import axios from 'axios';
import type { HealthCheckRequest } from 'pxp-api/clients/strapi/types';
import useSWR from 'swr';

import { useCustomLocalStorageState } from '@/hooks/use-custom-local-storage-state';

import { APP_VERSION } from '../constants/app';
import { useSSU } from '../context/ssu-provider';
import { LocalStorageConfigName } from '../enums/localStorageConfig';
import { ApiRoute } from '../enums/route';

const POLL_INTERVAL = 60 * 1000; // 1 minute

interface UseHealthCheckProps {
  lastInteraction: string;
  lastBoardingPassScan: string;
  lastFlightSearch: string;
}

export const usePostHealthCheck = ({
  lastInteraction,
  lastBoardingPassScan,
  lastFlightSearch,
}: UseHealthCheckProps) => {
  const { id, config } = useSSU();
  const [isDisabledByLocalStorage] = useCustomLocalStorageState(
    LocalStorageConfigName.DISABLE_HEALTH_CHECK,
    false,
  );
  const isDisabled = isDisabledByLocalStorage || config.isMobile;

  const data: HealthCheckRequest = {
    ssuId: id,
    version: APP_VERSION,
    lastInteraction,
    lastBoardingPassScan,
    lastFlightSearch,
  };

  return useSWR(
    isDisabled ? null : ApiRoute.HEALTH_CHECK,
    (url: string) => axios.post<HealthCheckRequest>(url, data),
    {
      refreshInterval: POLL_INTERVAL,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );
};
