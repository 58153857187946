import type { ReactNode } from 'react';
import React from 'react';
import { createElement } from 'react';

import { RenderLink } from './render-link';

type RenderComponentsRichTextProps = {
  htmlString: string;
  className?: string;
  areaGroupId?: string;
};

const renderNode = (node: ChildNode, index: number): ReactNode => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  }

  if (node instanceof HTMLElement && node.tagName === 'A') {
    return <RenderLink node={node} />;
  }

  const children = Array.from(node.childNodes).map((childNode, childIndex) =>
    renderNode(childNode, childIndex),
  );

  return createElement(
    node.nodeName.toLowerCase(),
    { key: index },
    ...children,
  );
};

export const RenderComponentsRichText = ({
  htmlString,
  className,
  areaGroupId,
}: RenderComponentsRichTextProps) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;

  const nodes = Array.from(div.children).filter((node) => {
    const nodeAreaGroupId = node.getAttribute('data-area-group');
    return nodeAreaGroupId ? nodeAreaGroupId === areaGroupId : true;
  });
  const components = nodes.map((node, index) => renderNode(node, index));

  return <div className={className}>{components}</div>;
};
