import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { useSSU } from '@/context/ssu-provider';

import type { MapFloor } from '../../../../types/content-schema';

import css from './floor-switcher-item.module.scss';

export interface FloorSwitcherItemProps {
  floor: MapFloor;
  isActiveFloor: boolean;

  onClickFloor(id: string): void;
}

export const FloorSwitcherItem: FC<FloorSwitcherItemProps> = ({
  floor,
  isActiveFloor,
  onClickFloor,
}) => {
  const { t } = useTranslation();
  const classes = classnames(css.root, isActiveFloor && css.isActive);
  const {
    config: { orientation },
  } = useSSU();
  const isFloorOfSSU = floor.id === orientation.floor;

  const floorNumberTranslation: string = t('general.map.floorNumber', {
    floorNumber: floor.number,
    defaultValue: `Floor number: ${floor.number}.`,
  });
  const floorNameTranslation: string = t('general.map.floorName', {
    // @ts-expect-error: translationKey is dynamic
    floorName: t(floor.translationKey),
    // @ts-expect-error: translationKey is dynamic
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- translationKey is dynamic
    defaultValue: `Floor name: ${t(floor.translationKey)}.`,
  });
  const currentFloorTranslation = isFloorOfSSU
    ? t('general.map.youAreHereMarker.floorCurrent', {
        defaultValue: 'You are here.',
      })
    : '';
  const ariaLabel = `${floorNumberTranslation} ${floorNameTranslation} ${currentFloorTranslation}`;

  return (
    <li className={classes}>
      <button
        onClick={() => onClickFloor(floor.id)}
        className={css.button}
        aria-label={ariaLabel}
      >
        <div className={css.number}>
          <span>{floor.number}</span>
        </div>
        <div className={css.label}>
          {/*
          // @ts-expect-error: translationKey is dynamic */}
          <span className={css.name}>{t(floor.translationKey)}</span>
          {isFloorOfSSU && (
            <span className={css.youAreHere}>
              {t('general.map.youAreHereMarker.floorCurrent', {
                defaultValue: 'You are here',
              })}
            </span>
          )}
        </div>
      </button>
    </li>
  );
};
