import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { WayFindingStore } from './types';

export const selectWayFinding = (state: WayFindingStore) => ({
  isShowingRoute: state.isShowingRoute,
  from: state.from,
  to: state.to,
  type: state.type,
  meta: state.meta,
});

export const useWayFindingStore = createWithEqualityFn<WayFindingStore>()(
  devtools(
    immer((set) => ({
      isShowingRoute: false,
      from: undefined,
      to: undefined,
      type: undefined,
      meta: undefined,
      showRoute: () =>
        set((state) => {
          state.isShowingRoute = true;
        }),
      hideRoute: () =>
        set((state) => {
          state.isShowingRoute = false;
        }),
      startRoute: (to, type, meta) =>
        set((state) => {
          state.isShowingRoute = true;
          state.to = to;
          state.type = type;
          state.meta = meta;
        }),
      stopRoute: () =>
        set((state) => {
          state.isShowingRoute = false;
          state.to = undefined;
        }),
    })),
  ),
);
