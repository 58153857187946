import classnames from 'classnames';
import type { TFunction } from 'i18next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import type { FC } from 'react';

import { useSSU } from '@/context/ssu-provider';

import { Path, QueryParam } from '../../../../enums/route';
import { useQueryParam } from '../../../../hooks/use-query-param';
import { deepEqual } from '../../../../lib/equality-fns';
import { ComponentId } from '../../enums/components';
import { GeoLocationState, useGeoLocation } from '../../hooks/use-geo-location';
import { useGetFloorIndication } from '../../hooks/use-get-floor-indication';
import { useMap } from '../../hooks/use-map';
import { useCurrentFloorStore } from '../../store/currentFloorStore';
import {
  selectWayFinding,
  useWayFindingStore,
} from '../../store/wayFindingStore';

import css from './you-are-here-marker.module.scss';

interface YouAreHereMarkerProps {
  className?: string;
}

const getText = (floorIndication: number, t: TFunction) => {
  if (floorIndication === 0) {
    return t('general.map.youAreHereMarker.floorCurrent');
  }
  if (floorIndication > 0) {
    return t('general.map.youAreHereMarker.floorDown', {
      floorNumber: floorIndication,
    });
  }
  return t('general.map.youAreHereMarker.floorUp', {
    floorNumber: Math.abs(floorIndication),
  });
};

export const YouAreHereMarker: FC<YouAreHereMarkerProps> = ({ className }) => {
  const { t } = useTranslation();
  const { config } = useSSU();
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    updateYouAreHereMarkerElement,
    updateYouAreHereMarkerLocation,
    centerMapToYouAreHereMarker,
    goToPoi,
    removeYouAreHereMarker,
  } = useMap();
  const currentFloorId =
    useCurrentFloorStore((state) => state.currentFloor) ||
    config.orientation.floor;
  const { floorIndicationNumber } = useGetFloorIndication(currentFloorId);
  const { pathname } = useRouter();
  const isMapPage = pathname === Path.MAP.toString();
  const wayFinding = useWayFindingStore(selectWayFinding, deepEqual);
  const poiId = useQueryParam(QueryParam.POI_ID);

  const onPositionUpdate = ({
    coords: { latitude, longitude, heading },
  }: GeolocationPosition) => {
    void updateYouAreHereMarkerLocation({ latitude, longitude, heading });
  };
  const text = getText(floorIndicationNumber, t);

  const onPositionInit = ({ coords: { heading } }: GeolocationPosition) => {
    if (poiId) {
      return goToPoi(String(poiId));
    }
    void centerMapToYouAreHereMarker({ heading });
  };

  const activeGeoLocation = !!config?.orientation?.useGeoLocation && isMapPage;
  const { state } = useGeoLocation({
    active: activeGeoLocation,
    onPositionUpdate,
    onInit: onPositionInit,
  });
  const markerIsActive =
    (!wayFinding.isShowingRoute && !activeGeoLocation) ||
    (activeGeoLocation && state === GeoLocationState.GRANTED.toString());
  const classNames = classnames(
    css.root,
    css.currentPin,
    markerIsActive && css.active,
    className,
  );

  useEffect(() => {
    if (ref.current) {
      void updateYouAreHereMarkerElement(ref.current);
    }
    return () => {
      void removeYouAreHereMarker();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only reren when the floor changes
  }, [currentFloorId]);

  return (
    <div ref={ref} id={ComponentId.YOU_ARE_HERE_MARKER} className={classNames}>
      <span className={css.marker} />
      <span className={css.label}>{text}</span>
    </div>
  );
};
