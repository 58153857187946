import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Icon } from '../../../../components/icon/icon';
import { IconName } from '../../../../types/icon';
import { InfoRow } from '../info-row/info-row';
import css from './passport-control.module.scss';

export const PassportControl: FC = () => {
  const { t } = useTranslation();
  return (
    <InfoRow className={css.root}>
      <div className={css.container}>
        <Icon className={css.icon} name={IconName.BORDER_CONTROL} />
        <p className={css.text}>
          {t('general.flightInfo.passportControl', {
            defaultValue:
              'Please note: you must go through passport control. Allow for extra time and keep your passport ready.',
          })}
        </p>
      </div>
    </InfoRow>
  );
};
