export enum Route {
  MAP = 'map',
  MAP_SEARCH = 'map-search',
  FLIGHT_INFO = 'flight-info',
  FLIGHT_INFO_DETAIL = 'flight-info-detail',
  CONTENT_PAGE = 'content-page',
  DISCLAIMER = 'disclaimer',
  CONFIG = 'config',
  FAQ = 'faq',
  SEARCH = 'search',
}

export enum ApiRoute {
  POIS = '/api/pois',
  FLIGHT_INFO = '/api/flight-info',
  VIDEO_CALL = '/api/video-call',
  HEALTH_CHECK = '/api/health-check',
  TOPICS = '/api/cms/topics',
  FAQ_BY_TOPICS = '/api/cms/faq-by-topics',
}

export enum Path {
  HOME = '/[iata]/[ssuId]/',
  CATCH_ALL = '/[iata]/[ssuId]/[[...ssuPath]]',
  CALL_AGENT = '/[iata]/[ssuId]/call-agent',
  CALL_FEEDBACK = '/[iata]/[ssuId]/call-agent/feedback',
  MAP = '/[iata]/[ssuId]/map',
  FLIGHT_INFO = '/[iata]/[ssuId]/flight-info',
  FLIGHT_INFO_DETAIL = '/[iata]/[ssuId]/flight-info/[flightId]',
  CONTACT = '/[iata]/[ssuId]/contact',
  CONFIG = '/[iata]/[ssuId]/config',
  FAQ = '/[iata]/[ssuId]/faq',
  TOPIC = '/[iata]/[ssuId]/faq/[topicSlug]',
  SEARCH = '/[iata]/[ssuId]/search',
}

export enum QueryParam {
  SSU_ID = 'ssuId',
  SSU_PATH = 'ssuPath',
  FLIGHT_DIRECTION = 'flightDirection',
  FLIGHT_ID = 'flightId',
  SEARCH_QUERY = 'q',
  POI_ID = 'poiId',
  FROM = 'from',
  CATEGORY = 'category',
  STATE = 'state',
  CONTACT_OPTION_INDEX = 'contactOptionIndex',
  IATA = 'iata',
  CSAT_SCORE = 'csatScore',
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  CID = 'cid',
  TOPIC_ID = 'topicId',
  SHOCON = 'shoCon',
  START_AREAGROUP = 'startAreaGroup',
  BOARDING_PASS_SCAN = 'boardingPassScan',
}

export enum QueryParamState {
  ERROR = '0',
}

export enum LocalStorageRoute {
  HOME_HREF = 'home-href',
}
