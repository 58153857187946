import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { Icon } from '@/components/icon/icon';
import type { IconName } from '@/types/icon';

import { Loader } from '../loader/loader';
import { SrOnly } from '../sr-only/sr-only';

import css from './button-icon.module.scss';

type PopOverProps = {
  isOpen: boolean;
  onOpenChange: () => void;
  content: ReactNode;
};

type ButtonWithPopOver = {
  hasPopOver: true;
  popOverProps: PopOverProps;
};

type ButtonWithoutPopOver = {
  hasPopOver?: false;
  popOverProps?: never;
};

export enum ButtonVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

const variantMap = {
  [ButtonVariant.PRIMARY]: css.primary,
  [ButtonVariant.SECONDARY]: css.secondary,
};

type ButtonIconProps = {
  iconName: IconName;
  iconClassName?: string;
  variant?: keyof typeof variantMap;
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  (ButtonWithPopOver | ButtonWithoutPopOver);

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  (
    {
      className,
      iconClassName,
      iconName,
      onClick,
      hasPopOver,
      popOverProps,
      variant = ButtonVariant.PRIMARY,
      isLoading,
      title,
      ...rest
    },
    forwardedRef,
  ) => {
    const classes = classNames(
      css.root,
      variantMap[variant],
      className,
      isLoading && css.isLoading,
    );
    const iconClasses = classNames(css.icon, iconClassName);

    const buttonElement = (
      <button
        type="button"
        onClick={onClick}
        className={classes}
        ref={forwardedRef}
        {...rest}
      >
        {isLoading ? (
          <Loader className={css.loader} />
        ) : (
          <Icon name={iconName} className={iconClasses} />
        )}
        <SrOnly>{title}</SrOnly>
      </button>
    );

    return hasPopOver ? (
      <Popover.Root
        open={popOverProps?.isOpen}
        onOpenChange={popOverProps?.onOpenChange}
      >
        <Popover.Anchor asChild>{buttonElement}</Popover.Anchor>
        <AnimatePresence>
          {popOverProps?.isOpen ? (
            <Popover.Portal forceMount>
              <Popover.Content
                className={css.popoverContent}
                onInteractOutside={(event) => {
                  event.preventDefault();
                }}
                side="top"
                asChild
                // This is not a dialog and does not behave like a tooltip (https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role),
                // so we need to override the role to presentation, as it only provides
                // visual aid
                role="presentation"
                aria-hidden="true"
                onOpenAutoFocus={(event) => {
                  // Prevent autofocus on the popover on open
                  event.preventDefault();
                }}
                onCloseAutoFocus={(event) => {
                  // Prevent autofocus on the button on close
                  event.preventDefault();
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                >
                  {popOverProps?.content}
                  <Popover.Arrow className={css.popoverArrow} />
                </motion.div>
              </Popover.Content>
            </Popover.Portal>
          ) : null}
        </AnimatePresence>
      </Popover.Root>
    ) : (
      buttonElement
    );
  },
);
