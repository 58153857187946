import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { createWithEqualityFn } from 'zustand/traditional';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { SessionStorageKey } from '../enums/session-storage';

interface HistoryStore {
  list: string[];
  previousUrl?: string;
  push: (url: string) => void;
  update: (list: string[]) => void;
  unshift: () => void;
}

export const historyStore = createWithEqualityFn<HistoryStore>()(
  devtools(
    persist(
      immer((set) => ({
        list: [],
        previousUrl: undefined,
        push: (url: string) => {
          set((state) => {
            state.list = [url.replace(/\/$/, ''), ...state.list];
          });
        },
        update: (rawList: string[]) => {
          const list = rawList.map((item) => item.replace(/\/$/, ''));
          set((state) => {
            state.list = list;
          });
        },
        unshift: () => {
          set((state) => {
            state.list = state.list.slice(1);
          });
        },
      })),
      {
        name: SessionStorageKey.SSU_HISTORY,
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

type UseHistory = () => HistoryStore & { back: () => void };

export const useHistory: UseHistory = () => {
  const { back: routerBack } = useRouter();
  const { list, push, update, unshift, previousUrl } = historyStore();

  const back = useCallback(() => {
    unshift();
    routerBack();
  }, [unshift, routerBack]);

  return {
    list,
    previousUrl,
    push,
    update,
    unshift,
    back,
  };
};
