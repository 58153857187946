import type { QRCodeImpression } from '../../../types/event-props';
import { QRCodeImpressionType } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

const eventNameMap = {
  [QRCodeImpressionType.CSAT]: GTMEventName.QR_CODE_IMPRESSION_CSAT,
  [QRCodeImpressionType.MTM]: GTMEventName.QR_CODE_IMPRESSION_MTM,
  [QRCodeImpressionType.FAQ]: GTMEventName.QR_CODE_IMPRESSION_FAQ,
  [QRCodeImpressionType.VOUCHER]: GTMEventName.QR_CODE_IMPRESSION_VOUCHER,
  [QRCodeImpressionType.ONWARD_TRAVEL]:
    GTMEventName.QR_CODE_IMPRESSION_ONWARD_TRAVEL,
  [QRCodeImpressionType.CONTACT_AIRLINE]: GTMEventName.QR_CODE_CONTACT_AIRLINE,
  [QRCodeImpressionType.PROMOTION]: GTMEventName.QR_CODE_IMPRESSION_PROMOTION,
};

export const qrCodeImpression: Mapper<QRCodeImpression> = ({
  meta: { value, type },
}) => ({
  eventName: eventNameMap[type],
  eventLabel: value ?? eventNameMap[type],
});
