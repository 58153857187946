import { useEffect } from 'react';
import { useNetworkState } from '../../hooks/use-network-state';
import { useNetworkStore } from '../../store/networkStore';

export const NetworkListener = () => {
  const setNetworkState = useNetworkStore((state) => state.setState);
  const { state } = useNetworkState();

  useEffect(() => {
    setNetworkState(state);
  }, [state, setNetworkState]);

  return null;
};
