import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';

import { setAbortableTimeout } from '../lib/set-abortable-timeout';

export type ToastPosition = 'top' | 'bottom';
export type ToastType = 'success' | 'danger';
export interface ToastOptions {
  dismissable: boolean;
  autodismiss: boolean;
  autodismissTimer: number;
  position: ToastPosition;
}

export interface Toast {
  message: string;
  type: ToastType;
  options: ToastOptions;
}

interface ToastContext {
  activeToast: Toast | null;
  showToast: (
    type: ToastType,
    message: string,
    options?: Partial<ToastOptions>,
  ) => void;
  dismissToast: () => void;
}

const defaultToastOptions: ToastOptions = {
  dismissable: true,
  autodismiss: true,
  autodismissTimer: 10 * 1000, // 10 seconds
  position: 'bottom',
};

export const ToastContext = createContext<ToastContext | undefined>(undefined);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [activeToast, setActiveToast] = useState<Toast | null>(null);
  const abortController = useRef(new AbortController());

  const showToast = useCallback(
    (type: ToastType, message: string, options?: Partial<ToastOptions>) => {
      const resolvedToastOptions =
        options === undefined
          ? defaultToastOptions
          : { ...defaultToastOptions, ...options };
      setActiveToast({ type, message, options: resolvedToastOptions });
    },
    [setActiveToast],
  );

  const dismissToast = useCallback(() => {
    // abort running timeout to prevent race conditions
    abortController.current.abort();
    setActiveToast(null);
  }, [setActiveToast]);

  useEffect(() => {
    if (!activeToast || !activeToast.options.autodismiss) {
      return;
    }
    abortController.current = new AbortController();
    setAbortableTimeout(
      dismissToast,
      activeToast.options.autodismissTimer,
      abortController.current.signal,
    );
  }, [activeToast, dismissToast]);

  return (
    <ToastContext.Provider value={{ activeToast, showToast, dismissToast }}>
      {children}
    </ToastContext.Provider>
  );
};
