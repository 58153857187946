import { mapFaqOpen } from '@/modules/analytics/services/schiphol/mappers/faq';
import type { Mapper } from '@/modules/analytics/services/schiphol/types';

import { UIEventName } from '../../events';

import { mapError, mapPageView, mapSessionEnd, mapStart } from './mappers/app';
import { mapAudioVideo } from './mappers/audio-video';
import { mapButtonClick } from './mappers/button';
import { mapChat } from './mappers/chat';
import { mapContactSelect } from './mappers/contact';
import {
  mapFeedbackCSAT,
  mapFeedbackCSATImpression,
  mapFeedbackSubmit,
  mapFeedbackTranslations,
  mapFeedbackVideoCall,
} from './mappers/feedback';
import {
  mapBoardingPassScan,
  mapFlightInfoAction,
  mapFlightInfoAirlineLounge,
  mapFlightInfoDetail,
  mapFlightInfoSearch,
} from './mappers/flight-info';
import { mapLanguageSelect } from './mappers/language';
import {
  mapMapFilter,
  mapMapPoiDetail,
  mapMapRouting,
  mapMapSearch,
  mapMapSelectFloor,
} from './mappers/map';
import { mapModal } from './mappers/modal';
import { qrCodeImpression } from './mappers/move-to-mobile';
import { mapGlobalSearch } from './mappers/search';
import { mapVideoCall } from './mappers/video-call';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- // to mapper is only known at runtime.
export const eventDataMap: Record<UIEventName, Mapper<any> | undefined> = {
  [UIEventName.START]: mapStart,
  [UIEventName.PAGE_VIEW]: mapPageView,
  [UIEventName.BUTTON_CLICK]: mapButtonClick,
  [UIEventName.ERROR]: mapError,
  [UIEventName.MODAL]: mapModal,
  [UIEventName.FAQ_OPEN]: mapFaqOpen,
  [UIEventName.FEEDBACK_COMPONENT_SUBMIT]: mapFeedbackSubmit,
  [UIEventName.FEEDBACK_VIDEO_CALL_SUBMIT]: mapFeedbackVideoCall,
  [UIEventName.FEEDBACK_TRANSLATIONS_SUBMIT]: mapFeedbackTranslations,
  [UIEventName.FEEDBACK_CSAT_SUBMIT]: mapFeedbackCSAT,
  [UIEventName.FEEDBACK_CSAT_IMPRESSION]: mapFeedbackCSATImpression,
  [UIEventName.VIDEO_CALL]: mapVideoCall,
  [UIEventName.FLIGHT_INFO_ACTION]: mapFlightInfoAction,
  [UIEventName.FLIGHT_INFO_DETAIL]: mapFlightInfoDetail,
  [UIEventName.FLIGHT_INFO_SEARCH]: mapFlightInfoSearch,
  [UIEventName.FLIGHT_INFO_AIRLINE_LOUNGE]: mapFlightInfoAirlineLounge,
  [UIEventName.MAP_FILTER]: mapMapFilter,
  [UIEventName.MAP_SEARCH]: mapMapSearch,
  [UIEventName.MAP_POI_DETAIL]: mapMapPoiDetail,
  [UIEventName.MAP_ROUTING]: mapMapRouting,
  [UIEventName.MAP_SELECT_FLOOR]: mapMapSelectFloor,
  [UIEventName.BOARDING_PASS_SCAN]: mapBoardingPassScan,
  [UIEventName.CONTACT_SELECT]: mapContactSelect,
  [UIEventName.LANGUAGE_SELECT]: mapLanguageSelect,
  [UIEventName.SESSION_END]: mapSessionEnd,
  [UIEventName.QR_CODE_IMPRESSION]: qrCodeImpression,
  [UIEventName.AUDIO_VIDEO]: mapAudioVideo,
  [UIEventName.CHAT]: mapChat,
  [UIEventName.LINK_CLICK]: undefined,
  [UIEventName.QR_CODE_VOUCHER_IMPRESSION]: undefined,
  [UIEventName.GLOBAL_SEARCH]: mapGlobalSearch,
};
