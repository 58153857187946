import { envMap, getEnvironmentFromAppEnv } from './environment';

const environment = process.env.NEXT_PUBLIC_APP_ENV;
const SENTRY_ENV = getEnvironmentFromAppEnv(environment);

export const sentryConfig = (dsn: string, cfg = {}) => ({
  dsn,
  enabled: SENTRY_ENV !== envMap.dev,
  environment,
  tracesSampleRate: SENTRY_ENV === envMap.prd ? 0.1 : 1.0,
  ...cfg,
});
