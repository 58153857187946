import type { Page, PageTitle } from 'pxp-api/clients/strapi/config-type';
import type { SSU } from '../types/content-schema';

const SEPARATOR = ' - ';

export const createPageTitle = ({ id }: SSU, title?: string) => {
  if (!title) {
    return id;
  }
  return `${id}${SEPARATOR}${title}`;
};

export const destructPageTitle = (pageTitle: string) => {
  const [base, title] = pageTitle.split(SEPARATOR);
  return {
    base,
    title,
  };
};

export const getPageTitleComponentTranslationKey = ({
  page,
}: {
  page: Page;
}) => {
  const pageTitleComponent = page?.components?.find(
    (component) => component.type === 'PAGE_TITLE',
  );
  return (pageTitleComponent?.properties as PageTitle)?.translationKeyTitle;
};
