import type { FC } from 'react';
import classnames from 'classnames';
import { TheFooter } from '../components/the-footer/the-footer';
import { TheHeader } from '../components/the-header/the-header';
import { ElementSelector } from '../enums/general';
import css from './contentLayout.module.scss';
import defaultCSS from './defaultLayout.module.scss';
import type { LayoutProps } from './get-layout';

export const ContentLayout: FC<LayoutProps> = ({ className, children }) => {
  const classes = classnames(defaultCSS.root, className);
  return (
    <div className={classes}>
      <TheHeader />
      <div className={classnames(css.scrollFade, css.top)} />
      <main
        className={css.main}
        data-selector={ElementSelector.SCROLLABLE_CONTENT}
      >
        {children}
      </main>
      <div className={classnames(css.scrollFade, css.bottom)} />
      <TheFooter className={css.footer} />
    </div>
  );
};
