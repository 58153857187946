import { useSSU } from '../context/ssu-provider';

export const usePassportNotification = (poiBubbleId = '') => {
  const {
    config: { bubble, passportNotification, orientation },
  } = useSSU();
  return (
    poiBubbleId !== bubble?.id &&
    passportNotification?.bubbles?.some(
      (bubble) => bubble?.id === poiBubbleId,
    ) &&
    !orientation.useGeoLocation
  );
};
