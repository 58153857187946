import classnames from 'classnames';
import Image from 'next/image';
import Link, { type LinkProps } from 'next/link';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { getTestIdProp } from '../../lib/get-test-id-prop';
import type { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';

import css from './button.module.scss';

export interface ButtonBareProps
  extends Omit<HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, 'type'> {
  as?: 'a' | 'button';
  className?: string;
  color?: 'PRIMARY' | 'SECONDARY' | 'TERTIARY';
  disabled?: boolean;
  href?: LinkProps['href'];
  icon?: IconName;
  iconPosition?: 'BEFORE' | 'AFTER';
  image?: {
    url?: string;
    alt?: string;
  };
  label: string;
  size?: 'SMALL' | 'MEDIUM' | 'LARGE';
  testId?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'RECTANGLE' | 'SQUARE';
}

const sizeClassMap = {
  SMALL: css.sizeSmall,
  MEDIUM: css.sizeMedium,
  LARGE: css.sizeLarge,
};

const variantClassMap = {
  RECTANGLE: css.variantRectangle,
  SQUARE: css.variantSquare,
};

const colorClassMap = {
  PRIMARY: css.colorPrimary,
  SECONDARY: css.colorSecondary,
  TERTIARY: css.colorTertiary,
};

export const ButtonBare = forwardRef<
  (HTMLButtonElement & HTMLAnchorElement) | null,
  ButtonBareProps
>(
  (
    {
      as,
      className,
      color,
      disabled,
      href,
      icon,
      iconPosition,
      image,
      label,
      size,
      testId,
      variant,
      ...props
    },
    ref,
  ) => {
    const classes = classnames(
      css.root,
      variant ? variantClassMap[variant] : variantClassMap.RECTANGLE,
      size && sizeClassMap[size],
      colorClassMap[color || 'PRIMARY'],
      iconPosition === 'BEFORE' && css.iconBefore,
      !icon && css.noIcon,
      disabled && css.disabled,
      testId,
      className,
    );

    const innerComponent = (
      <>
        <span className={css.label}>{label}</span>

        {image?.url && (
          <div className={css.richIcon}>
            <Image src={image?.url} fill alt="" aria-hidden={true} />
          </div>
        )}

        {!image?.url && icon && (
          <span className={css.icon}>
            <Icon name={icon} />
          </span>
        )}
      </>
    );

    const combinedProps = {
      className: classes,
      ref,
      ...props,
      ...getTestIdProp(testId),
    };

    if (as === 'a' && href) {
      return (
        <Link href={href} {...combinedProps}>
          {innerComponent}
        </Link>
      );
    }

    return <button {...combinedProps}>{innerComponent}</button>;
  },
);
