import { useEffect } from 'react';
import { Event } from '../enums/events';
import { useMap } from './use-map';

export const useMapEvent = (event: Event, callBack: Function) => {
  const { addEventListener, removeEventListener } = useMap();

  useEffect(() => {
    addEventListener(event, callBack);
    return () => {
      removeEventListener(event, callBack);
    };
  });
};
