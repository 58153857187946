import * as Portal from '@radix-ui/react-portal';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { FC, HTMLAttributes, ReactNode } from 'react';
import React, { useRef } from 'react';
import { useOutsideClick } from 'rooks';

import { getTestIdProp } from '@/lib/get-test-id-prop';
import { isIndexPage } from '@/lib/url';
import { IconName } from '@/types/icon';

import { ActionSheetWrapper } from '../action-sheet/action-sheet-wrapper';
import { ButtonIcon, ButtonVariant } from '../button-icon/button-icon';

import css from './modal-container.module.scss';

export enum ModalType {
  DEFAULT = 'default',
  ACTION_SHEET = 'action-sheet',
}

export enum AnimationType {
  FADE = 'fade',
  SLIDE_UP = 'slide-up',
}

const animationPropsMap = {
  [AnimationType.FADE]: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  [AnimationType.SLIDE_UP]: {
    initial: { transform: 'translateY(100%)' },
    animate: { transform: 'translateY(0)' },
    exit: { transform: 'translateY(100%)' },
  },
};

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  fullScreen?: boolean;
  backdropClassName?: string;
  animationType?: AnimationType;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  nested?: boolean;
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  onCloseIcon?: IconName;
  onCloseClassName?: string;
  onCloseButtonComponent?: ReactNode;
  type?: ModalType;
  contentClassName?: string;
}

const ANIMATION_DURATION_SEC = 0.2;

export const ModalContainer: FC<ModalProps> = ({
  children,
  className,
  contentClassName,
  backdropClassName,
  isOpen,
  onClose,
  onBack,
  nested,
  fullScreen,
  onCloseClassName,
  onCloseButtonComponent,
  onCloseIcon = IconName.CLOSE,
  type = ModalType.DEFAULT,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const isIndex = isIndexPage(router);
  const classes = classnames(
    css.root,
    isIndex && css.isIndexPage,
    fullScreen && css.fullScreen,
    type === ModalType.ACTION_SHEET && css.actionSheet,
    className,
  );
  const backdropClasses = classnames(
    css.backdrop,
    nested && css.nested,
    isIndex && css.isIndexPage,
    type === ModalType.ACTION_SHEET && css.actionSheet,
    backdropClassName,
  );
  const contentClasses = classnames(css.content, contentClassName);
  const closeButtonClasses = classnames(css.closeButton, onCloseClassName);
  const animationProps =
    type === ModalType.ACTION_SHEET
      ? animationPropsMap[AnimationType.SLIDE_UP]
      : animationPropsMap[AnimationType.FADE];
  const actionSheetRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(
    actionSheetRef,
    onClose as unknown as (event: MouseEvent) => void,
  );

  const CloseButton = onCloseButtonComponent ?? (
    <ButtonIcon
      variant={ButtonVariant.SECONDARY}
      onClick={onClose}
      className={closeButtonClasses}
      iconName={onCloseIcon}
      title={t('general.close', { defaultValue: 'Close' })}
      {...getTestIdProp('modal-close')}
    />
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal.Root {...getTestIdProp('modal-root')}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: type === ModalType.ACTION_SHEET ? 0.9 : 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: ANIMATION_DURATION_SEC }}
            className={backdropClasses}
          />
          <motion.div
            {...animationProps}
            className={classes}
            transition={{ duration: ANIMATION_DURATION_SEC }}
          >
            {type === ModalType.ACTION_SHEET ? (
              <ActionSheetWrapper ref={actionSheetRef}>
                {children}
              </ActionSheetWrapper>
            ) : (
              <div className={contentClasses}>{children}</div>
            )}
            <footer className={css.footer}>
              {onBack && (
                <ButtonIcon
                  variant={ButtonVariant.SECONDARY}
                  onClick={onBack}
                  iconName={IconName.ARROW_LEFT}
                  className={css.backButton}
                  iconClassName={css.backButtonIcon}
                  title={t('general.back')}
                  {...getTestIdProp('modal-back')}
                />
              )}

              {onClose && CloseButton}
            </footer>
          </motion.div>
        </Portal.Root>
      )}
    </AnimatePresence>
  );
};
