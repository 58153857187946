import type { NextRouter } from 'next/router';
import type { Locale } from 'pxp-api/clients/strapi/config-type';
import { isIndexPage } from '../../lib/url';
import type { ModalState } from '../../store/modalStore';
import { Path } from '@/enums/route';

export const shouldEnableTimeout = (
  router: NextRouter,
  defaultLocale: Locale,
  modals: ModalState,
) => {
  const isIndex = isIndexPage(router) && router.locale === defaultLocale.code;
  const isVideoCallOpen = router.route === Path.CALL_AGENT.toString();
  const isAnyModalOpen = Object.values(modals).some((modal) => modal.isOpen);
  return !isVideoCallOpen && (!isIndex || isAnyModalOpen);
};
