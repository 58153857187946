import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import useSWR from 'swr';

import { Path, QueryParam } from '../../../../enums/route';
import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import type { DefaultMeta } from '../../../analytics/types/event-props';
import { InfoRow } from '../../../flight-info/components/info-row/info-row';
import { useMap } from '../../hooks/use-map';
import { useFlightCardStore } from '../../store/flightCardStore';
import type { Poi } from '../../types/poi';

import css from './flight-info-airline-lounge.module.scss';

interface FlightInfoAirlineLoungeProps {
  loungeLocationId: string;
}

export const FlightInfoAirlineLounge: FC<FlightInfoAirlineLoungeProps> = ({
  loungeLocationId,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { getPoiById } = useMap();
  const dataLayer = useDataLayer();
  const { data: poi } = useSWR<Poi>(
    loungeLocationId,
    getPoiById as () => Promise<Poi>,
  );
  const [image] = poi?.images || [];
  const setCollapsed = useFlightCardStore((state) => state.setCollapsed);

  const onClick = () => {
    setCollapsed(true);
    dataLayer.publish<DefaultMeta>(UIEventName.FLIGHT_INFO_AIRLINE_LOUNGE, {
      value: poi?.name || '',
    });
  };

  return (
    <InfoRow descriptionTerm={t('general.flightInfo.airlineLounge')}>
      {image ? (
        // eslint-disable-next-line @next/next/no-img-element -- Width and height are not available; disabling linter
        <img
          src={image.src}
          title={image.title}
          className={css.image}
          alt={poi?.name}
        />
      ) : (
        poi?.name
      )}
      <Link
        className={css.link}
        href={{
          pathname: Path.MAP,
          query: {
            ...router.query,
            [QueryParam.POI_ID]: loungeLocationId,
          },
        }}
        onClick={onClick}
      >
        {t('general.flightInfo.showOnMap')}
      </Link>
    </InfoRow>
  );
};
