import { useTranslation } from 'next-i18next';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { FlightInfoContext } from '../context/flight-info-context';
import type { FlightInfoProviderApi } from '../types/provider';

import { FlightInfoApi } from './api';

export const FlightInfoProvider = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();
  const api = useMemo<FlightInfoProviderApi>(
    () => FlightInfoApi({ locale: i18n.language }),
    [i18n.language],
  );
  return (
    <FlightInfoContext.Provider value={api}>
      {children}
    </FlightInfoContext.Provider>
  );
};
