import { useContext } from 'react';
import { FlightInfoContext } from '../context/flight-info-context';

export const useFlightInfo = () => {
  const flightInfo = useContext(FlightInfoContext);

  if (!flightInfo) {
    throw Error('Flight Info provider is not initialized');
  }

  return flightInfo;
};
