import { FC, MouseEvent } from 'react';

import classnames from 'classnames';

import { formatDuration, secondsToMinutes } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { getDateLocale } from '../../../../lib/date';
import { deepEqual } from '../../../../lib/equality-fns';

import { Icon } from '../../../../components/icon/icon';
import { IconName } from '../../../../types/icon';
import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import {
  MapRouting,
  MapRoutingAction,
} from '../../../analytics/types/event-props';
import { useMap } from '../../hooks/use-map';
import { WayFindingMeta, WayFindingType } from '../../store/types';
import {
  selectWayFinding,
  useWayFindingStore,
} from '../../store/wayFindingStore';
import { Poi } from '../../types/poi';
import css from './button-routing.module.scss';

export enum ButtonRoutingSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

const sizeMap = {
  [ButtonRoutingSize.SMALL]: css.small,
  [ButtonRoutingSize.MEDIUM]: css.medium,
};

interface ButtonRoutingProps {
  poi: Poi;
  wayFindingType: WayFindingType;
  wayFindingMeta?: WayFindingMeta;
  onClick?: (event: MouseEvent) => void;
  className?: string;
  size?: ButtonRoutingSize;
}

export const ButtonRouting: FC<ButtonRoutingProps> = ({
  poi,
  wayFindingType,
  wayFindingMeta,
  onClick,
  className,
  size = ButtonRoutingSize.MEDIUM,
}) => {
  const classNames = classnames(css.root, size && sizeMap[size], className);
  const { t, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);
  const { startRoute, stopRoute, goToPoi } = useMap();
  const wayFinding = useWayFindingStore(selectWayFinding, deepEqual);
  const dataLayer = useDataLayer();
  const isRouteActive =
    wayFinding.isShowingRoute && wayFinding.to?.id === poi.id;

  const start = async () => {
    dataLayer.publish<MapRouting>(UIEventName.MAP_ROUTING, {
      action: MapRoutingAction.START,
      poiName: poi.defaultName,
    });
    return startRoute(poi, wayFindingType, wayFindingMeta);
  };

  const stop = async () => {
    dataLayer.publish<MapRouting>(UIEventName.MAP_ROUTING, {
      action: MapRoutingAction.STOP,
      poiName: poi.defaultName,
    });
    await stopRoute();
    return goToPoi(poi.id, { withoutMarker: true });
  };

  const onClickStart = (event: MouseEvent) => {
    void start();
    onClick?.(event);
  };

  const onClickStop = async (event: MouseEvent) => {
    await stop();
    onClick?.(event);
  };

  if (typeof poi.position.distanceInSeconds !== 'number') {
    return null;
  }

  return (
    <button
      className={classNames}
      onClick={isRouteActive ? onClickStop : onClickStart}
    >
      <span className={css.icon}>
        <Icon name={isRouteActive ? IconName.CLOSE : IconName.WALKING} />
      </span>
      <span className={css.text}>
        {isRouteActive
          ? t('general.map.route.close', { defaultValue: 'Close route' })
          : formatDuration(
              { minutes: secondsToMinutes(poi.position.distanceInSeconds) },
              { zero: true, locale },
            )}
      </span>
    </button>
  );
};
