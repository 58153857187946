import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { AjaxState } from '../types/ajax';
import type { PoiSearchStore } from './types';

export const usePoiSearchStore = createWithEqualityFn<PoiSearchStore>()(
  devtools(
    immer((set) => ({
      state: AjaxState.INIT,
      filters: {},
      results: [],
      setPending: (filters) =>
        set((state) => {
          state.state = AjaxState.PENDING;
          state.error = undefined;
          state.filters = filters || {};
        }),
      setSuccess: (pois) =>
        set((state) => {
          state.state = AjaxState.SUCCESS;
          state.results = pois;
        }),
      setError: (error) =>
        set((state) => {
          state.state = AjaxState.ERROR;
          state.filters = {};
          state.results = [];
          state.error = error;
        }),
      reset: () =>
        set((state) => {
          state.state = AjaxState.INIT;
          state.filters = {};
          state.error = undefined;
          state.results = [];
        }),
    })),
  ),
);
