import css from '@/components/external-link/external-link.module.scss';
import { Icon } from '@/components/icon/icon';
import type { IconName } from '@/types/icon';

interface Props {
  label: string;
  value?: string;
  icon?: IconName;
  isButtonStyle?: boolean;
  isNumber?: boolean;
}

export const ExternalLinkMobile = ({
  label,
  icon,
  isButtonStyle,
  value,
  isNumber = false,
}: Props) => {
  const href = isNumber ? `tel:${value}` : value;
  return (
    <a className={isButtonStyle ? css.button : css.link} href={href}>
      <span className={css.label}>{label}</span>
      {icon && <Icon className={css.icon} name={icon} />}
    </a>
  );
};
