import { format, getDayOfYear, startOfTomorrow } from 'date-fns';

import { customFetch } from '@/lib/custom-fetch';
import type { FlightInfoByDate } from '@/modules/flight-info/types/service';
import { FlightDirection } from '@/modules/flight-info/types/service';

interface GetFlightIdProps {
  arrivalAirport: string;
  departureAirport?: string;
  flightDate?: Date;
  iataCode?: string;
}

const getFlightNumber = (iataCode: string) =>
  // BCBP spec requires flightNumber to be 4 characters. In the case of a less digits
  // code, we pad with spaces.
  iataCode.replace(/\D/g, '').trim().padEnd(4, ' ');

const getCarrierDesignator = (iataCode: string) =>
  // Carrier Designators are 3 letters, padded with spaces.
  iataCode.replace(/\d/g, '').trim().padEnd(3, ' ');

export const getBarcodeString = ({
  arrivalAirport,
  departureAirport = 'AMS',
  flightDate = new Date(),
  iataCode = '',
}: GetFlightIdProps) =>
  `M1DOE/JOHN            E       ${departureAirport}${arrivalAirport}${getCarrierDesignator(iataCode)}${getFlightNumber(iataCode)} ${getDayOfYear(flightDate)}Y035A0000 100`;

const getFlightData = async (direction: FlightDirection) => {
  const date = format(startOfTomorrow(), 'yyyyMMdd');
  const [{ flights }] = await customFetch<FlightInfoByDate[]>(
    `/api/flight-info?date=${date}&flightDirection=${direction}&limit=1`,
  );

  const [flight] = flights;

  const {
    iataCode,
    arrival: { airportIataCode: arrivalAirport },
    departure: { airportIataCode: departureAirport },
  } = flight;

  console.log({ direction, flight });
  return {
    iataCode,
    arrivalAirport,
    departureAirport,
    arrivalTime: flight.arrival.scheduledArrivalTime,
    departureTime: flight.departure.scheduledDepartureTime,
  };
};

export const invalidBcbpString = async () =>
  Promise.resolve(
    getBarcodeString({
      iataCode: '-+??*',
      arrivalAirport: 'HKG',
      departureAirport: 'AMS',
    }),
  );

export async function exampleArrivalBcbpString() {
  try {
    const { iataCode, arrivalAirport, departureAirport, arrivalTime } =
      await getFlightData(FlightDirection.ARRIVAL);

    return getBarcodeString({
      arrivalAirport,
      departureAirport,
      flightDate: arrivalTime ? new Date(arrivalTime) : undefined,
      iataCode,
    });
  } catch (error) {
    console.error({ error });
    return '';
  }
}

export const exampleDepartureBcbpString = async () => {
  try {
    const { iataCode, arrivalAirport, departureAirport, departureTime } =
      await getFlightData(FlightDirection.DEPARTURE);

    return getBarcodeString({
      arrivalAirport,
      departureAirport,
      flightDate: departureTime ? new Date(departureTime) : undefined,
      iataCode,
    });
  } catch (error) {
    console.error({ error });
    return '';
  }
};

export const notFoundBcbpString = async () =>
  Promise.resolve(
    getBarcodeString({
      departureAirport: 'AMS',
      arrivalAirport: 'HKG',
      iataCode: 'QX66x',
    }),
  );
