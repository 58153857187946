import type { MapModule } from 'pxp-api/clients/strapi/config-type';
import useSWR from 'swr';
import { Module } from '../../../enums/module';
import { useModule } from '../../../hooks/use-module';
import type { ResponseError } from '../../../types/http';
import { MAX_POI_RESULTS, SEARCH_MIN_CHARS } from '../constants/search';
import type { PoiSearchQuery, PoiSearchResponse } from '../types/service';
import { useMap } from './use-map';

interface UseMapSearchOptions {
  minChars?: number;
}

interface UseMapSearchParametersFilters {
  category: string;
}

export interface UseMapSearchParameters {
  query?: string;
  filters?: Partial<UseMapSearchParametersFilters>;
  locale: string;
}

export const useMapSearch = (
  parameters: UseMapSearchParameters,
  options: UseMapSearchOptions = {},
) => {
  const { search } = useMap();
  const mapModule = useModule(Module.MAP) as MapModule;

  const { locale, query, filters } = parameters;
  const { minChars = SEARCH_MIN_CHARS } = options;

  const maxPoiResults =
    (mapModule?.properties?.maxPoiResults as number) || MAX_POI_RESULTS;
  const queryString = JSON.stringify(parameters);
  const categories = filters?.category ? [filters?.category] : [];
  const defaultCategories =
    mapModule?.properties?.initSearchListCategories || [];

  const searchQuery = {
    categories,
    defaultCategories,
    query: query && query?.length >= minChars ? query : '',
  } as PoiSearchQuery;

  return useSWR<PoiSearchResponse, ResponseError>(
    queryString,
    () => search(searchQuery, { limit: maxPoiResults, locale }),
    {
      revalidateOnFocus: false,
    },
  );
};
