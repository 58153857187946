import { useEffect } from 'react';

interface UseSiteKioskBarcodeScannerProps {
  deviceId?: string;
  isEnabled?: boolean;
  onScan: (data: string) => void;
}

export const useSiteKioskBarcodeScanner = ({
  deviceId,
  onScan,
  isEnabled = false,
}: UseSiteKioskBarcodeScannerProps) => {
  useEffect(() => {
    if (
      !isEnabled ||
      deviceId === undefined ||
      typeof siteKiosk === 'undefined'
    ) {
      return;
    }

    const device = siteKiosk.devices.getByName(deviceId);
    if (device) {
      device.onData(({ text }) => onScan(text));
    }
  }, [deviceId, onScan, isEnabled]);
};
