import type { FC, PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { Container } from './components/container/container';
import { RenderMap } from './components/render-map/render-map';
import { MapContext } from './context/module-context';

export const MapModule: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [mapIsLoaded, setMapIsLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    setMapIsLoaded(true);
  }, []);

  const setUnloaded = useCallback(() => {
    setMapIsLoaded(false);
  }, []);

  return (
    <MapContext>
      <Container>
        <RenderMap onInit={setLoaded} onUnload={setUnloaded} />
        {mapIsLoaded && children}
      </Container>
    </MapContext>
  );
};
