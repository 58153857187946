import classnames from 'classnames';
import { formatDuration, secondsToMinutes } from 'date-fns';
import { useTranslation } from 'next-i18next';
import type { MouseEvent } from 'react';
import { forwardRef } from 'react';

import { Card } from '@/components/card/card';
import { Icon } from '@/components/icon/icon';
import { localeMap } from '@/lib/date';
import { IconName } from '@/types/icon';

import type { WayFindingState } from '../../store/types';

import { WayFindingCardHeadingElements } from './way-finding-card-heading-elements';
import css from './way-finding-card.module.scss';

export interface Props {
  className?: string;
  wayFinding: WayFindingState;
  onClose?: (event: MouseEvent) => void;
}

export const WayFindingCard = forwardRef<HTMLDivElement, Props>(
  ({ className, wayFinding, onClose }, ref) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    const locale = localeMap[language];
    const classNames = classnames(css.root, className);

    if (!wayFinding.to) {
      return null;
    }

    return (
      <Card ref={ref} className={classNames}>
        <div className={css.header}>
          {typeof wayFinding.to.position.distanceInSeconds === 'number' && (
            <>
              <span className={css.distance}>
                <Icon name={IconName.WALKING} className={css.icon} />
                <span className={css.distanceLabel}>
                  {formatDuration(
                    {
                      minutes: secondsToMinutes(
                        wayFinding.to.position.distanceInSeconds,
                      ),
                    },
                    { zero: true, locale },
                  )}
                </span>
              </span>
              <Icon name={IconName.CHEVRON_RIGHT} className={css.icon} />
            </>
          )}
          <h4 className={css.title}>
            <WayFindingCardHeadingElements wayFinding={wayFinding} />
          </h4>
          <div className={css.cardActions}>
            <button onClick={onClose} className={css.closeButton}>
              <Icon name={IconName.CLOSE} className={css.icon} />
              <span>
                {t('general.map.route.close', { defaultValue: 'Close route' })}
              </span>
            </button>
          </div>
        </div>
      </Card>
    );
  },
);
