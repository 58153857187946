import classnames from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import { SrOnly } from '@/components/sr-only/sr-only';
import { QueryParam, Route } from '@/enums/route';
import { mergeSsuQueryParams } from '@/lib/url';

import type { Poi } from '../../types/poi';
import { MapSearchResult } from '../map-search-result/map-search-result';

import css from './map-search-results.module.scss';

export interface MapSearchResultsProps {
  className?: string;
  pois?: ReadonlyArray<Poi>;
}

type PoiWithDistance = Poi & { position: { distanceInSeconds: number } };

/**
 * Returns an array of sorted POIs based on their distance in seconds.
 * If the array is empty or the POIs do not have a distance property, the original array is returned.
 */
export function getSortedPois(
  pois: ReadonlyArray<Poi>,
): ReadonlyArray<Poi | PoiWithDistance> {
  if (!pois.length) return pois;

  const someHaveDistance = pois.some(({ position }) =>
    position.hasOwnProperty('distanceInSeconds'),
  );

  if (!someHaveDistance) return pois;

  return [...pois].sort(
    (poi1, poi2) =>
      (poi1.position.distanceInSeconds || Infinity) -
      (poi2.position.distanceInSeconds || Infinity),
  );
}

export const MapSearchResults = ({
  className,
  pois = [],
}: MapSearchResultsProps) => {
  const classNames = classnames(css.root, className);
  const { pathname, query } = useRouter();
  const { t } = useTranslation();

  const getHref = (poi: Poi) => {
    return {
      pathname,
      query: mergeSsuQueryParams(query, {
        ...query,
        [QueryParam.POI_ID]: poi.id,
        [QueryParam.FLIGHT_ID]: query[QueryParam.FLIGHT_ID],
        [QueryParam.FROM]: Route.MAP_SEARCH,
      }),
    };
  };
  const { q: searchQuery } = query;

  return (
    <div className={classNames}>
      <h2 className={css.heading}>{t('general.map.search.results')}</h2>
      {pois.length && searchQuery && (
        <SrOnly>
          <span role="status">
            <Trans
              i18nKey="general.map.search.amountResults"
              values={{ count: pois.length, searchQuery }}
              defaults='{count} results for "{searchQuery}"'
            />
          </span>
        </SrOnly>
      )}
      <motion.ul
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.2 }}
        className={css.results}
      >
        {getSortedPois(pois).map((poi) => (
          <MapSearchResult key={poi.id} poi={poi} href={getHref(poi)} />
        ))}
      </motion.ul>
    </div>
  );
};
