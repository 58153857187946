import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { DetailCardStore } from './types';

export const DEFAULT_COLLAPSED_STATE = true;

export const useDetailCardStore = createWithEqualityFn<DetailCardStore>()(
  devtools(
    immer((set) => ({
      isCollapsed: DEFAULT_COLLAPSED_STATE,
      setCollapsed: (isCollapsed) =>
        set((state) => {
          state.isCollapsed = isCollapsed;
        }),
    })),
  ),
);
