import { useCallback, useEffect } from 'react';

import {
  exampleArrivalBcbpString,
  exampleDepartureBcbpString,
  invalidBcbpString,
  notFoundBcbpString,
} from './helpers';

type ScanHelper =
  | typeof exampleArrivalBcbpString
  | typeof exampleDepartureBcbpString
  | typeof invalidBcbpString
  | typeof notFoundBcbpString;

const keyMap: Record<string, ScanHelper> = {
  '[': exampleArrivalBcbpString,
  ';': exampleDepartureBcbpString,
  '.': invalidBcbpString,
  '/': notFoundBcbpString,
};

type UseFakeBarcodeScanProps = {
  isEnabled?: boolean;
  onScan: (barcode: string) => void;
};

export const useFakeBarcodeScan = ({
  isEnabled = true,
  onScan,
}: UseFakeBarcodeScanProps) => {
  const onKeyDown = useCallback(
    async function keyDownListener({ key, ctrlKey }: KeyboardEventInit) {
      if (!ctrlKey || !key || !Object.keys(keyMap).includes(key)) {
        return;
      }

      const scanFn = keyMap[key];

      const barcode = await scanFn();

      if (barcode) {
        onScan(barcode);
      }
    },
    [onScan],
  );

  useEffect(() => {
    if (!isEnabled) return;

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [isEnabled, onScan, onKeyDown]);
};
