import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { getFlightStatus } from 'pxp-api/clients/flight-info-api/helpers/status';
import useSWR from 'swr';

import { ResizablePanel } from '@/components/resizable-panel/resizable-panel';
import { Path, QueryParam, Route } from '@/enums/route';
import { useQueryParam } from '@/hooks/use-query-param';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { cleanQueryParams } from '@/lib/url';
import { IconName } from '@/types/icon';

import { FlightInfoCompactBar } from '../../../flight-info/components/flight-info-compact-bar/flight-info-compact-bar';
import { FlightInfoDetailError } from '../../../flight-info/components/flight-info-detail-error/flight-info-detail-error';
import { FlightInfoDetailLoading } from '../../../flight-info/components/flight-info-detail-loading/flight-info-detail-loading';
import { useGetFlightById } from '../../../flight-info/hooks/use-get-flight-by-id';
import { useMap } from '../../hooks/use-map';
import { useFlightCardStore } from '../../store/flightCardStore';
import { useWayFindingStore } from '../../store/wayFindingStore';
import { FlightInfoDetailCard } from '../flight-info-detail-card/flight-info-detail-card';

import css from './map-flight-info-detail.module.scss';

interface Props {
  className?: string;
}

const TOP_PADDING_FOR_FLIGHT_INFO = 700;

export const MapFlightInfoDetail = ({ className }: Props) => {
  const flightId = String(useQueryParam(QueryParam.FLIGHT_ID));
  const isFromFlightDetail =
    useQueryParam(QueryParam.FROM)?.toString() === Route.FLIGHT_INFO_DETAIL;
  const {
    i18n: { language },
  } = useTranslation();
  const router = useRouter();
  const {
    data: flight,
    isLoading,
    error,
    retry,
  } = useGetFlightById({ flightId });

  const classNames = classnames(css.root, className);
  const { isCollapsed, setCollapsed } = useFlightCardStore();
  const { goToPoi, getPoiById } = useMap();
  const isShowingRoute = useWayFindingStore((state) => state.isShowingRoute);
  const { isCancelled } = useMemo(
    () => (flight ? getFlightStatus(flight) : { isCancelled: false }),
    [flight],
  );
  const gateLocationId = flight?.departure.gateLocationId || '';
  const { data: poi } = useSWR(`${gateLocationId}${language}`, () =>
    getPoiById(gateLocationId),
  );

  useEffect(() => {
    if (isCancelled) {
      router.push({
        pathname: Path.FLIGHT_INFO_DETAIL,
        query: {
          [QueryParam.FLIGHT_ID]: flightId,
        },
      });
    }
  }, [flightId, isCancelled, router]);

  useEffect(() => {
    if (isShowingRoute) {
      setCollapsed(true);
    }
  }, [isShowingRoute, setCollapsed]);

  useEffect(() => {
    if (isFromFlightDetail) {
      setCollapsed(true);
      void router.push(
        {
          pathname: router.pathname,
          query: cleanQueryParams({
            ...router.query,
            [QueryParam.FROM]: undefined,
          }),
        },
        undefined,
        { shallow: true },
      );
    }
  }, [isFromFlightDetail, router, setCollapsed]);

  useEffect(() => {
    if (gateLocationId) {
      goToPoi(gateLocationId, {
        padding: { top: TOP_PADDING_FOR_FLIGHT_INFO },
      });
    }
  }, [gateLocationId, goToPoi]);

  return (
    <div className={classNames}>
      <ResizablePanel>
        {isLoading && <FlightInfoDetailLoading />}
        {error && <FlightInfoDetailError onRetry={retry} />}
        {flight && isCollapsed && (
          <button
            className={css.button}
            onClick={() => setCollapsed(false)}
            {...getTestIdProp('flight-info-api-detail-card')}
          >
            <FlightInfoCompactBar
              flight={flight}
              iconName={IconName.CHEVRON_DOWN}
              className={css.compactBar}
            />
          </button>
        )}
        {flight && !isCollapsed && (
          <FlightInfoDetailCard flight={flight} poi={poi} />
        )}
      </ResizablePanel>
    </div>
  );
};
