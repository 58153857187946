import { useRouter } from 'next/router';

import { useCustomSessionStorageState } from '@/hooks/use-custom-session-storage-state';

import { DEFAULT_LOCALE } from '../constants/i18n';
import { SessionStorageKey } from '../enums/session-storage';
import { createSsuHref } from '../lib/url';
import { useDataLayer } from '../modules/analytics/data-layer-provider';
import { UIEventName } from '../modules/analytics/events';

import { useHistory } from './use-history';

export const clearCache = async () => {
  if (!window?.caches) {
    return Promise.resolve();
  }

  const keys = await window.caches.keys();
  return Promise.all(keys.map((cacheKey) => window.caches.delete(cacheKey)));
};

export const useSessionReset = () => {
  const router = useRouter();
  const { update } = useHistory();
  const dataLayer = useDataLayer();
  const [, setCsatShown] = useCustomSessionStorageState(
    SessionStorageKey.CSAT_SHOWN,
    false,
  );

  const reset = async () => {
    await clearCache();
    dataLayer.publish(UIEventName.SESSION_END, {});
    update([]);
    setCsatShown(false);

    window.location.href = createSsuHref({ ...router, locale: DEFAULT_LOCALE });
  };

  return {
    reset,
  };
};
