
export enum FlightDirection {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export enum FlightType {
  SCHENGEN = 'SCHENGEN',
  NON_SCHENGEN = 'NON_SCHENGEN',
  DOMESTIC = 'DOMESTIC',
}

export enum FlightStatus {
  DELAYED = 'DEL',
  CANCELLED = 'CNX',
  GATE_CHANGED = 'GCH',
  GATE_CLOSING = 'GCL',
  GATE_CLOSED = 'GTD',
  BOARDING = 'BRD',
  DEPARTED = 'DEP',
  ON_SCHEDULE = 'SCH',
  TOMORROW = 'TOM',
  WAIT_IN_LOUNGE = 'WIL',
  GATE_OPEN = 'GTO',
  EARLY = 'EAR',
  ARRIVED = 'ARR',
  AIRBORNE = 'AIR',
  FLIGHT_INFORMATION_REGION = 'FIR',
  LANDED = 'LND',
  FIRST_BAG_ON_BELT = 'FIB',
  DIVERTED = 'DIV',
}

export enum BaggageStatusEventType {
  AIRPLANE_LANDED_ALL_BAGGAGE_INSIDE = 'AIRPLANE_LANDED_ALL_BAGGAGE_INSIDE',
  BAGGAGE_UNLOADING_TO_BELT_LOADER = 'BAGGAGE_UNLOADING_TO_BELT_LOADER',
  ALL_BAGGAGE_UNLOADED_TO_BELT_LOADER = 'ALL_BAGGAGE_UNLOADED_TO_BELT_LOADER',
  BAGGAGE_IN_TRANSIT_TO_BELT = 'BAGGAGE_IN_TRANSIT_TO_BELT',
  FIRST_BAG_ON_BELT = 'FIRST_BAG_ON_BELT',
  LAST_BAG_ON_BELT = 'LAST_BAG_ON_BELT',
}
