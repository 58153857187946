import { GateLabel } from '@/components/gate-label/gate-label';
import { DepartureTime } from '@/modules/flight-info/components/departure-time/departure-time';
import { useFlightTimes } from '@/modules/flight-info/hooks/use-flight-times';
import { WayFindingType, type WayFindingState } from '../../store/types';

interface Props {
  wayFinding: WayFindingState;
}

export const WayFindingCardHeadingElements = ({ wayFinding }: Props) => {
  const { to, type, meta } = wayFinding;
  const { getBoardingTime } = useFlightTimes();

  if (type === WayFindingType.POI || !meta?.flight) {
    return [to?.name];
  }

  const { departure, arrival } = meta.flight;
  const boardingTime = getBoardingTime(meta.flight);

  return [
    departure?.gateNumber ? (
      <GateLabel gateNumber={departure.gateNumber} />
    ) : null,
    boardingTime ?? <DepartureTime flight={meta.flight} />,
    arrival.airportName,
  ]
    .filter(Boolean)
    .map((element) => <span key={JSON.stringify(element)}>{element}</span>);
};
