import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { NetworkState } from '../hooks/use-network-state';

interface NetworkStore {
  state: NetworkState;
  setState: (networkState: NetworkState) => void;
}

export const selectIsOnline = (state: NetworkStore) => {
  return state.state === NetworkState.ONLINE;
};

export const useNetworkStore = createWithEqualityFn<NetworkStore>()(
  devtools(
    immer((set) => ({
      state: NetworkState.ONLINE,
      setState: (networkState) =>
        set((state) => {
          state.state = networkState;
        }),
    })),
  ),
);
