import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useTimeoutWhen } from 'rooks';
import type { MoveToMobileModule } from 'pxp-api/clients/strapi/config-type';

import { ButtonIcon } from '@/components/button-icon/button-icon';
import { Module } from '@/enums/module';
import { useModule } from '@/hooks/use-module';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { isIndexPage } from '@/lib/url';
import { ModalTrigger } from '@/modals';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import {
  type QRCodeImpression,
  QRCodeImpressionType,
} from '@/modules/analytics/types/event-props';
import {
  selectBalloonHasBeenClosed,
  selectBalloonIsOpen,
  useBalloonStore,
} from '@/store/balloonStore';
import { IconName } from '@/types/icon';

const BALLOON_ID = 'move-to-mobile';

export interface Props {
  qrCodeValue?: string;
}

export const MoveToMobileFooterButton = ({ qrCodeValue }: Props) => {
  const router = useRouter();
  const isIndex = isIndexPage(router);
  const moveToMobileModule = useModule<MoveToMobileModule>(
    Module.MOVE_TO_MOBILE,
  );

  const dataLayer = useDataLayer();
  const { t } = useTranslation();
  const { close, setIsOpen } = useBalloonStore();
  const isOpen = useBalloonStore(selectBalloonIsOpen(BALLOON_ID));
  const hasBeenClosed = useBalloonStore(selectBalloonHasBeenClosed(BALLOON_ID));

  const balloonTitle = t('move-to-mobile.button.balloon', {
    defaultValue: 'Move to your mobile phone',
  });

  const handleClick = () => {
    dataLayer.publish<QRCodeImpression>(UIEventName.QR_CODE_IMPRESSION, {
      type: QRCodeImpressionType.MTM,
    });
    close(BALLOON_ID);
  };

  useTimeoutWhen(
    () => setIsOpen(BALLOON_ID, true),
    (moveToMobileModule?.properties?.showBalloonDelay || 0) * 1000,
    !!moveToMobileModule?.properties?.showBalloonDelay &&
      !isIndex &&
      !hasBeenClosed,
  );

  useTimeoutWhen(
    () => close(BALLOON_ID),
    (moveToMobileModule?.properties?.balloonTimeout || 0) * 1000,
    !!moveToMobileModule?.properties?.balloonTimeout && isOpen,
  );

  return (
    <ModalTrigger
      modalId="MOVE_TO_MOBILE"
      contentProps={{ qrCodeValue }}
      onClick={handleClick}
    >
      <ButtonIcon
        iconName={IconName.MOVE_TO_MOBILE}
        hasPopOver
        popOverProps={{
          isOpen,
          onOpenChange: () => setIsOpen(BALLOON_ID, !isOpen),
          content: balloonTitle,
        }}
        title={balloonTitle}
        {...getTestIdProp('move-to-mobile-button')}
      />
    </ModalTrigger>
  );
};
