import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

interface VirtualKeyboardStore {
  id: string;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}

export const selectIsActive = (state: VirtualKeyboardStore) => {
  return state.isActive;
};

export const useVirtualKeyboardStore =
  createWithEqualityFn<VirtualKeyboardStore>()(
    devtools(
      immer((set) => ({
        id: 'virtual-keyboard',
        isActive: false,
        setIsActive: (isActive) =>
          set((state) => {
            state.isActive = isActive;
          }),
      })),
    ),
  );
