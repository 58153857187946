import type {
  AdBanner,
  ContactModule,
  CustomerSatisfactionScoreModule,
  FlightInfoModule,
  MapModule,
  MoveToMobileModule,
  PromotionsModule,
  VideoCallModule,
} from 'pxp-api/clients/strapi/config-type';

import { useSSU } from '@/context/ssu-provider';

import type { ModuleId } from '../types/module';

type Module =
  | FlightInfoModule
  | MapModule
  | ContactModule
  | MoveToMobileModule
  | CustomerSatisfactionScoreModule
  | VideoCallModule
  | AdBanner
  | PromotionsModule;

export const useModule = <T = Module>(moduleId: ModuleId): T | undefined => {
  const { modules } = useSSU();
  const _module = modules?.[moduleId];

  return _module ? (_module as unknown as T) : undefined;
};
