import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect } from 'react';

import { useDataLayer } from '../modules/analytics/data-layer-provider';
import { UIEventName } from '../modules/analytics/events';
import type { SSU } from '../types/content-schema';

const SSUContext = createContext<SSU | null>(null);

export const SSUProvider: FC<PropsWithChildren<{ ssu: SSU }>> = ({
  ssu,
  children,
}) => {
  const dataLayer = useDataLayer();

  useEffect(() => {
    dataLayer.update({ ssu });
    dataLayer.publish(UIEventName.START, {}, { once: true });
  }, [ssu, dataLayer]);

  return <SSUContext.Provider value={ssu}>{children}</SSUContext.Provider>;
};

export function useSSU() {
  const ssu = useContext(SSUContext);

  if (!ssu) {
    throw Error('SSU Context is not correctly provided');
  }

  return ssu;
}
