import { FC } from 'react';
import classnames from 'classnames';
import { secondsToMinutes } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { IconName } from '../../../../types/icon';
import { Poi } from '../../types/poi';
import { Icon } from '../../../../components/icon/icon';
import css from './walking-time.module.scss';

export interface WalkingTimeProps {
  className?: string;
  distanceInSeconds: Poi['position']['distanceInSeconds'];
}

export const WalkingTime: FC<WalkingTimeProps> = ({
  distanceInSeconds = 0,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(css.root, className)}>
      <Icon className={css.icon} name={IconName.WALKING} />
      <span className={css.text}>
        {t('general.map.walkingTime', {
          minutes: secondsToMinutes(distanceInSeconds),
        })}
      </span>
    </div>
  );
};
