import classNames from 'classnames';
import { format } from 'date-fns';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { useSSU } from '@/context/ssu-provider';
import { useDateFormatting } from '@/hooks/use-date-formatting';
import { useInterval } from '@/hooks/use-interval';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import { isIndexPage } from '@/lib/url';

import css from './the-header.module.scss';

const MINUTES_IN_MILLISECONDS = 60_000;

interface TheHeaderProps {
  className?: string;
}

export const TheHeader: FC<TheHeaderProps> = ({ className }) => {
  const router = useRouter();
  const { getFormattedDate } = useDateFormatting();
  const isIndex = isIndexPage(router);
  const [date, setDate] = useState<string | undefined>();
  const [time, setTime] = useState<string | undefined>();
  const [dateTime, setDateTime] = useState<string | undefined>();
  const { theme, modules } = useSSU();
  const hasAdBanner = modules?.adBanner?.enabled;
  const classes = classNames(
    css.root,
    className,
    isIndex && hasAdBanner && css.hasAdBanner,
  );

  const setTimeState = useCallback(() => {
    setDate(
      getFormattedDate({
        dateTimeFormatOptions: {
          day: '2-digit',
          month: 'short',
        },
      }),
    );
    setTime(
      getFormattedDate({
        dateTimeFormatOptions: {
          hour: '2-digit',
          minute: '2-digit',
        },
      }),
    );
    setDateTime(format(new Date(), "yyyy-MM-dd'T'HH:mmXXX"));
  }, [setDate, setTime, setDateTime, getFormattedDate]);

  useInterval(() => {
    setTimeState();
  }, MINUTES_IN_MILLISECONDS);

  useEffect(() => {
    setTimeState();
  }, [setTimeState]);

  return (
    <header className={classes} {...getTestIdProp('header')}>
      {isIndex && theme?.logoImage?.url && (
        <span className={classNames(css.logo, !isIndex && css.small)}>
          <Image
            src={theme?.logoImage?.url}
            layout="fill"
            objectFit="contain"
            aria-hidden={true}
            alt=""
          />
        </span>
      )}
      <div className={css.wrapper}>
        {date && time && (
          <time dateTime={dateTime} className={css.dateTime}>
            <span className={css.date}>{date}</span>
            <span className={css.time}>{time}</span>
          </time>
        )}
      </div>
    </header>
  );
};
