import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import type { CustomerSatisfactionScoreModule } from 'pxp-api/clients/strapi/config-type';
import { Module } from '../../enums/module';
import { useModule } from '../../hooks/use-module';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';
import type { ModalEvent } from '../../modules/analytics/types/event-props';
import { ModalAction } from '../../modules/analytics/types/event-props';
import { Modal, useModalStore } from '../../store/modalStore';
import type { ContentComponentProps } from '../../types/app';
import { IconName } from '../../types/icon';
import { ButtonBare } from '../button-bare/button';
import { FeedbackOverlay } from './feedback-overlay';
import css from './feedback-overlay-button.module.scss';

export type FeedbackOverlayButtonProps = ContentComponentProps<{
  context: string;
}>;

export const FeedbackOverlayButton: FC<FeedbackOverlayButtonProps> = ({
  context,
}) => {
  const { t } = useTranslation();
  const dataLayer = useDataLayer();
  const openModal = useModalStore((state) => state.openModal);
  const CSATModule = useModule<CustomerSatisfactionScoreModule>(Module.CSAT);

  const onClick = () => {
    dataLayer.publish<ModalEvent>(UIEventName.MODAL, {
      type: Modal.FEEDBACK_OVERLAY,
      action: ModalAction.OPEN,
    });

    openModal(Modal.FEEDBACK_OVERLAY);
  };

  if (CSATModule?.enabled) {
    return null;
  }

  return (
    <>
      <ButtonBare
        label={t('general.feedback.overlayButton.label')}
        icon={IconName.FEEDBACK}
        className={css.button}
        iconPosition="BEFORE"
        size="SMALL"
        onClick={onClick}
        testId="feedbackOverlayButton"
      />

      <FeedbackOverlay context={context} />
    </>
  );
};
