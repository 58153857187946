import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { Modal, ModalContentProps, ModalMap } from '@/modals/types';
import { getModalMap } from '@/modals/utils';

const ModalContainer = dynamic(() =>
  import('@/modals/modal-container').then((mod) => mod.ModalContainer),
);

interface ActiveModalState {
  id: Modal;
  contentProps: ModalContentProps[keyof ModalContentProps];
  returnElement: HTMLElement;
}

export interface ModalProviderContext {
  openModal: <ID extends Modal>(
    id: ID,
    contentProps: ModalContentProps[ID],
    returnElement?: HTMLElement | null,
  ) => void;
  pushModal: <ID extends Modal>(
    id: ID,
    contentProps: ModalContentProps[ID],
    returnElement?: HTMLElement | null,
  ) => void;
  popModal: () => void;
  closeModal: () => void;
  activeModals: ActiveModalState[];
}

const ModalContext = createContext<ModalProviderContext>({
  openModal: () => undefined,
  pushModal: () => undefined,
  popModal: () => undefined,
  closeModal: () => undefined,
  activeModals: [],
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [activeModals, setActiveModals] = useState<
    ModalProviderContext['activeModals']
  >([]);
  const [modalsLoading, setModalsLoading] = useState(false);
  const [modalMap, setModalMap] = useState<ModalMap | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (modalMap) {
      return;
    }
    const loadModals = async () => {
      try {
        setModalsLoading(true);
        setModalMap(await getModalMap());
      } catch (e) {
        const errorMessage = (e as Error).message ?? '';
        console.error(
          `Error while loading modals in ModalProvider. Check if (dynamic) imports are done correctly.\n${errorMessage}`,
        );
        Sentry.captureException(e);
      } finally {
        setModalsLoading(false);
      }
    };

    loadModals();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run on mount
  }, []);

  // Clear active modals on route changes
  useEffect(() => {
    const clearModals = () => {
      setActiveModals([]);
    };
    router.events.on('routeChangeComplete', clearModals);

    return () => {
      router.events.off('routeChangeComplete', clearModals);
    };
  }, [router]);

  const openModal: ModalProviderContext['openModal'] = useCallback(
    (id, contentProps, returnElement) => {
      setActiveModals([
        { id, contentProps, returnElement: returnElement ?? document.body },
      ]);
    },
    [setActiveModals],
  );

  const closeModal: ModalProviderContext['closeModal'] = useCallback(() => {
    /**
     * SetTimeout is used here to delay the focus call until after this component has unmounted properly.
     * Use the return element of the first item in the stack as all modals are closed.
     */
    setTimeout(() => {
      activeModals.at(0)?.returnElement.focus();
    });
    setActiveModals([]);
  }, [activeModals]);

  const pushModal: ModalProviderContext['pushModal'] = useCallback(
    (id, contentProps, returnElement) => {
      // fall back to openModal when there's no active modals
      if (activeModals.length < 1) {
        return openModal(id, contentProps, returnElement);
      }
      setActiveModals((activeModals) => [
        ...activeModals,
        { id, contentProps, returnElement: returnElement ?? document.body },
      ]);
    },
    [activeModals.length, openModal],
  );

  const popModal: ModalProviderContext['popModal'] = useCallback(() => {
    // fall back to closeModal to handle focus when popping doesn't make sense
    if (activeModals.length < 2) {
      return closeModal();
    }
    setActiveModals((activeModals) => activeModals.slice(0, -1));
  }, [activeModals, closeModal]);

  return (
    <ModalContext.Provider
      value={{
        activeModals,
        openModal,
        closeModal,
        pushModal,
        popModal,
      }}
    >
      {children}
      {modalMap && !modalsLoading && <ModalContainer modalMap={modalMap} />}
    </ModalContext.Provider>
  );
};

ModalContext.displayName = 'ModalContext';

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }
  return context;
};
