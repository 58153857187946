enum ValidatorType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

const VALIDATOR_REGEXPS = {
  // Adapted from source: https://github.com/solvvy/redact-pii/blob/da5f16f/src/built-ins/simple-regexp-patterns.ts
  [ValidatorType.EMAIL]: /([a-z0-9_\-.+]+)@\w+(\.\w+)*/,
  [ValidatorType.PHONE]:
    /(\(?\+?[0-9]{1,2}\)?[-. ]?)?(\(?[0-9]{3}\)?|[0-9]{3})[-. ]?([0-9]{3}[-. ]?[0-9]{4}|\b[A-Z0-9]{7}\b)/,
};

const VALIDATOR_ERROR_MESSAGES: Record<ValidatorType, string> = {
  [ValidatorType.EMAIL]:
    'Please do not provide an email addresses in your feedback',
  [ValidatorType.PHONE]:
    'Please do not provide a phone number in your feedback',
};

export interface ContainsPIIError {
  type: string;
  message: string;
}

export const containsPII = (str = ''): ContainsPIIError[] | undefined => {
  const strToTest = str
    .replace(/\s+/g, ' ')
    .replace(/\s/g, ' ')
    .toLocaleLowerCase() as string | undefined;

  if (!strToTest) {
    return undefined;
  }

  const errors = (
    Object.entries(VALIDATOR_REGEXPS) as [ValidatorType, RegExp][]
  )
    .filter(([, value]) => value.test(strToTest))
    .map(([key]: [ValidatorType, RegExp]) => ({
      type: key,
      message: VALIDATOR_ERROR_MESSAGES[key],
    }));

  return errors.length ? errors : undefined;
};
