import qs from 'qs';

import { DEFAULT_LOCALE } from '../../../constants/i18n';
import { ApiRoute } from '../../../enums/route';
import { customFetch } from '../../../lib/custom-fetch';
import type {
  FlightInfoApiOptions,
  FlightInfoProviderApi,
} from '../types/provider';
import type {
  FlightDirection,
  FlightInfoByDate,
  FlightInfoByIdResponse,
} from '../types/service';

export const FlightInfoApi = (
  options: FlightInfoApiOptions,
): FlightInfoProviderApi => {
  const { locale = DEFAULT_LOCALE } = options;

  const defaultQueryParams = {
    locale,
  };

  async function search(
    query: string,
    flightDirection: FlightDirection,
  ): Promise<FlightInfoByDate[]> {
    const queryString = qs.stringify({
      ...defaultQueryParams,
      query,
      flightDirection,
    });
    const url = `${ApiRoute.FLIGHT_INFO}?${queryString}`;
    return customFetch<FlightInfoByDate[]>(url, { method: 'GET' });
  }

  async function getById(
    id: string,
    flightDirection: FlightDirection,
  ): Promise<FlightInfoByIdResponse> {
    const queryString = qs.stringify({
      ...defaultQueryParams,
      flightDirection,
    });
    return customFetch<FlightInfoByIdResponse>(
      `${ApiRoute.FLIGHT_INFO}/${id}?${queryString}`,
      {
        method: 'GET',
      },
    );
  }

  return {
    search,
    getById,
  };
};
