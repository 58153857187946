import classNames from 'classnames';
import Image from 'next/legacy/image';

import { Info } from '../icon/lib/runway/info';

import css from './out-of-service-message.module.scss';

type Props = {
  title?: string;
  text?: string;
  altTitle?: string;
  altText?: string;
  errorCode?: string;
  logo?: string;
};

export const OutOfServiceMessage = ({
  title = 'Temporarily out of service',
  text = 'We encountered a technical problem that we need to solve first.',
  altTitle,
  altText,
  errorCode,
  logo,
}: Props) => {
  return (
    <div className={css.root}>
      <div className={css.body}>
        {errorCode && <span className={css.errorCode}>{errorCode}</span>}
        <Info className={css.icon} />
        <h2 className={classNames(css.title, css.bold)}>{title}</h2>
        {altTitle && <h2 className={css.title}>{altTitle}</h2>}
        <p className={classNames(css.message, css.bold, css.marginTop)}>
          {text}
        </p>
        {altText && <p className={css.message}>{altText}</p>}
      </div>
      {logo && (
        <div className={css.logo}>
          <Image
            src={logo}
            aria-hidden={true}
            alt=""
            layout="fill"
            objectFit="contain"
          />
        </div>
      )}
    </div>
  );
};
