import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useOutsideClick } from 'pxp-utils/hooks/use-outside-click';

import { useSSU } from '../../context/ssu-provider';
import { ElementSelector } from '../../enums/general';
import { LocalStorageConfigName } from '../../enums/localStorageConfig';
import { useHistory } from '../../hooks/use-history';
import { useSessionReset } from '../../hooks/use-session-reset';
import { getLocalStorageConfig } from '../../lib/get-local-storage-config';
import { Modal, selectIsOpen, useModalStore } from '../../store/modalStore';
import { ModalContainer, ModalType } from '../modal-container/modal-container';

import { shouldEnableTimeout } from './helper';
import { SessionTimeoutButton } from './session-timeout-button';
import css from './session-timeout.module.scss';

export const SessionTimeout = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    locales,
    config: { sessionExpirationTime = 0, outOfService },
  } = useSSU();
  const defaultLocale = locales.find((locale) => locale.default) || locales[0];
  const router = useRouter();
  const { update } = useHistory();
  const { reset } = useSessionReset();
  const modals = useModalStore((state) => state.modals);
  const isOpen = useModalStore(selectIsOpen(Modal.SESSION_TIMEOUT));
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const isEnabled =
    shouldEnableTimeout(router, defaultLocale, modals) &&
    !outOfService?.isEnabled &&
    sessionExpirationTime > 0;

  useEffect(() => {
    const isDisabledByLocalConfig = getLocalStorageConfig(
      LocalStorageConfigName.SESSION_TIMEOUT,
    );
    const debouncedShowOverlay = debounce(() => {
      openModal(Modal.SESSION_TIMEOUT);
    }, sessionExpirationTime * 1000);

    const element = document.querySelector(
      `[data-selector=${ElementSelector.SCROLLABLE_CONTENT}]`,
    );

    if (isEnabled && !isDisabledByLocalConfig) {
      element?.addEventListener('scroll', debouncedShowOverlay);
      window.addEventListener('touchmove', debouncedShowOverlay);
      window.addEventListener('pointerdown', debouncedShowOverlay);
      window.addEventListener('pointermove', debouncedShowOverlay);
      window.addEventListener('pointerend', debouncedShowOverlay);
      window.addEventListener('input', debouncedShowOverlay);
      window.addEventListener('keydown', debouncedShowOverlay);
      debouncedShowOverlay();
    }

    return () => {
      element?.removeEventListener('scroll', debouncedShowOverlay);
      window.removeEventListener('touchmove', debouncedShowOverlay);
      window.removeEventListener('pointerdown', debouncedShowOverlay);
      window.removeEventListener('pointermove', debouncedShowOverlay);
      window.removeEventListener('pointerend', debouncedShowOverlay);
      window.removeEventListener('input', debouncedShowOverlay);
      window.removeEventListener('keydown', debouncedShowOverlay);
      debouncedShowOverlay.cancel();
    };
  }, [isEnabled, openModal, sessionExpirationTime]);

  const continueSession = () => {
    closeModal(Modal.SESSION_TIMEOUT);
  };

  useOutsideClick(ref, () => {
    continueSession();
  });

  const handleTimeout = () => {
    update([]); // reset history
    void reset();
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      type={ModalType.ACTION_SHEET}
      onClose={continueSession}
    >
      <h2 className={css.title}>
        {t('general.session.timeout.title', {
          defaultValue: 'Are you still there?',
        })}
      </h2>

      <SessionTimeoutButton
        label={t('general.session.timeout.button', {
          defaultValue: "Yes, I'm still here",
        })}
        onTimeout={() => handleTimeout()}
        onClick={continueSession}
      />
    </ModalContainer>
  );
};
