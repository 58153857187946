import { ExternalLink } from '@/components/external-link/external-link';
import { QRCodeImpressionType } from '@/modules/analytics/types/event-props';

type RenderExternalLinkProps = {
  node: HTMLElement;
};
export const RenderExternalLink = ({ node }: RenderExternalLinkProps) => {
  const href = node.getAttribute('href') ?? '';
  const label = node.textContent ?? '';
  const isNumber = !isNaN(Number(href));
  return (
    <ExternalLink
      qrCodeImpressionType={QRCodeImpressionType.FAQ}
      label={label}
      value={href}
      isNumber={isNumber}
    />
  );
};
