import type { DefaultMeta } from '../../../types/event-props';
import { GTMEventName } from '../event';
import type { Mapper } from '../types';

export const mapLanguageSelect: Mapper<DefaultMeta> = ({
  meta: { value },
}) => ({
  eventName: GTMEventName.LANGUAGE_SELECT,
  eventLabel: value,
});
