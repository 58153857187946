import { type NextRouter, useRouter } from 'next/router';
import {
  type CustomerSatisfactionScoreModule,
  type MoveToMobileModule,
} from 'pxp-api/clients/strapi/config-type';
import { useCallback, useEffect, useState } from 'react';
import { useTimeoutWhen } from 'rooks';

import {
  ModalContainer,
  ModalType,
} from '@/components/modal-container/modal-container';
import { useSSU } from '@/context/ssu-provider';
import { Module } from '@/enums/module';
import { Path, QueryParam } from '@/enums/route';
import { SessionStorageKey } from '@/enums/session-storage';
import { useCustomSessionStorageState } from '@/hooks/use-custom-session-storage-state';
import { useModule } from '@/hooks/use-module';
import { isIndexPage } from '@/lib/url';
import { Modal, selectIsOpen, useModalStore } from '@/store/modalStore';

import { useDataLayer } from '../../../analytics/data-layer-provider';
import { UIEventName } from '../../../analytics/events';
import type { FeedbackCsat } from '../../../analytics/types/event-props';
import { CsatFeedbackInput } from '../csat-feedback-input/csat-feedback-input';
import { CsatFeedbackScore } from '../csat-feedback-score/csat-feedback-score';
import { CsatSuccessMessage } from '../csat-success-message/csat-success-message';
import { CsatToMobileMessage } from '../csat-to-mobile-message/csat-to-mobile-message';

enum State {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  TO_MOBILE = 'TO_MOBILE',
  FEEDBACK_INPUT = 'FEEDBACK_INPUT',
}

const MIN_POSITIVE_VALUE = 3;

const isPageWithoutCsat = (router: NextRouter) => {
  const predicates: ((router: NextRouter) => boolean)[] = [
    isIndexPage,
    (router) => router.asPath === Path.CALL_FEEDBACK.toString(),
    (router) => router.pathname === Path.SEARCH.toString(),
  ];
  return predicates.map((predicate) => predicate(router)).some((val) => val);
};

export const CustomerSatisfactionScore = () => {
  const { config } = useSSU();
  const csatModule = useModule<CustomerSatisfactionScoreModule>(Module.CSAT);
  const moveToMobileModule = useModule<MoveToMobileModule>(
    Module.MOVE_TO_MOBILE,
  );
  const router = useRouter();
  const dataLayer = useDataLayer();
  const initialCsatValue = router.query[QueryParam.CSAT_SCORE]
    ? Number(router.query[QueryParam.CSAT_SCORE])
    : 0;
  const [state, setState] = useState(State.INIT);
  const [csatShown, setCsatShown] = useCustomSessionStorageState(
    SessionStorageKey.CSAT_SHOWN,
    false,
  );
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const [formValue, setFormValue] = useState<number>(initialCsatValue);
  const isOpen = useModalStore(selectIsOpen(Modal.CSAT));
  const isVideoOpen = router.route === Path.CALL_AGENT.toString();
  const openModalAfterSeconds =
    state === State.FEEDBACK_INPUT
      ? 0
      : Number(csatModule?.properties?.showPopupDelay || 0);
  const closeModalAfterSeconds = Number(
    csatModule?.properties?.feedbackReceivedTimeout || 0,
  );
  const openModalWhen =
    // Basic checks first
    !csatShown &&
    !isVideoOpen &&
    // Then, check if we are either on a page that should allow CSAT with correct settings ...
    ((csatModule?.enabled &&
      openModalAfterSeconds > 0 &&
      !isPageWithoutCsat(router)) ||
      // ... or we are in the FEEDBACK_INPUT state
      state === State.FEEDBACK_INPUT);

  const toMobile = !config.isMobile && moveToMobileModule?.enabled;

  const onClose = useCallback(() => {
    closeModal(Modal.CSAT);
  }, [closeModal]);

  const submitFeedback = useCallback(
    ({ value, feedback }: { value?: number; feedback?: string } = {}) => {
      dataLayer.publish<FeedbackCsat>(UIEventName.FEEDBACK_CSAT_SUBMIT, {
        value,
        feedback,
      });
    },
    [dataLayer],
  );

  const onSelect = useCallback(
    (value: number) => {
      setFormValue(value);
      setCsatShown(true);
      submitFeedback({ value });

      if (value < MIN_POSITIVE_VALUE) {
        if (toMobile) {
          setState(State.TO_MOBILE);
          return;
        }
        setState(State.FEEDBACK_INPUT);
        return;
      }

      setState(State.SUCCESS);
    },
    [submitFeedback, setState, setFormValue, toMobile, setCsatShown],
  );

  const onSubmit = useCallback(
    (feedback: string) => {
      submitFeedback({ feedback });
      setState(State.SUCCESS);
    },
    [submitFeedback, setState],
  );

  useEffect(() => {
    if (initialCsatValue) {
      setFormValue(initialCsatValue);
      setState(State.FEEDBACK_INPUT);
    }
  }, [initialCsatValue, setState, setFormValue]);

  useTimeoutWhen(
    () => openModal(Modal.CSAT),
    openModalAfterSeconds * 1000,
    openModalWhen,
  );

  useTimeoutWhen(
    () => {
      closeModal(Modal.CSAT);
      setState(State.INIT);
    },
    closeModalAfterSeconds * 1000,
    state === State.SUCCESS,
  );

  useEffect(() => {
    if (isOpen && state === State.INIT) {
      dataLayer.publish(UIEventName.FEEDBACK_CSAT_IMPRESSION, {});
    }
  }, [isOpen, state, dataLayer]);

  if (!csatModule?.enabled) {
    return null;
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      type={ModalType.ACTION_SHEET}
      onClose={onClose}
    >
      {state === State.INIT && <CsatFeedbackScore onSelect={onSelect} />}
      {state === State.SUCCESS && <CsatSuccessMessage />}
      {state === State.TO_MOBILE && <CsatToMobileMessage value={formValue} />}
      {state === State.FEEDBACK_INPUT && (
        <CsatFeedbackInput onSubmit={onSubmit} />
      )}
    </ModalContainer>
  );
};
