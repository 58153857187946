import classnames from 'classnames';
import React, { useEffect } from 'react';

import { AdBanner } from '@/modules/ad-banner';

import { TheFooter } from '../components/the-footer/the-footer';
import { TheHeader } from '../components/the-header/the-header';

import css from './defaultLayout.module.scss';
import type { LayoutProps } from './get-layout';

export const HomeLayout: React.FC<LayoutProps> = ({ className, children }) => {
  const classes = classnames(css.root, className);

  useEffect(() => {
    document.body.classList.add('home');

    return () => {
      document.body.classList.remove('home');
    };
  }, []);

  return (
    <div className={classes}>
      <AdBanner />
      <TheHeader />
      <div className={css.main}>{children}</div>
      <TheFooter />
    </div>
  );
};
